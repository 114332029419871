import Image from '@components/Image';
import { toLower } from 'lodash';
import PLACEHOLDER_ICON from 'src/assets/icons/database-16.svg';
import { useEntitySourceInfoQuery } from 'src/generated/graphql';

import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
import { connectorsInfos } from 'src/common/utils/connectors';
import { filterStringByTerm } from 'src/common/utils/utils';
// https://sightfull.atlassian.net/wiki/spaces/HANDBOOK/pages/1298563074/Scrape+Fivetran+connectors

const POPULAR_CONNECTORS = ['Salesforce', 'HubSpot', 'Google Sheets', 'Google Drive'];

type SourceInfo = {
	connectorInfo?: DataConnectorInfo;
	urlFormatter: (baseUrl: string, primaryKeys: PrimaryKeys) => string | null;
};

const salesforceUrlFormatter = (baseUrl: string, primaryKeys: PrimaryKeys) => {
	try {
		return new URL(Object.values(primaryKeys)[0], baseUrl).href;
	} catch {
		return null;
	}
};

function getConnectorsSourceInfo({ source }: { source: string }): SourceInfo | undefined {
	const connectorInfo = connectorsInfos.find(
		(info) =>
			toLower(info.id) === toLower(source) ||
			toLower(info.backwardsCompatibleName) === toLower(source) ||
			toLower(info.coreName) === toLower(source)
	);
	if (connectorInfo == undefined) {
		return undefined;
	}

	return {
		connectorInfo,
		urlFormatter:
			connectorInfo.name === 'Salesforce'
				? salesforceUrlFormatter
				: // eslint-disable-next-line @typescript-eslint/no-unused-vars
				  (baseUrl: string, primaryKeys: PrimaryKeys) => null,
	};
}
export function getSourcesInfo({
	source,
	baseUrl,
	primaryKeys,
}: {
	source?: string;
	baseUrl?: string;
	primaryKeys?: PrimaryKeys;
}): { connectorInfo?: DataConnectorInfo; url: string | null } | null {
	if (!source) return null;
	const sourceInfo = getConnectorsSourceInfo({ source });
	if (sourceInfo == undefined) {
		return null;
	}
	const { connectorInfo, urlFormatter } = sourceInfo;
	if (!baseUrl || !primaryKeys) return { connectorInfo, url: null };
	return { connectorInfo, url: urlFormatter(baseUrl, primaryKeys) };
}

export function useSourceInfo({ entityName, primaryKeys }: { entityName: string; primaryKeys?: PrimaryKeys }) {
	const { data, loading, error } = useEntitySourceInfoQuery({ variables: { entity: entityName } });
	const sourceInfo = data?.entityInfo.schema;
	if (loading || error || !sourceInfo) return null;

	return getSourcesInfo({ source: sourceInfo.sourceDisplayName, baseUrl: sourceInfo.sourceURL, primaryKeys });
}

export const popularConnectors: DataConnectorInfo[] = connectorsInfos.filter(({ name }) => {
	return POPULAR_CONNECTORS.includes(name);
});
export const allConnectorTypes = new Set(connectorsInfos.map(({ type }) => type).sort((a, b) => a.localeCompare(b)));
export type PrimaryKeys = { [primaryKey: string]: string };

export const filterConnectorsBySearchTerm = (searchTerm: string) => {
	return connectorsInfos.filter(({ name }) => searchTerm == '' || filterStringByTerm(searchTerm, name));
};
export const getSourceInfoLogo = ({
	bigLogo,
	dataConnectorInfo,
	placeholderIcon = PLACEHOLDER_ICON,
}: {
	bigLogo: boolean;
	dataConnectorInfo?: DataConnectorInfo | null;
	placeholderIcon?: string;
}) => {
	const props = { maxWidth: bigLogo ? '20px' : '16px', height: bigLogo ? '20px' : '16px', width: '100%' };
	if (dataConnectorInfo == undefined) {
		return <Image src={placeholderIcon} {...props} />;
	}
	return <Image src={dataConnectorInfo.icon} {...props} />;
};
