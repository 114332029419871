import Flex from '@components/Flex';
import { ICellRendererParams } from 'ag-grid-community';
import Spinner from 'src/common/components/Spinner';
import Typography from 'src/common/components/Typography';
import { SYNC_COPY } from 'src/pages/ConnectorsPage/utils/constants';

export function LastSyncRenderer() {
	function Cell(params: ICellRendererParams) {
		const cellValue = params?.value || {};

		return (
			<Flex alignItems={'center'} gap={'10px'}>
				{cellValue.isSyncing && <Spinner trackColor="gray.500" thickness="12px" color="gray.1000" size={'16px'} />}
				<Typography variant={'DesktopH8Regular'} color="gray.800" noOfLines={1} wordBreak="break-all">
					{cellValue.isSyncing ? SYNC_COPY : cellValue.date}
				</Typography>
			</Flex>
		);
	}
	return Cell;
}
