import { Box, Popover, PopoverContent, PopoverProps, PopoverTrigger, Portal } from '@chakra-ui/react';
import { useViewport } from '@xyflow/react';
import { useModal } from '../../hooks/ui/useModal';
import { useCallback, useMemo } from 'react';

export type FlowPopoverProps = Omit<PopoverProps, 'children'> & {
	popoverContent?: React.ReactNode;
	triggerLocation?: { x: number; y: number };
	onTrigger?: () => void;
	children: React.ReactNode;
};

export function FlowPopover({ popoverContent, triggerLocation, onTrigger, children }: FlowPopoverProps) {
	// this useViewport is necessary to keep the popover displayed next to the trigger element.
	// removing it will make the popover display in an incorrect location when it is open while also zooming the view.
	useViewport();

	const { isOpen, onOpen, onClose } = useModal();

	const onClick = useCallback(() => {
		onOpen();
		onTrigger?.();
	}, [onOpen, onTrigger]);

	const style: React.CSSProperties | undefined = useMemo(() => {
		return triggerLocation
			? {
					position: 'absolute',
					transform: `translate(-50%, -50%) translate(${triggerLocation.x}px, ${triggerLocation.y}px)`,
					pointerEvents: 'all',
			  }
			: undefined;
	}, [triggerLocation]);

	return (
		<Popover placement={'bottom-start'} gutter={4} isOpen={isOpen} onClose={onClose}>
			<PopoverTrigger>
				<Box onClick={onClick} style={style}>
					{children}
				</Box>
			</PopoverTrigger>
			<Portal>
				<PopoverContent animate={false} backgroundColor={'unset'} boxShadow={'unset'}>
					{popoverContent}
				</PopoverContent>
			</Portal>
		</Popover>
	);
}
