import { ChevronRight16 } from '@components/Icons';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import colors from '../../../../../style/colors';
import { EntityWithMetrics, LoadedRelationship } from '../../../../../lib/completions/semanticTypes';
import { semanticDisplayName } from '../../../../../normalize';

export function RelationshipsPath({
	relationships,
	allEntities,
}: {
	relationships: (LoadedRelationship & { entity: EntityWithMetrics })[];
	allEntities: Record<string, EntityWithMetrics>;
}) {
	const separator = (
		<Flex color={colors.gray[600]}>
			<ChevronRight16 />
		</Flex>
	);

	return (
		<Typography variant={'Paragraph12R'} color={colors.gray[1000]}>
			<Flex direction={'row'} alignItems={'center'} wrap={'wrap'}>
				{relationships.map((rel, index) => (
					<>
						{index === 0 && semanticDisplayName(rel.entity)}
						{separator}
						<Typography variant={'Paragraph12M'}>{semanticDisplayName(rel)}</Typography>
						{separator}
						{semanticDisplayName(allEntities[rel.referenced_entity] ?? { name: 'unknown' })}
					</>
				))}
			</Flex>
		</Typography>
	);
}
