// TAF: remove the support for that annoying hash
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// TAF:
function removeNonPersistentSearchParams(curLocation: URL) {
	const newLocation = new URL(curLocation);
	curLocation.searchParams.forEach((searchParams, value) => {
		if (!value.startsWith('_')) {
			newLocation.searchParams.delete(value);
		}
	});

	return newLocation;
}

export function getAppStateSearchParams() {
	return removeNonPersistentSearchParams(getNewUrl()).search;
}

export interface NavigationOptions {
	path: string;
	anchor?: string;
	replace?: boolean;
	isNewTab?: boolean;
	willIncludeAllSearchParams?: boolean;
	additionalSearchParams?: URLSearchParams;
}

// TAF: remove the support for that annoying hash
export function buildEntityProfileUrl(entityId: string, shouldIncludeCurrentLocation: boolean, isNewFormat?: boolean) {
	const entityProfilePrefix = 'node';
	const curLocation = window.location.origin;

	const path = isNewFormat ? `${entityProfilePrefix}/${entityId}` : `${entityProfilePrefix}/id:${entityId}`;
	if (shouldIncludeCurrentLocation) return `${curLocation}/${path}`;

	return path;
}

const getNewUrl = () => new URL(window.location.href);

function SetLocationWithAppState({
	path,
	isNewTab = false,
	willIncludeAllSearchParams = false,
	additionalSearchParams,
	anchor,
}: NavigationOptions): string | null {
	if (!path) return '';
	if (!path.startsWith('/')) {
		path = `/${path}`;
	}

	const searchParams = new URLSearchParams(willIncludeAllSearchParams ? getNewUrl().search : getAppStateSearchParams());

	const fullSearchParams = new URLSearchParams({
		...Object.fromEntries(searchParams),
		...Object.fromEntries(additionalSearchParams ?? new URLSearchParams()),
	});

	const newLocation = `${path}?${fullSearchParams}`;

	if (isNewTab) {
		window.open(newLocation, '_blank');
		return null;
	}

	if (newLocation == `${window.location.pathname}${window.location.hash}`) return null;

	if (anchor) return newLocation + '/' + anchor;
	else return newLocation;
}

function useNavigation() {
	const customNavigation = useNavigate();
	const navigate = useCallback(
		(navOptions: NavigationOptions) => {
			const parsedPath = SetLocationWithAppState(navOptions);
			if (parsedPath) customNavigation(parsedPath, { replace: navOptions.replace });
		},
		[customNavigation]
	);

	const goBack = useCallback(() => {
		const isNewTab = history.length <= 2;
		if (isNewTab) navigate({ path: '/' });
		else history.back();
	}, [navigate]);

	const refresh = useCallback(() => {
		location.reload();
	}, []);

	const navigateAndRefresh = useCallback(
		(navOptions: NavigationOptions) => {
			navigate(navOptions);
			location.reload();
		},
		[navigate]
	);

	const getHref = useCallback(({ path = '', additionalSearchParams }: NavigationOptions) => {
		const normalizedPath = !path.startsWith('/') ? `/${path}` : path;

		const searchParams = new URLSearchParams(getAppStateSearchParams());

		const fullSearchParams = new URLSearchParams({
			...Object.fromEntries(searchParams),
			...Object.fromEntries(additionalSearchParams ?? new URLSearchParams()),
		});

		return `${normalizedPath}?${fullSearchParams}`;
	}, []);

	return { navigate, goBack, getHref, refresh, navigateAndRefresh };
}

export default useNavigation;
