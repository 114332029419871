import { Box, Flex } from '@chakra-ui/react';
import { Mention } from 'src/types/mention';
import { CloseSmall12 } from '../../Icons';
import { Label } from '../../Labels';
import Tooltip from '../../Tooltip';

export function InviteChipList({ chips, onDelete }: { chips: Mention[]; onDelete: (mention: Mention) => void }) {
	if (chips.length === 0) return undefined;
	return (
		<Flex gap={'6px'} paddingBottom={'6px'} flexWrap={'wrap'} paddingRight={'4px'}>
			{chips.map((chip) => {
				return <InviteChip mention={chip} key={chip.id} onDelete={() => onDelete(chip)} />;
			})}
		</Flex>
	);
}

function InviteChip({ mention, onDelete }: { mention: Mention; onDelete: () => void }) {
	return (
		<Label
			variant={'filled'}
			colorScheme={'dark'}
			endIcon={
				<Tooltip label={'Remove'} placement={'top'} size={'sm'}>
					<Box onClick={onDelete} cursor={'pointer'}>
						<CloseSmall12 />
					</Box>
				</Tooltip>
			}
			size="small"
			style="light"
		>
			{mention.name || mention.email || ''}
		</Label>
	);
}
