import { ReactNode } from 'react';
import shadows from '../../../../style/shadows';

import { SemanticCard } from './SemanticCard';

export function SemanticPopup({ children }: { children: ReactNode }) {
	return (
		<SemanticCard minWidth={'280px'} maxWidth={'280px'} shadow={shadows.lg}>
			{children}
		</SemanticCard>
	);
}
