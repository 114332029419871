import { getSimpleBezierPath, Position } from '@xyflow/react';

export function getBezierPath({
	sourceX,
	sourceY,
	targetX,
	targetY,
	offestX = 4,
	sourcePosition = Position.Right,
	targetPosition = Position.Left,
}: {
	sourceX: number;
	sourceY: number;
	targetX: number;
	targetY: number;
	offestX?: number;
	sourcePosition?: Position;
	targetPosition?: Position;
}) {
	const path = getSimpleBezierPath({
		sourcePosition,
		targetPosition,
		sourceX: sourceX - offestX,
		sourceY,
		targetX: targetX + offestX,
		targetY,
	});

	return path;
}
