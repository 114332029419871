import Flex from '@components/Flex';
import Box from '@components/Box';
import Popover from '@components/Popover';
import { FolderData, UpsertFolderModal } from './UpsertFolderModal';
import Typography from '../../Typography';
import Button from '@components/Button';
import { DashboardNew18, DeleteTrash16, EditFull16, More16 } from '../../Icons';
import { AccessController } from '../../AccessController';
import { Permissions } from 'src/types/environment';
import Divider from '../../Divider';
import ListItem from '../../ListItem';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { useRef, useState } from 'react';
import Tooltip from '../../Tooltip';
import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import shadows from 'src/style/shadows';
import colors from 'src/style/colors';
import { useModal } from 'src/common/hooks/ui/useModal';
import { DeleteFolderModal } from '.';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { useReportEvent } from 'src/services/analytics';

//TODO: unify with SpaceMoreButton on drag-&-drop development
export const DashboardFolder = ({ folder }: { folder: FolderData }) => {
	const titleRef = useRef(null);
	const isOverflowingTitle = useOnOverflow(titleRef, [folder.folderName], undefined, false);
	const [isHovering, setIsHovering] = useState(false);
	const { reportEvent } = useReportEvent();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();
	const {
		isOpen: isCreateFolderModalOpen,
		onOpen: onCreateFolderModalOpen,
		onClose: onCreateFolderModalClose,
	} = useModal();
	const {
		isOpen: isCreateEditCollectionModalOpen,
		onOpen: onCreateEditCollectionModalOpen,
		onClose: onCreateEditCollectionModalClose,
	} = useModal();

	const handleDelete = () => {
		reportEvent({ event: 'sidebar-delete-item', metaData: { itemType: 'folder', name: folder.folderName } });
		onClose();
		onDeleteModalOpen();
	};

	const handleEdit = () => {
		reportEvent({ event: 'sidebar-edit-item', metaData: { itemType: 'folder', name: folder.folderName } });
		onClose();
		onCreateFolderModalOpen();
	};

	const handleCreateDashboard = () => {
		reportEvent({ event: 'sidebar-create-new-dashboard-in-folder', metaData: { name: folder.folderName } });
		onClose();
		onCreateEditCollectionModalOpen();
	};

	const PopoverMenuItems = ({
		onEdit,
		onDelete,
		onCreateDashboard,
	}: {
		onEdit: VoidFunction;
		onDelete: VoidFunction;
		onCreateDashboard: VoidFunction;
	}) => (
		<>
			<PopoverContent>
				<Flex alignSelf="center" direction={'column'} py="8px" width="224px" borderRadius="4px">
					<AccessController permission={Permissions.createCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							size="sm"
							label="Create a dashboard..."
							prefixIcon={<DashboardNew18 />}
							onClick={onCreateDashboard}
						/>
						<Box px={'12px'}>
							<Divider direction="horizontal" my="8px" />
						</Box>
					</AccessController>
					<AccessController permission={Permissions.updateCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							size="sm"
							label="Edit"
							prefixIcon={<EditFull16 />}
							onClick={onEdit}
						/>
					</AccessController>
					<Box px={'12px'}>
						<Divider direction="horizontal" my="8px" />
					</Box>
					<AccessController permission={Permissions.deleteCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							size="sm"
							label="Delete"
							prefixIcon={<DeleteTrash16 />}
							onClick={onDelete}
						/>
					</AccessController>
				</Flex>
			</PopoverContent>
		</>
	);

	return (
		<Flex
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			justifyContent={'space-between'}
			alignItems={'center'}
			borderRadius={'6px'}
			transition={'0.2s'}
			_hover={{
				backgroundColor: 'rgba(206, 206, 206, 0.32)',
			}}
			cursor={'pointer'}
			padding={'8px'}
			width={'100%'}
		>
			<Flex alignItems={'center'} gap={'8px'}>
				<Box fontSize={'20px'} lineHeight={'20px'}>
					{folder.emoji}
				</Box>
				<Box>
					<Tooltip size="md" variant="fluid" label={isOverflowingTitle && folder.folderName}>
						<Typography wordBreak={'break-all'} noOfLines={1} variant={'DesktopH8Regular'} color={'gray.1000'}>
							<Box ref={titleRef}>{folder.folderName}</Box>
						</Typography>
					</Tooltip>
				</Box>
			</Flex>
			{(isHovering || isOpen) && (
				<>
					<Popover
						placement="bottom-start"
						variant="solid"
						isOpen={isOpen}
						onClose={onClose}
						triggerElement={
							<Flex position="relative">
								<Button
									size="xxxs"
									variant="outline"
									colorScheme="gray"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										if (isOpen) onClose();
										else onOpen();
									}}
									isActive={isOpen}
									isIconOnly={true}
									color={'gray.900'}
									_hover={{ bgColor: isOpen ? 'transparent' : 'gray.200' }}
									blendMode={'multiply'}
								>
									<More16 />
								</Button>
							</Flex>
						}
						popoverContentProps={{
							boxShadow: shadows['panelShadow'],
							borderRadius: '8px',
							border: `1px solid ${colors.gray[300]}`,
						}}
					>
						<PopoverMenuItems onCreateDashboard={handleCreateDashboard} onEdit={handleEdit} onDelete={handleDelete} />
					</Popover>
					<CollectionUpsertModal
						selectedFolder={folder}
						isOpen={isCreateEditCollectionModalOpen}
						onClose={() => {
							onCreateEditCollectionModalClose();
							setIsHovering(false);
						}}
						collectionType={'dashboard'}
					/>
				</>
			)}
			<UpsertFolderModal folder={folder} isOpen={isCreateFolderModalOpen} onClose={onCreateFolderModalClose} />
			<DeleteFolderModal folder={folder} isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} />
		</Flex>
	);
};
