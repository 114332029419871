import { useEffect, useRef } from 'react';
import { UsersForMentionsQuery, UsersForMentionsQueryVariables } from 'src/generated/graphql';
import { UsersForMentions } from 'src/queries/user';
import { selectors, useMentionsStore } from 'src/stores/mentionsStore';
import { useUserStore } from 'src/stores/user';
import { Mention } from 'src/types/mention';
import useFeatureFlag from '../stores/useFeatureFlag';
import useTenantConfig from '../stores/useTenantConfig';
import useLazyQuery from './useLazyQuery';

type RawUser = {
	__typename?: 'users';
	id: any;
	email?: string | null;
	first_name?: string | null;
	last_name?: string | null;
	picture?: string | null;
};

const AlwaysFailingRegex = '(?!x)x';
const SightfullEmailRegex = '.*?@sightfull.com';

const useMentionsFetch = () => {
	const isAlreadyCalled = useRef<boolean>(false);
	const [fetchMentions, { data: rawMentions }] = useLazyQuery<UsersForMentionsQuery, UsersForMentionsQueryVariables>(
		UsersForMentions
	);
	const userStore = useUserStore();
	const { isSightfullTenant } = useTenantConfig();
	const isInitialized = useMentionsStore(selectors.getInitialized);
	const setInitialized = useMentionsStore(selectors.setInitialized);
	const setMentions = useMentionsStore(selectors.setMentions);
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const parseMentions = (rawUser: RawUser) => ({
		id: rawUser.id || '',
		name: `${rawUser.first_name || ''} ${rawUser.last_name || ''}`,
		avatarURL: rawUser.picture || '',
		email: rawUser.email || '',
	});

	useEffect(() => {
		if (rawMentions?.users) {
			const mentions: Mention[] = rawMentions?.users?.map(parseMentions);

			setMentions(mentions || []);
		}
	}, [setMentions, rawMentions]);

	useEffect(() => {
		const isSightfull2AndTenantConfigNotLoaded = isSightfull2 && isSightfullTenant == undefined;
		if (isInitialized || isAlreadyCalled.current || isSightfull2AndTenantConfigNotLoaded) {
			return;
		}

		isAlreadyCalled.current = true;

		void fetchMentions({
			variables: {
				email_regex: isSightfullTenant ? AlwaysFailingRegex : SightfullEmailRegex,
			},
		}).then(() => setInitialized(true));
	}, [setInitialized, fetchMentions, userStore.user.id, isInitialized, isSightfullTenant, isSightfull2]);
};

export default useMentionsFetch;
