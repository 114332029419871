import Flex from '@components/Flex';
import colors from 'src/style/colors';
import { AiSparks14, AiSparks16 } from '@icons/index';
import Typography from '@components/Typography';
import { isMacOperatingSystem } from 'src/common/utils/utils';

export function OmniSearch({ variant, onClick }: { variant: 'small' | 'big'; onClick: () => void }) {
	const fontVariant = variant == 'big' ? 'DesktopH7Regular' : 'DesktopH8Regular';

	return (
		<Flex
			borderRadius={'58px'}
			border={'4px solid'}
			borderColor={colors.white}
			_hover={{ borderColor: colors.blue[200] }}
		>
			<Flex
				width={variant === 'big' ? '712px' : '500px'}
				padding={variant === 'big' ? '18px' : '12px 16px'}
				paddingRight={'24px'}
				alignItems={'center'}
				gap={'20px'}
				justifyContent={'space-between'}
				borderRadius={'58px'}
				backgroundColor={colors.gray[200]}
				onClick={onClick}
				_hover={{ backgroundColor: colors['white'], cursor: 'pointer' }}
			>
				<Flex gap={'8px'} alignItems={'center'}>
					{variant === 'big' ? <AiSparks16 color={colors.gray[1000]} /> : <AiSparks14 color={colors.gray[1000]} />}
					<Typography variant={fontVariant} color={colors.gray[1000]}>
						Search metrics or ask Sightfull AI
					</Typography>
				</Flex>
				<Flex alignItems={'center'} justifyContent={'center'} paddingRight={'4px'}>
					<Typography variant={fontVariant} color={colors.gray[600]}>
						{isMacOperatingSystem ? '⌘' : 'Ctrl'}+K
					</Typography>
				</Flex>
			</Flex>
		</Flex>
	);
}
