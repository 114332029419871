import Flex from '@components/Flex';
import Typography from 'src/common/components/Typography';
import { OmniSearch } from '@pages/MetricCatalog/OmniSearch';
import useUser from '@hooks/stores/useUser';
import { MetricCatalogHeaderV2Buttons } from '@pages/MetricCatalog/MetricCatalogHeaderV2Buttons';
import colors from 'src/style/colors';

export function MetricCatalogHeaderV2({ onDiscoveryOpen }: { onDiscoveryOpen: () => void }) {
	const [user] = useUser();

	const getTimeOfDay = (): string => {
		const hour = new Date().getHours();
		if (hour >= 6 && hour < 12) {
			return 'morning';
		}
		if (hour >= 12 && hour < 17) {
			return 'afternoon';
		}
		if (hour >= 17 && hour < 21) {
			return 'evening';
		}
		if (hour >= 21 && hour < 6) {
			return 'night';
		}

		return 'day';
	};
	return (
		<Flex
			padding={'104px 32px 44px 32px'}
			direction={'column'}
			alignItems={'center'}
			gap={'28px'}
			alignSelf={'stretch'}
		>
			<Flex flexDirection={'column'} alignItems={'center'} gap={'12px'} width={'584px'}>
				<Typography variant={'DesktopH3Regular'} color={colors['gray']['1000']}>
					Good {getTimeOfDay()}, {user.firstName ?? user.email}.
				</Typography>
				<Typography variant={'DesktopH7Regular'} color={colors['gray']['1000']}>
					Discover insights in your metric catalog and business data.
				</Typography>
			</Flex>
			<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'} alignSelf={'stretch'}>
				<OmniSearch variant={'big'} onClick={onDiscoveryOpen} />
				<MetricCatalogHeaderV2Buttons />
			</Flex>
		</Flex>
	);
}
