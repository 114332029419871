import { ChartBar24 } from '@components/Icons';
import { NodeProps } from '@xyflow/react';
import colors from '../../../../style/colors';
import { SemanticHeader } from '../components/SemanticHeader';
import { SemanticNode } from '../components/SemanticNode';
import { MetricNodeType } from '../types';
import { SemanticNodeContent, SemanticPopupContent } from '../components/SemanticContent';
import { Label } from '../../Labels';
import Flex from '../../Flex';
import { SemanticFooter } from '../components/SemanticFooter';
import { SemanticParagraph } from '../components/SemanticParagraph';
import { SemanticBody } from '../components/SemanticBody';
import { MetricPagePath } from '../../../../pages/MetricPage/pageRoutesPaths';
import { ExploreSemanticObjectButton } from '../components/ExploreSemanticObjectButton';
import { SemanticTitle } from '../components/SemanticTitle';
import { SemanticField } from '../components/SemanticField';

export function MetricNode(props: NodeProps<MetricNodeType>) {
	const { data } = props;
	const { displayName, metricName, description, dimensions, direction } = data;

	const header = (
		<SemanticHeader
			title={displayName ?? metricName}
			subtitle={'Metric'}
			icon={<ChartBar24 />}
			iconColor={colors.violet[500]}
			iconBgColor={colors.violet[100]}
		/>
	);

	return (
		<SemanticNode
			popupContent={
				<SemanticPopupContent>
					<SemanticBody>
						<SemanticParagraph fallbackContent={'No description'}>{description}</SemanticParagraph>
						<SemanticField name={'Token name'} value={`$metric__${metricName}`} />
						{dimensions.length > 0 ? (
							<Flex direction={'column'} gap={'8px'}>
								<SemanticTitle>Dimensions ({dimensions.length})</SemanticTitle>
								<Flex gap={'6px'} wrap={'wrap'}>
									{dimensions.map((dimension, index) => (
										<Label key={index} colorScheme={'dark'} size={'small'} variant={'filled'}>
											{dimension.relationships
												.map((rel) => rel.name)
												.concat(dimension.name)
												.join('.')}
										</Label>
									))}
								</Flex>
							</Flex>
						) : undefined}
					</SemanticBody>
					<SemanticFooter>
						<ExploreSemanticObjectButton
							path={`/${MetricPagePath}/${metricName}`}
							text={'Explore metric'}
							metadata={{ objectType: 'metric', objectName: metricName, direction }}
						/>
					</SemanticFooter>
				</SemanticPopupContent>
			}
			{...props}
		>
			<SemanticNodeContent>{header}</SemanticNodeContent>
		</SemanticNode>
	);
}
