import Button from '@components/Button';
import { useConnectorModals } from '@pages/ConnectorsPage/components/AddDataSourceButton/useConnectorModals';
import {
	DataConnectorsDynamicModal,
	DataConnectorsModal,
	DataConnectorsPermissionModal,
} from '@components/TopNavigation/DataConnectorsModal';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { ButtonColorSchemaType } from '@components/Button/types';
import { ReactElement } from 'react';

export const AddDataSourceButton = ({
	reportOnClick,
	colorScheme,
	label,
	leftIcon,
	dataTestId,
}: {
	reportOnClick: VoidFunction;
	colorScheme: ButtonColorSchemaType;
	label: string;
	leftIcon?: ReactElement;
	dataTestId?: string;
}) => {
	const dataConnectorsPlaygroundEnabled = useFeatureFlag('pulse.sightfull2.playground.enable');
	const {
		selectedConnector,
		setSelectedConnector,
		isDataConnectorsModalOpen,
		onDataConnectorsModalOpen,
		onDataConnectorsModalClose,
		requestSetupConnector,
		dataConnectorsSubmit,
		isDataConnectorsDynamicModalOpen,
		onDataConnectorsDynamicModalClose,
		isDataConnectorsPermissionModalOpen,
		onDataConnectorsPermissionModalClose,
		openHelpModalWithReport,
	} = useConnectorModals();

	return (
		<>
			<Button
				variant="solid"
				size="small"
				colorScheme={colorScheme}
				onClick={() => {
					reportOnClick();
					setSelectedConnector(null);
					onDataConnectorsModalOpen();
				}}
				leftIcon={leftIcon}
				data-testid={dataTestId}
			>
				{label}
			</Button>

			<DataConnectorsModal
				selectedConnector={selectedConnector}
				setSelectedConnector={setSelectedConnector}
				isOpen={isDataConnectorsModalOpen}
				onClose={onDataConnectorsModalClose}
				requestSetupConnectorLazyQuery={requestSetupConnector}
				onNextButtonClick={dataConnectorsSubmit}
			/>
			<DataConnectorsDynamicModal
				onAddConnectorError={() => {
					if (dataConnectorsPlaygroundEnabled) openHelpModalWithReport();
				}}
				selectedConnector={selectedConnector}
				isOpen={isDataConnectorsDynamicModalOpen}
				onClose={() => {
					setSelectedConnector(null);
					onDataConnectorsDynamicModalClose();
				}}
			/>
			<DataConnectorsPermissionModal
				selectedConnector={selectedConnector}
				isOpen={isDataConnectorsPermissionModalOpen}
				onClose={onDataConnectorsPermissionModalClose}
			/>
		</>
	);
};
