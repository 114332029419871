import React, { forwardRef } from 'react';
import { useReportEvent } from '@services/analytics';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useModal } from '@hooks/ui/useModal';
import { ChevronDown16, ChevronUp16 } from '@icons/index';
import shadows from 'src/style/shadows';
import Flex from '@components/Flex';
import { ExploreMenu } from '@components/TopNavigation/ExploreMenu';
import { PopoverContent } from '@chakra-ui/react';
import Popover from '@components/Popover';
import Button from '@components/Button';
import { OmniSearch } from '@pages/MetricCatalog/OmniSearch';

const MetricCatalogHeaderMini = forwardRef<
	HTMLDivElement,
	{
		shouldDisplay: boolean;
		onDiscoveryOpen: () => void;
	}
>(({ shouldDisplay, onDiscoveryOpen }, ref) => {
	const { wrapWithReport } = useReportEvent();
	const isMetricPageEnabled = useFeatureFlag('pulse.sightfull2.createNewMetric.enable');
	const { isOpen, onOpen, onClose } = useModal();
	return (
		<Flex
			padding={'8px 32px'}
			justifyContent={'space-between'}
			alignItems={'center'}
			alignSelf={'stretch'}
			boxShadow={'0px -1px 0px 0px #DEE1E5 inset'}
			display={!shouldDisplay ? 'none' : undefined}
			height={'fit-content'}
			ref={ref}
		>
			<OmniSearch variant={'small'} onClick={onDiscoveryOpen} />
			<Flex width={'423px'} justifyContent={'flex-end'} alignItems={'center'} gap={'24px'}>
				{isMetricPageEnabled && (
					<Popover
						triggerElement={
							<Button
								leftIcon={isOpen ? <ChevronUp16 /> : <ChevronDown16 />}
								variant="solid"
								onClick={wrapWithReport(onOpen, 'create-new-metric-clicked')}
								size="small"
								colorScheme="blue"
								data-intercom-area={'main'}
								data-intercom-type={'button'}
								data-intercom-target={'create-new-metric-button'}
							>
								Create New metric
							</Button>
						}
						isOpen={isOpen}
						placement={'bottom-start'}
						onClose={onClose}
						popoverContentProps={{
							boxShadow: shadows['a-22'],
						}}
						closeOnBlur={true}
					>
						<PopoverContent>
							<ExploreMenu isCreateNewMetric onClose={onClose} />
						</PopoverContent>
					</Popover>
				)}
			</Flex>
		</Flex>
	);
});

MetricCatalogHeaderMini.displayName = 'MetricCatalogHeaderMini';
export default MetricCatalogHeaderMini;
