import { subject } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { ReactNode } from 'react';
import { AbilityContext, useIsEntitlementsFeatureEnabled } from 'src/services/entitlements';
import { Entitlement } from './types';

export const CanInternal = createContextualCan(AbilityContext.Consumer);

type CanProps = {
	children: ReactNode;
	not?: boolean;
} & Entitlement;

export const Can = ({ children, action, resourceId, resourceType, not = false }: CanProps) => {
	const isEntitlementsEnabled = useIsEntitlementsFeatureEnabled({ resourceType });
	if (!isEntitlementsEnabled) return children;

	return (
		<CanInternal do={action} this={subject(resourceType, { resourceId: resourceId })} not={not}>
			{children}
		</CanInternal>
	);
};
