import Flex from '@components/Flex';
import colors from '../../../../style/colors';
import { ReactNode } from 'react';

export function SemanticCard({
	children,
	highlighted,
	shadow,
	maxWidth,
	minWidth,
	borderColor = colors.gray[300],
	hoverBorderColor,
}: {
	children: ReactNode;
	highlighted?: boolean;
	shadow?: string;
	maxWidth?: string;
	minWidth?: string;
	borderColor?: string;
	hoverBorderColor?: string;
}) {
	return (
		<Flex
			maxWidth={maxWidth}
			minWidth={minWidth}
			shadow={shadow}
			overflow={'clip'}
			borderRadius={'8px'}
			border={'1px solid'}
			borderColor={borderColor}
			backgroundColor={'white'}
			_hover={{
				borderColor: hoverBorderColor,
			}}
			style={
				highlighted
					? colors.GradientBorder({
							background: `linear-gradient(45deg, ${colors.violet[200]}, ${colors.violet[400]})`,
							borderWidth: '4px',
							borderRadius: '12px',
					  })
					: undefined
			}
		>
			<Flex backgroundColor={'white'} direction={'column'} flex={1}>
				{children}
			</Flex>
		</Flex>
	);
}
