import { MetricCategory } from 'src/generated/graphql';

type ResponseCategoryObject = {
	__typename?: 'metric_categories_v2';
	category: string;
	description?: string | null;
	id: any;
};

export const normalizeResponseCategoryObject = (rawCategory?: ResponseCategoryObject): MetricCategory => {
	return {
		categoryId: rawCategory?.id,
		categoryName: rawCategory?.category || '',
		description: rawCategory?.description || '',
		isFullyDefined: true,
		metrics: [],
		__typename: 'MetricCategory',
	};
};

export const getCategoryMutationOptimisticResponse = ({
	categoryName,
	categoryDescription,
}: {
	categoryName: string;
	categoryDescription: string;
}) => {
	return {
		returning: [
			{
				id: 'someID',
				category: categoryName,
				description: categoryDescription,
				__typename: 'metric_categories_v2' as const,
			},
		],
		__typename: 'metric_categories_v2_mutation_response' as const,
	};
};
