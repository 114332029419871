import { Grid } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import { useReportEvent } from 'src/services/analytics';
import { CategoryV2, MetricMetadataV2 } from 'src/types/metric';
import { MetricCatalogCategoryV2 } from './MetricCatalogCategoryV2';
import { NoResultsCatalog } from './NoResultsCatalog';
import { NUMBERS_OF_CATEGORIES_TO_LOAD } from './utils';
import useNavigation from 'src/services/useNavigation';
import { MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';
import { MetricCatalogHeaderV2 } from '@pages/MetricCatalog/MetricCatalogHeaderV2';
import { PendingSetupCategory } from '@pages/MetricCatalog/PendingSetupCategory';
import { AskAIModal } from '@components/AskAI';
import { useModal } from '@hooks/ui/useModal';
import Flex from '@components/Flex';
import MetricCatalogHeaderMini from '@pages/MetricCatalog/MetricCatalogHeaderMini';
import { RecentMetrics } from './RecentMetrics';

export function MetricCatalogBodyV2({
	cache,
	categoriesWithFilteredMetrics,
	searchTerm,
	listRef,
	pendingSetupMetrics,
	paddingLeft,
	recentMetrics,
}: {
	cache: CellMeasurerCache;
	categoriesWithFilteredMetrics: CategoryV2[];
	searchTerm: string;
	listRef: React.RefObject<List>;
	pendingSetupMetrics: MetricMetadataV2[];
	recentMetrics: MetricMetadataV2[];
	paddingLeft: string;
}) {
	const { isOpen: isDiscoveryOpen, onOpen: onDiscoveryOpen, onClose: onDiscoveryClose } = useModal();
	const [shouldShowMiniHeader, setShouldShowMiniHeader] = useState<boolean>(false);
	const pinnedMiniHeaderRef = useRef<HTMLDivElement>(null);
	const metricCatalogHeaderMini = (
		<MetricCatalogHeaderMini
			shouldDisplay={shouldShowMiniHeader}
			onDiscoveryOpen={onDiscoveryOpen}
			ref={pinnedMiniHeaderRef}
		/>
	);
	const [pinnedMiniHeaderHeight, setPinnedMiniHeaderHeight] = useState(0);
	useEffect(() => {
		if (pinnedMiniHeaderRef.current) {
			setPinnedMiniHeaderHeight(pinnedMiniHeaderRef.current.clientHeight);
		}
	}, [pinnedMiniHeaderRef, shouldShowMiniHeader]);
	const hasPendingSetupMetrics = pendingSetupMetrics.length > 0;
	const hasRecentMetrics = recentMetrics.length > 0;
	const addedCategoriesNumber = hasPendingSetupMetrics ? 2 : 1;

	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { reportEvent } = useReportEvent();
	const reportSearchTerm = (isFound: boolean, currentSearchTerm: string) => {
		const event = isFound ? 'metric-catalog-search' : 'metric-catalog-search-not-found';
		reportEvent({ event: event, metaData: { currentSearchTerm } });
	};
	const debouncedReportRef = useRef(debounce(reportSearchTerm, 500));

	const isNoResults = categoriesWithFilteredMetrics.length == 0;

	useEffect(() => {
		const currentRef = debouncedReportRef.current;
		if (searchTerm) {
			currentRef(!isNoResults, searchTerm);
		}
		return () => currentRef.cancel();
	}, [searchTerm, isNoResults]);

	const debouncedResize = useDebouncedCallback(() => cache?.clearAll(), 500);

	const { getHref } = useNavigation();

	const getMetricPathObject = useCallback(
		(metric: MetricMetadataV2) => {
			const additionalSearchParams = new URLSearchParams();
			const isEditMetricEnabled = isSightfull2;
			const { name: metricName, defaultChartType: chartType, isFullyDefined } = metric;

			if (chartType) additionalSearchParams.append('chartType', chartType);
			if (isEditMetricEnabled && !isFullyDefined) additionalSearchParams.append('pageMode', 'edit');

			return {
				path: `/${MetricPagePath}/${metricName}`,
				additionalSearchParams,
			};
		},
		[isSightfull2]
	);

	const getMetricHref = useCallback(
		(metric: MetricMetadataV2) => {
			return getHref(getMetricPathObject(metric));
		},
		[getHref, getMetricPathObject]
	);

	const onResize = useCallback(() => debouncedResize(), [debouncedResize]);

	const recentMetricsElements = useMemo(
		() => <RecentMetrics recentMetrics={recentMetrics} getMetricHref={getMetricHref} padding={paddingLeft} />,
		[getMetricHref, paddingLeft, recentMetrics]
	);

	const ListRow = useCallback(
		({ index }: { index: number }) => {
			if (index === 0) {
				return (
					<>
						<MetricCatalogHeaderV2 onDiscoveryOpen={onDiscoveryOpen} />
						{hasRecentMetrics && (
							<Flex flexDirection="column" marginBottom={hasPendingSetupMetrics ? '60px' : '30px'}>
								{recentMetricsElements}
							</Flex>
						)}
					</>
				);
			}

			if (index === 1 && hasPendingSetupMetrics) {
				return (
					<PendingSetupCategory
						pendingSetupMetrics={pendingSetupMetrics}
						getMetricHref={getMetricHref}
						paddingLeft={paddingLeft}
					/>
				);
			}
			const fixedIndex = index - addedCategoriesNumber;
			const category = categoriesWithFilteredMetrics[fixedIndex];
			return (
				<>
					<Grid
						gridTemplateColumns={'repeat(12, 1fr [col-start])'}
						columnGap="16px"
						pt={!hasPendingSetupMetrics ? '20px' : '0'}
						mt={!hasPendingSetupMetrics ? '0' : '-16px'}
						pr={'32px'}
						pl={paddingLeft}
						rowGap="16px"
						data-intercom-area={'catalog'}
						data-intercom-type={'category'}
						data-intercom-target={category?.name}
						data-intercom-sequence={fixedIndex + 1}
					>
						<MetricCatalogCategoryV2 metricCategory={category} getMetricHref={getMetricHref} />
					</Grid>
				</>
			);
		},
		[
			addedCategoriesNumber,
			categoriesWithFilteredMetrics,
			getMetricHref,
			hasPendingSetupMetrics,
			onDiscoveryOpen,
			paddingLeft,
			pendingSetupMetrics,
			recentMetricsElements,
			hasRecentMetrics,
		]
	);
	const rowCount = categoriesWithFilteredMetrics.length + addedCategoriesNumber;
	const rows = useMemo(() => {
		const rowsList: JSX.Element[] = [];
		for (let i = 0; i < rowCount; i++) {
			rowsList.push(<ListRow index={i} />);
		}
		return rowsList;
	}, [ListRow, rowCount]);

	const CategoriesList = useMemo(() => {
		return (
			<AutoSizer onResize={onResize}>
				{({ width, height }) => (
					<>
						<List
							onRowsRendered={({ startIndex }) => {
								setShouldShowMiniHeader(startIndex != 0);
							}}
							ref={listRef}
							width={width}
							height={height - pinnedMiniHeaderHeight}
							rowHeight={cache.rowHeight}
							deferredMeasurementCache={cache}
							rowCount={rowCount}
							overscanRowCount={NUMBERS_OF_CATEGORIES_TO_LOAD}
							scrollToAlignment={'start'}
							rowRenderer={({ key, index, style, parent }) => {
								return (
									<CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
										{({ registerChild }) => (
											<div style={style} ref={(element) => registerChild?.(element as HTMLDivElement)}>
												{rows[index]}
											</div>
										)}
									</CellMeasurer>
								);
							}}
						/>
					</>
				)}
			</AutoSizer>
		);
	}, [cache, listRef, onResize, pinnedMiniHeaderHeight, rowCount, rows]);
	return (
		<>
			<AskAIModal isOpen={isDiscoveryOpen} onClose={onDiscoveryClose} onOpen={onDiscoveryOpen} />
			{isNoResults ? (
				<NoResultsCatalog />
			) : (
				<>
					{metricCatalogHeaderMini}
					{CategoriesList}
				</>
			)}
		</>
	);
}
