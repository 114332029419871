import { useCallback, useMemo, useState } from 'react';
import { SemanticRelationshipType } from '../../types';
import Flex from '@components/Flex';
import Button from '@components/Button';
import { ButtonProps } from '@components/Button/Button';
import { RelationshipPopupContent } from './RelationshipPopupContent';
import { ChevronLeft16, ChevronRight16 } from '@components/Icons';

export type MultiPageRelationshipsContentProps = {
	relationships: SemanticRelationshipType[];
};

export function MultiPageRelationshipsContent({ relationships }: MultiPageRelationshipsContentProps) {
	const [activePage, setActivePage] = useState(0);
	const totalPages = relationships.length;

	const buttonProps: Partial<ButtonProps> = useMemo(() => {
		return { size: 'micro', colorScheme: 'black', isIconOnly: true, variant: 'solid' };
	}, []);

	const onPrevClick = useCallback(() => {
		setActivePage((prev) => (prev - 1 + totalPages) % totalPages);
	}, [totalPages]);

	const onNextClick = useCallback(() => {
		setActivePage((prev) => (prev + 1) % totalPages);
	}, [totalPages]);

	return (
		<RelationshipPopupContent
			relationships={[relationships[activePage]]}
			isMultiTraversal={false}
			subtitle={`Relationship (${activePage + 1} of ${totalPages})`}
			headerPanel={
				<Flex direction={'row'} gap={'4px'}>
					<Button onClick={onPrevClick} {...buttonProps}>
						<ChevronLeft16 />
					</Button>
					<Button onClick={onNextClick} {...buttonProps}>
						<ChevronRight16 />
					</Button>
				</Flex>
			}
		/>
	);
}
