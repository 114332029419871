import { gql } from '@apollo/client';

export const GetEntityDefinition = gql`
	query GetEntityDefinition($entityName: String!) {
		getEntityDefinition(entityName: $entityName) {
			entityDefinition
			entityDefinitionVersion {
				definitionHash
			}
		}
	}
`;

export const RunNormalization = gql`
	mutation RunNormalization {
		runCore(partial: true) {
			status
		}
	}
`;

export const ValidateEntities = gql`
	query ValidateEntities($entityNames: [String!]!) {
		validateEntities(entityNames: $entityNames) {
			validateEntitiesSemanticsResponses {
				entityName
				errors
				isValid
				semanticToken
			}
		}
	}
`;

export const GetEntityRecords = gql`
	query GetEntityRecords(
		$entity: String!
		$additionalColumns: [String!]
		$itemsPerPage: Float
		$pageOffset: Float
		$filter: [Filter!]
	) {
		getEntityRecords(
			entity: $entity
			additionalColumns: $additionalColumns
			itemsPerPage: $itemsPerPage
			pageOffset: $pageOffset
			filter: $filter
		) {
			cols {
				aggregationFunction
				colDisplayName
				colName
				seriesFormatterKey
				isPrimaryKey
			}
			rows {
				cols
				id
			}
		}
	}
`;

export const GetSpecificMetricDefinition = gql`
	query GetSpecificMetricDefinition($metricName: String!) {
		getSpecificMetricDefinition(metricName: $metricName) {
			metricDefinition
			metricType
			metricDefinitionVersion {
				definitionHash
			}
		}
	}
`;

export const TemplateInfo = gql`
	query TemplateInfo($referralPageName: String, $templateName: String!, $templatesTag: String) {
		templateInfo(referralPageName: $referralPageName, templateName: $templateName, templatesTag: $templatesTag) {
			title
			oneLiner
			source
			metrics {
				id
				title
				oneLiner
				imageUrl
			}
		}
	}
`;

export const ListConnectors = gql`
	query ListConnectors {
		listConnectors {
			connectors {
				name
				connectorStatus
				createdAt
				type
				createdBy
				lastSuccessfulSync
				syncState
				connectorId
			}
		}
	}
`;

export const AddTemplate = gql`
	mutation AddTemplate($templateName: String!, $templatesTag: String, $useMock: Boolean = false) {
		addTemplate(templateName: $templateName, templatesTag: $templatesTag, useMock: $useMock) {
			status
			metricsAdded
		}
	}
`;

export const UpsertMetric = gql`
	mutation UpsertMetric(
		$currentMetricName: String!
		$metricDefinition: [String!]!
		$metricType: String!
		$categoryId: String
	) {
		upsertMetric(
			currentMetricName: $currentMetricName
			metricDefinition: $metricDefinition
			metricType: $metricType
			categoryId: $categoryId
		) {
			status
			metricName
			upsertMetricAction
			metricDefinitionVersion {
				definitionHash
			}
		}
	}
`;

export const deleteEntity = gql`
	mutation deleteEntity($entityName: String!) {
		deleteEntity(entityName: $entityName) {
			status
			entityName
		}
	}
`;

export const DeleteMetric = gql`
	mutation DeleteMetric($metricName: String!) {
		deleteMetric(metricName: $metricName) {
			status
			metricName
		}
	}
`;

export const ConnectorCreationRequirements = gql`
	query connectorCreationRequirements($serviceId: String!) {
		connectorCreationRequirements(serviceId: $serviceId) {
			requiredFields {
				title
				type
				defaultValue
				description
				placeHolder
			}
		}
	}
`;

export const VisitMetric = gql`
	mutation visitMetric($metric: String!) {
		visitMetric(metric: $metric) {
			status
		}
	}
`;

export const GetRecentMetrics = gql`
	query getRecentMetrics {
		getRecentMetrics {
			status
			recentMetrics
			__typename
		}
	}
`;

export const UpdateEntity = gql`
	mutation UpdateEntity($entityName: String!, $entityDefinition: [String!]!, $currentEntityVersion: String!) {
		updateEntity(
			currentEntityName: $entityName
			entityDefinition: $entityDefinition
			currentEntityVersion: $currentEntityVersion
		) {
			status
			semanticId
			updateSemanticAction
			semanticDefinitionVersion {
				definitionHash
				__typename
			}
			__typename
		}
	}
`;

export const CreateEntity = gql`
	mutation CreateEntity($entityDefinition: [String!]!) {
		createEntity(entityDefinition: $entityDefinition) {
			status
			semanticId
			semanticDefinitionVersion {
				definitionHash
				__typename
			}
			__typename
		}
	}
`;
