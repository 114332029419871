import Flex from '@components/Flex';
import Typography from '@components/Typography';
import colors from '../../../../../style/colors';

export type JoinOnStatementProps = {
	joinOn?: string;
	relationshipName?: string;
};

export function JoinOnStatement({ relationshipName, joinOn }: JoinOnStatementProps) {
	return (
		<Flex direction={'column'} gap={'8px'} borderColor={colors.gray[300]}>
			<Typography variant={'Paragraph12R'}>Join on {relationshipName ? `(${relationshipName})` : ''}</Typography>
			<Flex backgroundColor={colors.gray[200]} padding={'8px 12px'} borderRadius={'8px'}>
				<Typography variant={'SourceCodePro'} wordBreak={'break-word'} color={colors.gray[1000]}>
					<Flex fontSize={'smaller'}>{joinOn}</Flex>
				</Typography>
			</Flex>
		</Flex>
	);
}
