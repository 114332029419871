import { useCallback, useMemo } from 'react';
import useMentionsState from 'src/common/hooks/stores/useMentionsState';
import {
	GetUsersWithRolesOnResourceQuery,
	useAssignRolesToUsersOnResourceMutation,
	useGetUsersWithRolesOnResourceQuery,
} from 'src/generated/graphql';
import { GetUsersWithRolesOnResource } from 'src/queries/rbac';
import { SpecificResource } from '../Can/types';
import { isRole, RoleType } from './constants';

export function useResourceRoles({ resource }: { resource: SpecificResource }) {
	const { data, loading: isLoading } = useGetUsersWithRolesOnResourceQuery({
		variables: {
			resourceType: resource.resourceType,
			resourceId: resource.resourceId,
		},
	});
	const mentions = useMentionsState();
	const roles = useMemo<ResourceRoles | undefined>(() => {
		if (!data?.getUsersWithRolesOnResource) return;
		if (!mentions) return;
		const generalAccessRole = data.getUsersWithRolesOnResource.generalAccessRole ?? null;
		if (generalAccessRole != null && !isRole(generalAccessRole)) return;

		const roles = data.getUsersWithRolesOnResource.users
			.map((user) => {
				const mention = mentions.find((mention) => mention.id === user.userId);
				const userName = mention?.name ?? 'Unknown user';
				const avatarURL = mention?.avatarURL || undefined;
				const email = mention?.email || undefined;
				if (!isRole(user.role)) return undefined;
				return { ...user, userName, avatarURL, email, role: user.role };
			})
			.filter((user) => user !== undefined)
			.sort((a, b) => {
				return a.userName.localeCompare(b.userName);
			});
		return { generalAccessRole, users: roles };
	}, [data, mentions]);
	return { roles, isLoading };
}
export type ResourceRolesUser = {
	userName?: string;
	avatarURL?: string;
	email?: string;
	role: RoleType;
} & GetUsersWithRolesOnResourceQuery['getUsersWithRolesOnResource']['users'][0];

export type ResourceRoles = {
	users?: ResourceRolesUser[];
	generalAccessRole: RoleType | null;
};

export function useAssignRoleToUserOnResource({ resource }: { resource: SpecificResource }) {
	const [assignMutation, mutationResult] = useAssignRolesToUsersOnResourceMutation();
	const assignRoleToUserOnResource = useCallback(
		(userId: string, role: RoleType | null) => {
			void assignMutation({
				variables: {
					resourceId: resource.resourceId,
					resourceType: resource.resourceType,
					userRoles: [{ userId, role }],
				},
				optimisticResponse: {
					assignRolesToUsersOnResource: {
						status: 200,
						__typename: 'RoleAssignmentStatus',
					},
				},
				update: (cache, { data }) => {
					if (!data) return;
					const { status } = data.assignRolesToUsersOnResource;
					if (status !== 200) return;
					const existingData = cache.readQuery<GetUsersWithRolesOnResourceQuery>({
						query: GetUsersWithRolesOnResource,
						variables: {
							resourceType: resource.resourceType,
							resourceId: resource.resourceId,
						},
					});
					if (!existingData) return;
					const newUsers = existingData.getUsersWithRolesOnResource.users.map((user) => {
						if (user.userId === userId) {
							return { ...user, role };
						}
						return user;
					});
					if (!newUsers.find((user) => user.userId === userId)) {
						newUsers.push({ userId, role, missingPermissionsForRole: [] });
					}
					const newData = {
						getUsersWithRolesOnResource: {
							...existingData.getUsersWithRolesOnResource,
							users: newUsers,
						},
					};
					cache.writeQuery({
						query: GetUsersWithRolesOnResource,
						variables: {
							resourceType: resource.resourceType,
							resourceId: resource.resourceId,
						},
						data: newData,
					});
				},
			});
		},
		[assignMutation, resource.resourceId, resource.resourceType]
	);
	return [assignRoleToUserOnResource, mutationResult] as const;
}

export function useAssignGeneralAccessRoleOnResource({ resource }: { resource: SpecificResource }) {
	const [assignMutation, mutationResult] = useAssignRolesToUsersOnResourceMutation();
	const assignGeneralAccessRoleOnResource = useCallback(
		(role: RoleType | null) => {
			void assignMutation({
				variables: {
					resourceId: resource.resourceId,
					resourceType: resource.resourceType,
					generalAccessRole: { role },
				},
				optimisticResponse: {
					assignRolesToUsersOnResource: {
						status: 200,
						__typename: 'RoleAssignmentStatus',
					},
				},
				update: (cache, { data }) => {
					if (!data) return;
					const { status } = data.assignRolesToUsersOnResource;
					if (status !== 200) return;
					const existingData = cache.readQuery<GetUsersWithRolesOnResourceQuery>({
						query: GetUsersWithRolesOnResource,
						variables: {
							resourceType: resource.resourceType,
							resourceId: resource.resourceId,
						},
					});
					if (!existingData) return;
					const newData = {
						getUsersWithRolesOnResource: {
							...existingData.getUsersWithRolesOnResource,
							generalAccessRole: role,
						},
					};
					cache.writeQuery({
						query: GetUsersWithRolesOnResource,
						variables: {
							resourceType: resource.resourceType,
							resourceId: resource.resourceId,
						},
						data: newData,
					});
				},
			});
		},
		[assignMutation, resource.resourceId, resource.resourceType]
	);
	return [assignGeneralAccessRoleOnResource, mutationResult] as const;
}
