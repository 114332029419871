import { useCallback, useMemo, useState } from 'react';
import useMutation from 'src/common/hooks/fetching/useMutation';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { UpsertMetricMutation, UpsertMetricMutationVariables } from 'src/generated/graphql';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { removeEscaping } from 'src/models/YamlUtils/yamlUtils';
import { convertToValidCoreName, removeNonAlphabeticCharacters, removeUnderscoresAndCapitalize } from 'src/normalize';
import { UpsertMetric } from 'src/queries/core-manager';
import { useReportEvent } from 'src/services/analytics';
import { useInvalidateCache } from 'src/services/apollo';
import { useEntitlementsRefresh } from 'src/services/entitlements';
import useNavigationBlock from 'src/services/useNavigationBlock';
import { EditorFeature } from '../components/CalculatePanel/EditPanel';
import { MetricPagePath } from '../pageRoutesPaths';
import { formatBuilderFeatureEditMode } from '../utils/eventMetadata';
import { useMetricDerivedState } from './useMetricDerivedState';
import { useMetricEditorState } from './useMetricEditorState';

export const parseName = (name: string) => removeNonAlphabeticCharacters(removeUnderscoresAndCapitalize(name));

type OnHandleSaveSuccessType = (params: { redirectTo: string; additionalSearchParams: URLSearchParams }) => void;

export function useSaveAsNewMetric({
	selectedFeature,
	onSuccess,
	isPreviewEnabled,
}: {
	selectedFeature: EditorFeature;
	onSuccess?: OnHandleSaveSuccessType;
	isPreviewEnabled: boolean;
}) {
	const toast = useToast();
	const { reportEvent } = useReportEvent();
	const { invalidateCache } = useInvalidateCache();
	const { isCalculatingPreview } = useBuilderDerivedState();
	const { metricEditorLoadedState } = useMetricEditorState();
	const { errorMessage } = useMetricDerivedState();
	const [upsertMetric, { loading: isUpsertMetricLoading }] = useMutation<
		UpsertMetricMutation,
		UpsertMetricMutationVariables
	>(UpsertMetric);
	const [isInputModalInvalid, setIsInputModalInvalid] = useState(false);
	const {
		isOpen: isSaveAsNewRenameModalOpen,
		onOpen: onSaveAsNewRenameModalOpen,
		onClose: onSaveAsNewRenameModalClose,
	} = useModal();

	const { setIsNavigationBlocked } = useNavigationBlock();

	const isSaveAsNewEnabled = useMemo(() => {
		return !isPreviewEnabled && !errorMessage && !(isCalculatingPreview || !metricEditorLoadedState);
	}, [errorMessage, isCalculatingPreview, isPreviewEnabled, metricEditorLoadedState]);

	const refreshEntitlements = useEntitlementsRefresh();
	const saveAsNewMetric = useCallback(
		(metricName: string, categoryId?: string) => {
			if (isCalculatingPreview || !metricEditorLoadedState) return;
			reportEvent({
				event: 'metric-edit-save-as-new-save',
				metaData: {
					feature: 'Metric Builder',
					editMode: formatBuilderFeatureEditMode(selectedFeature),
					metricName,
				},
			});

			const valueToSave = metricEditorLoadedState.previewValue || metricEditorLoadedState.savedValue;
			const lines = valueToSave.split('\n');

			const correctLines = metricEditorLoadedState.previewValue.length ? removeEscaping(lines) : lines;
			correctLines[0] = `name: ${convertToValidCoreName(metricName?.trim())}`;

			const displayNameIndex = correctLines.findIndex((line) => line.includes('display_name'));
			if (displayNameIndex) {
				correctLines[displayNameIndex] = metricName
					? `  display_name: ${parseName(metricName)}`
					: correctLines[displayNameIndex];
			}
			upsertMetric({
				variables: {
					currentMetricName: '',
					metricDefinition: correctLines,
					metricType: metricEditorLoadedState.kind,
					categoryId,
				},
			})
				.then((result) => {
					invalidateCache();
					refreshEntitlements();
					const resultData = result?.data?.upsertMetric;
					if (!resultData) return;
					const additionalSearchParams = new URLSearchParams('pageMode=edit');
					setIsNavigationBlocked({ isBlocked: false });
					setTimeout(
						() =>
							onSuccess?.({
								redirectTo: `/${MetricPagePath}/${resultData.metricName}`,
								additionalSearchParams,
							}),
						0
					);

					onSaveAsNewRenameModalClose();
				})
				.catch((error) => {
					// TODO: CloudError
					if (error.message.includes('already exists')) {
						if (isSaveAsNewRenameModalOpen) {
							setIsInputModalInvalid(true);
							return;
						}
						onSaveAsNewRenameModalOpen();
						return;
					}
					toast({
						variant: 'error',
						message: 'Save failed, please review and try again.',
					});
				});
		},
		[
			isCalculatingPreview,
			metricEditorLoadedState,
			reportEvent,
			selectedFeature,
			upsertMetric,
			invalidateCache,
			setIsNavigationBlocked,
			onSaveAsNewRenameModalClose,
			onSuccess,
			toast,
			isSaveAsNewRenameModalOpen,
			onSaveAsNewRenameModalOpen,
		]
	);
	return {
		saveAsNewMetric,
		isUpsertMetricLoading,
		isSaveAsNewRenameModalOpen,
		onSaveAsNewRenameModalClose,
		onSaveAsNewRenameModalOpen,
		isInputModalInvalid,
		setIsInputModalInvalid,
		isSaveAsNewEnabled,
	};
}
