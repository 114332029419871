import Box from '@components/Box';
import Typography from '@components/Typography';
import { TypographyVariant } from '@components/Typography/types';

export type InitialsBoxProps = {
	text?: string;
	testId?: string;
	color?: string;
	bgColor?: string;
	borderRadius?: string;
	size?: string | number;
	mr?: string;
};

function InitialsBox({
	text,
	testId,
	size,
	mr,
	borderRadius = '4px',
	bgColor = 'blue.700',
	color = 'white',
}: InitialsBoxProps) {
	if (!text) {
		return null;
	}

	const initials = text
		.split(' ')
		.slice(0, 1)
		.map((s) => s[0]?.toUpperCase());

	const typoVariant: TypographyVariant = size === 'lg' ? 'Paragraph14SB' : 'Tags10B';
	const typoTestId = testId ? `${testId}-typography` : '';
	return (
		<Box
			bg={bgColor}
			mr={mr}
			borderRadius={borderRadius}
			data-testid={testId}
			minWidth={size}
			maxWidth={size}
			minHeight={size}
			maxHeight={size}
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			<Typography color={color} variant={typoVariant} testId={typoTestId}>
				{initials}
			</Typography>
		</Box>
	);
}

export default InitialsBox;
