import { PopoverContent } from '@chakra-ui/react';
import CustomEmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import Flex from '@components/Flex';
import Popover from '@components/Popover';
import { ReactNode, useEffect, useState } from 'react';
import './EmojiPicker.scss';

export const DEFAULT_EMOJIPICKER_VALUE = '🗂️';

export const EmojiPicker = ({
	isOpen,
	onOpen,
	onClose,
	onEmojiSelect,
	defaultEmojiValue,
}: {
	isOpen: boolean;
	onOpen: VoidFunction;
	onClose: VoidFunction;
	onEmojiSelect: (emoji: string) => void;
	defaultEmojiValue?: React.ReactNode | string;
}) => {
	const [selectedEmojiLabel, setSelectedEmojiLabel] = useState<string | ReactNode>(DEFAULT_EMOJIPICKER_VALUE);

	useEffect(() => {
		if (defaultEmojiValue) {
			setSelectedEmojiLabel(defaultEmojiValue);
		}
	}, [defaultEmojiValue]);

	const onEmojiClick = (emojiObj: EmojiClickData) => {
		setSelectedEmojiLabel(emojiObj.emoji);
		onClose();
		onEmojiSelect(emojiObj.emoji);
	};

	return (
		<Flex flexDirection={'column'} gap={'8px'}>
			<Popover
				isOpen={isOpen}
				placement={'bottom-start'}
				onClose={onClose}
				popoverContentProps={{
					borderRadius: '8px',
				}}
				triggerElement={
					<Flex
						fontSize={'20px'}
						lineHeight={'20px'}
						cursor="pointer"
						onClick={onOpen}
						maxW={'42px'}
						maxH={'42px'}
						padding="11px 12px"
						width="fit-content"
					>
						{selectedEmojiLabel}
					</Flex>
				}
			>
				<PopoverContent>
					<CustomEmojiPicker
						previewConfig={{ showPreview: false }}
						lazyLoadEmojis
						emojiStyle={EmojiStyle.APPLE}
						onEmojiClick={onEmojiClick}
					/>
				</PopoverContent>
			</Popover>
		</Flex>
	);
};
