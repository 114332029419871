import { Grid, GridItem } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import Link from 'src/common/components/Link';
import colors from 'src/style/colors';
import { useCategoriesWithBrokenMetrics } from './hooks/useCategoriesWithBrokenMetric';
import { MetricMetadataV2 } from 'src/types/metric';
import { RecentMetricCard } from './MetricCards/RecentMetricCard';

type RecentMetricsProps = {
	padding: string;
	getMetricHref: (metric: MetricMetadataV2) => string;
	recentMetrics: MetricMetadataV2[];
};

export const RecentMetrics = ({ padding, getMetricHref, recentMetrics }: RecentMetricsProps) => {
	const { categoriesWithBrokenMetrics } = useCategoriesWithBrokenMetrics();

	return !recentMetrics?.length ? null : (
		<>
			<Box px={padding}>
				<Box backgroundColor={colors.gray[200]} padding={'20px'} borderRadius={'8px'}>
					<Flex marginBottom={'16px'} alignItems={'center'} gap={'8px'}>
						<Typography variant={'DesktopH8Medium'} color="gray.1000">
							Recent metrics
						</Typography>
					</Flex>
					<Grid gridTemplateColumns={'repeat(12, 1fr [col-start])'} columnGap="16px" rowGap="16px">
						{recentMetrics?.map((metric, index) => {
							const withError = categoriesWithBrokenMetrics.some((el) => el.metricNames.includes(metric?.name));
							return (
								<GridItem
									key={`recent-metrics-${metric.name}-${metric.entity}`}
									colSpan={{ base: 3, xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }}
								>
									<Link href={getMetricHref(metric)} _hover={{ textDecoration: 'none' }} w="100%">
										<RecentMetricCard withError={withError} metric={metric} dataIntercomSequence={index + 1} />
									</Link>
								</GridItem>
							);
						})}
					</Grid>
				</Box>
			</Box>
		</>
	);
};
