import Box from '@components/Box';
import Flex from '@components/Flex';
import Card from '@components/Card';
import Typography from '@components/Typography';
import Tooltip from 'src/common/components/Tooltip';
import { MetricCardV2 } from './MetricCardV2';
import classes from './MetricCardV2.module.scss';
import { ErrorYellow16 } from 'src/common/components/Icons';
import colors from 'src/style/colors';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { useEntitlementsCheck } from 'src/services/entitlements';
import { MetricCardMoreButton } from './MetricCardMoreButton';
import { CardContentProps, CatalogCardProps } from '../types';

export function PendingSetupMetricCard({
	metric,
	withError = false,
	dataIntercomSequence,
	cardSize = 'int',
	imageUrl,
	thumbnail,
	noEffects = false,
	metricCategoryId,
}: CatalogCardProps) {
	const { metricCard, moreButton } = classes;

	const { id, name, displayName, isFullyDefined, entity, hide } = metric;

	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const hasEditEntitlement = useEntitlementsCheck({
		action: 'edit',
		resourceType: 'metric',
		resourceId: name,
	});

	const CardContent = ({
		handleClick,
		isOverflowingName,
		nameRef,
		entityDisplayName,
		SourceLogo,
		labelName,
	}: CardContentProps) => {
		return (
			<Card padding={0} radius={8} size={cardSize} onClick={handleClick} noHoverEffects={noEffects}>
				<Flex direction="column" alignItems={'flex-end'} position="relative" width={'100%'} className={metricCard}>
					<Box
						data-intercom-area={'catalog'}
						data-intercom-type={'metric'}
						data-intercom-target={displayName ?? name}
						data-intercom-sequence={dataIntercomSequence}
						width={'100%'}
					>
						<Flex alignItems={'center'} padding={'16px'} justifyContent="space-between">
							<Flex flexDirection={'column'} gap={'4px'}>
								<Flex gap={'8px'} alignItems={'center'} height={'auto'}>
									{!isFullyDefined && <ErrorYellow16 />}
									<Tooltip size="md" variant="fluid" label={isOverflowingName && (displayName ?? name)}>
										<Typography minLines={1} color={'gray.1000'} noOfLines={1} variant="DesktopH8Medium">
											<Box ref={nameRef}>{displayName ?? name}</Box>
										</Typography>
									</Tooltip>
								</Flex>
								<Typography color={entity ? colors.gray[700] : 'transparent'} cursor={'inherit'} variant="Paragraph12R">
									{entity ? entityDisplayName : 'Placeholder'}
								</Typography>
							</Flex>

							{SourceLogo && (
								<Tooltip label={labelName} size="sm">
									{SourceLogo}
								</Tooltip>
							)}
						</Flex>
					</Box>
					{/* After entitlements rolled out remove this duplication with Frontegg checks*/}
					{!noEffects && hasEditPermission && hasEditEntitlement && (
						<MetricCardMoreButton
							hidden={hide}
							metricDisplayName={displayName}
							metricName={name}
							className={moreButton}
							metricId={id}
							metricCategoryId={metricCategoryId}
							metricCategoryName={'pending-setup'}
							isPendingSetupCategory
						/>
					)}
				</Flex>
			</Card>
		);
	};

	return (
		<MetricCardV2
			metric={metric}
			categoryName={'pending-setup'}
			withError={withError}
			dataIntercomSequence={dataIntercomSequence}
			cardSize={cardSize}
			imageUrl={imageUrl}
			thumbnail={thumbnail}
			noEffects={noEffects}
			metricCategoryId={metricCategoryId}
			renderItem={({
				handleClick,
				CardPreview,
				isOverflowingName,
				nameRef,
				entityDisplayName,
				SourceLogo,
				labelName,
			}: CardContentProps) => (
				<CardContent
					handleClick={handleClick}
					CardPreview={CardPreview}
					isOverflowingName={isOverflowingName}
					nameRef={nameRef}
					entityDisplayName={entityDisplayName}
					SourceLogo={SourceLogo}
					labelName={labelName}
				/>
			)}
		/>
	);
}
