import { useMemo } from 'react';
import { EntityWithMetrics } from '../../../../../lib/completions/semanticTypes';
import { semanticDisplayName } from '../../../../../normalize';
import { OntologyPagePath } from '../../../../../pages/OntologyPage/OntologyBuilderPage';
import { ManyToOneThin20 } from '../../../Icons';
import { ExploreSemanticObjectButton } from '../../components/ExploreSemanticObjectButton';
import { SemanticBody } from '../../components/SemanticBody';
import { SemanticPopupContent } from '../../components/SemanticContent';
import { SemanticField } from '../../components/SemanticField';
import { SemanticFooter } from '../../components/SemanticFooter';
import { SemanticHeader } from '../../components/SemanticHeader';
import { SemanticParagraph } from '../../components/SemanticParagraph';
import { JoinOnStatementProps, JoinOnStatement } from './JoinOnStatement';
import { RelationshipsPath } from './RelationshipsPath';
import colors from '../../../../../style/colors';
import { useSemanticDefinitions } from '../../../../hooks/stores/useSemanticDefinitions';
import { SemanticRelationshipType } from '../../types';

export type RelationshipPopupContentProps = {
	relationships: SemanticRelationshipType[];
	isMultiTraversal: boolean;
	subtitle: string;
	headerPanel?: React.ReactNode;
};

export function RelationshipPopupContent({
	relationships,
	isMultiTraversal,
	subtitle,
	headerPanel,
}: RelationshipPopupContentProps) {
	const { semanticDefinitions } = useSemanticDefinitions();

	const allEntities = useMemo(
		() =>
			semanticDefinitions?.entities.reduce((acc: Record<string, EntityWithMetrics>, entity) => {
				acc[entity.name] = entity;
				return acc;
			}, {}) ?? {},
		[semanticDefinitions?.entities]
	);

	const semanticRelationships = useMemo(() => {
		return (
			relationships?.map((rel) => {
				const entity = allEntities[rel.entity];
				const foundRelationship = entity?.relationships?.find((entityRel) => entityRel.name === rel.name);
				return entity && foundRelationship ? { entity, ...foundRelationship } : undefined;
			}) ?? []
		).filter((rel) => rel !== undefined);
	}, [allEntities, relationships]);

	const relationshipDisplayName = useMemo(
		() => semanticRelationships.map((rel) => semanticDisplayName(rel)).join(' & '),
		[semanticRelationships]
	);

	const relationshipFullName = useMemo(
		() => semanticRelationships.map((rel) => rel.name).join('.'),
		[semanticRelationships]
	);

	const joinStatements: JoinOnStatementProps[] = useMemo(() => {
		return semanticRelationships.map((rel) => {
			return {
				relationshipName: isMultiTraversal ? rel.name : undefined,
				joinOn: rel.on,
			};
		});
	}, [isMultiTraversal, semanticRelationships]);

	return (
		<SemanticPopupContent>
			<SemanticHeader
				title={relationshipDisplayName}
				subtitle={subtitle}
				icon={<ManyToOneThin20 />}
				iconColor={colors.cyan[800]}
				iconBgColor={colors.cyan[100]}
				rightPanel={headerPanel}
			/>
			<SemanticBody>
				<RelationshipsPath relationships={semanticRelationships} allEntities={allEntities} />
				<SemanticParagraph fallbackContent={'No description'}>
					{semanticRelationships[0].meta?.description}
				</SemanticParagraph>
				<SemanticField name={'Token name'} value={`$${relationshipFullName}`} />
				{joinStatements.map((joinStatement, index) => (
					<JoinOnStatement key={index} {...joinStatement} />
				))}
			</SemanticBody>
			<SemanticFooter>
				<ExploreSemanticObjectButton
					path={`/${OntologyPagePath}/entity/${semanticRelationships[0].entity.name}/${semanticRelationships[0].name}`}
					text={'Explore relationship'}
					metadata={{ objectType: 'relationship', objectName: semanticRelationships[0].name }}
				/>
			</SemanticFooter>
		</SemanticPopupContent>
	);
}
