import useNavigation from 'src/services/useNavigation';
import { PlaygroundType, useAssignPlaygroundMutation } from 'src/generated/graphql';
import { useCallback, useEffect } from 'react';
import { useEnvironment } from 'src/stores/environment';
import { DEFAULT_PLAYGROUND_TENANT_ID } from '@pages/PlaygroundRedirectPage/types';

export default function PlaygroundRedirectPage({
	playgroundType,
	userId,
}: {
	playgroundType: PlaygroundType;
	userId: string;
}) {
	const { navigateAndRefresh } = useNavigation();
	const [assignPlaygroundMutation] = useAssignPlaygroundMutation();
	const { tenant } = useEnvironment();

	const moveUserToARRPlayground = useCallback(async () => {
		if (tenant != DEFAULT_PLAYGROUND_TENANT_ID) {
			return;
		}
		await assignPlaygroundMutation({
			fetchPolicy: 'no-cache',
			variables: {
				playgroundType,
				fronteggUserId: userId,
			},
		});
	}, [assignPlaygroundMutation, playgroundType, tenant, userId]);

	useEffect(() => {
		void moveUserToARRPlayground().then(() => {
			navigateAndRefresh({ path: '/' });
		});
	}, [moveUserToARRPlayground, navigateAndRefresh]);

	return <>{'Redirecting'}</>;
}
