import { EdgeLabelRenderer } from '@xyflow/react';
import { ManyToOneThin20 } from '@components/Icons';
import Flex from '@components/Flex';
import { FlowPopover } from '@components/Flow';
import { useReportEvent } from '@services/analytics';
import colors from '../../../../../style/colors';
import { SemanticRelationshipType } from '../../types';
import { SemanticPopup } from '../../components/SemanticPopup';
import { RelationshipPopupContent } from './RelationshipPopupContent';
import { useCallback } from 'react';

export type MetricRelationshipsEdgeButtonProps = {
	labelX: number;
	labelY: number;
	relationships?: SemanticRelationshipType[];
};

export function MetricRelationshipsEdgeButton({ labelX, labelY, relationships }: MetricRelationshipsEdgeButtonProps) {
	const isMultiTraversal = (relationships?.length ?? 0) > 1;

	const { reportEvent } = useReportEvent();
	const onPopoverTrigger = useCallback(() => {
		reportEvent({
			event: 'lineage-relationship-button-clicked',
			metaData: { relationshipType: 'metric', relationship: relationships?.map((rel) => rel.name).join('.') },
		});
	}, [relationships, reportEvent]);

	if (!relationships || relationships?.length === 0) return;
	return (
		<EdgeLabelRenderer>
			<FlowPopover
				triggerLocation={{ x: labelX, y: labelY }}
				onTrigger={onPopoverTrigger}
				popoverContent={
					<SemanticPopup>
						<RelationshipPopupContent
							relationships={relationships}
							isMultiTraversal={isMultiTraversal}
							subtitle={isMultiTraversal ? 'Multistep relationship' : 'Relationship'}
						/>
					</SemanticPopup>
				}
			>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					height={'28px'}
					width={'28px'}
					borderColor={colors.cyan[800]}
					borderRadius={'24px'}
					cursor={'pointer'}
					border={'1px solid'}
					color={colors.cyan[800]}
					backgroundColor={'white'}
				>
					<ManyToOneThin20 />
				</Flex>
			</FlowPopover>
		</EdgeLabelRenderer>
	);
}
