import { IMarkdownString } from 'monaco-editor';
import { stringify } from 'yaml';
import { Context, ParameterContext } from '../completionProvider';
import { AvailableParameter, isRawSQLDataSource } from '../semanticTypes';
import { ListValues, RangeValues } from '../semanticTypes/parameters.schema';
import { DESCRIPTION_BY_TYPE, DimensionTypes } from '../types';
import { buildDiv, buildDivChildren, buildLink, buildSectionColumn, buildYamlCodeBlock } from './baseBuilders';
import { getCssCompletionTooltip } from './cssTooltip';
import { getEntityCssCompletionTooltip } from './entityCompletionItem';
import { buildSourceFooter } from './sourceFooter';
import { useEnvironmentStore } from 'src/stores/environment';
import { addPrefixDollarSignIfNeeded } from '@pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { formatDimensionLabel } from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';

const MAX_SOURCE_LINES = 8;

export function getContextCompletionTooltip(context: Context, rawInsertText: string): IMarkdownString | undefined {
	if (context.type == 'entity') return getEntityCssCompletionTooltip(context.entity, rawInsertText); // TODO: because of historical changes there is both the context.type "entity" and a separate entity tooltip

	const image = { color: contextTypeColor[context.type], name: context.type };
	const isDimension = DimensionTypes.includes(context.type);
	const insertText = isDimension ? addPrefixDollarSignIfNeeded(rawInsertText) : rawInsertText;
	const rowTitle = context.display_name ?? context.name;
	const title = isDimension ? formatDimensionLabel(addPrefixDollarSignIfNeeded(rowTitle)) : rowTitle;
	const subtitleText = DESCRIPTION_BY_TYPE[context.type];
	const dimensionTypeIconName = isSourceDimension(context)
		? 'source-dimension-type-icon'
		: 'ontology-dimension-type-icon';
	const dimensionTypeBadgeText = isSourceDimension(context) ? 'Source dimension' : 'Ontology';
	const description = isSourceDimension(context)
		? `This source dimension is obtained from the source system.`
		: context.description;

	const tenantId = useEnvironmentStore.getState().environment.tenant;
	const openMetricLink =
		context.type === 'metric' || context.type === 'formula_metric'
			? `${window.location.origin}/metric/${context.metric.name}?_tenant=${tenantId}&pageMode=edit`
			: undefined;
	const path = context.type === 'relationship' ? buildRelationshipPath(context) : undefined;
	const additionalInformationRows = [];

	if (context.type === 'relationship') {
		const relationshipJoinKeysSection = buildRelationshipJoinKeysSection(context);
		if (relationshipJoinKeysSection) {
			additionalInformationRows.push(relationshipJoinKeysSection);
		}
	}

	if (DimensionTypes.includes(context.type)) {
		const dimensionSourceSection = buildDimensionSourceSection(context);
		if (dimensionSourceSection) {
			additionalInformationRows.push(dimensionSourceSection);
		}
	}

	if (context.type === 'parameter') {
		const defaultValueSection = buildParameterDefaultValueSection(context);
		additionalInformationRows.push(defaultValueSection);

		const valuesSection = buildParameterValuesSection(context);
		if (valuesSection) {
			additionalInformationRows.push(valuesSection);
		}
	}

	return getCssCompletionTooltip(
		{
			title,
			image,
			path,
			subtitle: subtitleText,
			typeBadgeIconName: isDimension ? dimensionTypeIconName : undefined,
			typeBadgeText: isDimension ? dimensionTypeBadgeText : undefined,
			description,
			additionalInformationRows,
			openMetricLink,
			sourceFooter: getSourceFooter(context),
		},
		insertText
	);
}

export const isSourceDimension = (context: Context) =>
	context.location && 'isSourceDimension' in context.location && context.location['isSourceDimension'];

export const getSourceFooter = (context: Context) => {
	if (!context.parent_entity) {
		return '';
	}
	const dataSource = context.parent_entity.data_source;
	const isRawSql = isRawSQLDataSource(dataSource);

	const sourceName = isRawSql ? 'Raw SQL' : dataSource.schema?.meta?.source;

	const entityLink =
		isSourceDimension(context) ||
		!['dimension', 'dimension_numeric', 'dimension_date', 'dimension_boolean', 'relationship'].includes(context.type)
			? undefined
			: `${window.location.origin}/ontology/entity/${context?.parent_entity?.name}/${context.name}`;

	return buildSourceFooter({
		source: sourceName ?? 'Unknown Source',
		entityLink,
		isWithSourceIcon: !isRawSql && !!sourceName,
		entityName: context?.parent_entity?.meta?.display_name ?? context.parent_entity?.name,
	});
};

function buildRelationshipJoinKeysSection(context: Context): string | undefined {
	if (context.location && 'on' in context.location && context.location['on']) {
		const yamlCodeBlock = buildYamlCodeBlock(stringify({ on: context.location['on'] }));
		return buildSectionColumn('full-width', 'Join keys', yamlCodeBlock);
	}
}
export function buildDimensionSourceSection(context: Context): string | undefined {
	if (context.location && 'sources' in context.location && context.location['sources']) {
		const fullText = stringify(context.location['sources']);
		const stringToShow = fullText.split('\n').slice(0, MAX_SOURCE_LINES).join('\n');
		const isLongString = fullText.length > stringToShow.length;
		const dimensionLink = `${window.location.origin}/ontology/entity/${context?.parent_entity?.name}/${context.name}`;
		const yamlCodeBlock = isLongString ? buildYamlCodeBlock(stringToShow, dimensionLink) : buildYamlCodeBlock(fullText);

		return buildSectionColumn('full-width', 'Sources', yamlCodeBlock);
	} else if (context.location && 'raw_sql' in context.location && context.location['raw_sql']) {
		const yamlCodeBlock = buildYamlCodeBlock(stringify({ raw_sql: context.location['raw_sql'] }));

		return buildSectionColumn('full-width', 'Raw Sql', yamlCodeBlock);
	}
}

function buildRelationshipPath(context: Context): string {
	const parentEntity = buildLink(
		`${window.location.origin}/ontology/entity/${context?.parent_entity?.name}`,
		buildDiv(
			'relationship-path-parent-entity',
			context.parent_entity?.meta?.display_name ?? context.parent_entity?.name
		)
	);
	const relationship = buildDiv('relationship-path-relationship', context.display_name ?? context.name);
	const childEntity = buildLink(
		`${window.location.origin}/ontology/entity/${context?.entity?.name}`,
		buildDiv('relationship-path-child-entity', context.entity?.meta?.display_name ?? context.entity?.name)
	);
	const pathIcon = buildDiv('relationship-path-icon');
	return buildSectionColumn(
		'',
		'',
		buildDivChildren('path-layout', parentEntity, pathIcon, relationship, pathIcon, childEntity)
	);
}

function buildParameterDefaultValueSection(context: ParameterContext): string {
	const parameter = context.location;

	return buildSectionColumn('', 'Default value', parameter.default.toString());
}

function buildParameterValuesSection(context: ParameterContext): string | undefined {
	const parameter = context.location;
	const valuesAsString = getValuesAsString(parameter);

	if (!valuesAsString) {
		return undefined;
	}

	return buildSectionColumn('', 'Values', valuesAsString);
}

function isAnyValue(parameter: AvailableParameter, values?: ListValues | RangeValues): values is undefined {
	return parameter.values_type === 'any';
}

function isValueFromList(parameter: AvailableParameter, values?: ListValues | RangeValues): values is ListValues {
	return parameter.values_type === 'list';
}

function isValueFromRange(parameter: AvailableParameter, values?: ListValues | RangeValues): values is RangeValues {
	return parameter.values_type === 'range';
}

function getValuesAsString(parameter: AvailableParameter) {
	const values = parameter.values;

	if (isAnyValue(parameter, values)) {
		return 'Any value';
	} else if (isValueFromList(parameter, values)) {
		return values.map((v) => v.value).join(', ');
	} else if (isValueFromRange(parameter, values)) {
		return `${values.from} to ${values.to}`;
	}

	return undefined;
}

export const contextTypeColor: Record<Context['type'], string> = {
	relationship: 'cyan',
	entity: 'cyan',
	parameter: 'blue',
	parameter_store: 'blue',
	dimension: 'blue',
	dimension_numeric: 'blue',
	metric: 'violet',
	formula_metric: 'violet',
	join: 'pink',
	function: 'green',
	dimension_date: 'blue',
	dimension_boolean: 'blue',
};
