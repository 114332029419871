import colors from 'src/style/colors';
import Avatar from '../Avatar/Avatar';
import Flex from '../Flex';
import { Error16 } from '../Icons';
import ListItem from '../ListItem';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import { canEditRole, rolesToString, RoleType } from './constants';
import { RoleSelect } from './RoleSelect/RoleSelect';
import { ResourceRolesUser } from './useResourceRoles';

export function UserListItem({
	user,
	isMe = false,
	onRoleChange,
	myRole,
}: {
	user: ResourceRolesUser;
	isMe?: boolean;
	onRoleChange?: (role: RoleType | null) => void;
	myRole: RoleType;
}) {
	const hasConflictingPermissions = user.missingPermissionsForRole.length > 0;
	return (
		<ListItem
			state="disabled"
			hasRoundedCorners
			size="md"
			label={
				<Flex color={colors.gray[1000]}>
					<Typography variant="DesktopH8Regular" color={'gray.1000'}>
						{user.userName}&nbsp;
					</Typography>
					{isMe && (
						<Typography variant="DesktopH8Regular" color={'gray.500'}>
							(You)
						</Typography>
					)}
				</Flex>
			}
			prefixComponent={
				<Flex width={'24px'} height={'24px'} borderRadius={'100%'} justifyContent={'center'} alignItems={'center'}>
					<Avatar imageUrl={user.avatarURL || ''} name={user.userName} shape="round" width={'24px'} height={'24px'} />
				</Flex>
			}
			suffixComponent={
				<Flex direction={'row'} alignItems={'center'} gap={'4px'}>
					{hasConflictingPermissions && (
						<Tooltip
							variant="fluid"
							size="sm"
							label="This person has a view only permissions in the platform and he won&#39;t be able to edit."
							maxWidth={'200px'}
						>
							<Error16 color={colors.yellow[600]} />
						</Tooltip>
					)}
					{onRoleChange && canEditRole(user.role, myRole) ? (
						<RoleSelect selectedRole={user.role} onSelect={onRoleChange} myRole={myRole} />
					) : (
						<Typography variant="DesktopH8Regular" color={'gray.1000'}>
							{rolesToString[user.role]}
						</Typography>
					)}
				</Flex>
			}
		/>
	);
}
