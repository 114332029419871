import { useCallback, useMemo } from 'react';
import { useMetricCategoriesV2 } from '../../MetricCatalog/hooks/useMetricCategoriesV2';
import { MetricMetadataV2 } from '../../../types/metric';
import { useSemanticDefinitions } from '../../../common/hooks/stores/useSemanticDefinitions';
import { useGetMetricLineageQuery } from '../../../generated/graphql';
import { useMetricEditorState } from './useMetricEditorState';
import { EntityWithMetrics } from '../../../lib/completions/semanticTypes';
import { MetricLineageGraphBuilder } from '../utils/MetricLineageGraphBuilder';

export function useMetricLineage(metricName: string) {
	const { semanticDefinitions } = useSemanticDefinitions();
	const [metricCategories, isLoadingCategories] = useMetricCategoriesV2();
	const { previewValueForCalculationOnly } = useMetricEditorState();

	const { data: metricLineageResult, loading: isLineageLoading } = useGetMetricLineageQuery({
		variables: { metricName, userMetricDefinitions: previewValueForCalculationOnly },
		fetchPolicy: 'no-cache',
	});

	const allMetrics = useMemo(() => {
		if (isLoadingCategories || metricCategories.length === 0) return;
		return metricCategories.reduce((acc: Record<string, MetricMetadataV2>, category) => {
			category.metrics?.reduce((acc, metric) => {
				acc[metric.name] = metric;
				return acc;
			}, acc);
			return acc;
		}, {});
	}, [metricCategories, isLoadingCategories]);

	const allEntities = useMemo(() => {
		return (
			semanticDefinitions?.entities.reduce((acc: Record<string, EntityWithMetrics>, entity) => {
				acc[entity.name] = entity;
				return acc;
			}, {}) ?? {}
		);
	}, [semanticDefinitions?.entities]);

	const calculateNodesAndEdges = useCallback(() => {
		if (!allMetrics || isLineageLoading || !metricLineageResult) return;
		const { metricLineage } = metricLineageResult;
		const lineageGraph = new MetricLineageGraphBuilder(allEntities, allMetrics);
		lineageGraph.buildGraph(metricLineage.metrics);
		return lineageGraph.getNodesAndEdges();
	}, [allEntities, allMetrics, isLineageLoading, metricLineageResult]);

	const nodesAndEdges = useMemo(() => calculateNodesAndEdges(), [calculateNodesAndEdges]);

	return { calculateNodesAndEdges, nodesAndEdges };
}
