import Flex from '../../Flex';

export function SemanticBody({ children }: { children?: React.ReactNode }) {
	if (!children) return;

	return (
		<Flex direction={'column'} gap={'20px'}>
			{children}
		</Flex>
	);
}
