import { Focus16, Minus16, Plus16, RefreshSmall16 } from '@components/Icons';
import { useState, useEffect, useCallback, useMemo } from 'react';
import colors from '../../../style/colors';
import {
	ReactFlow,
	MiniMap,
	ReactFlowProvider,
	useNodesInitialized,
	useReactFlow,
	useNodesState,
	Node,
	Edge,
	OnNodesChange,
	ReactFlowProps,
	MiniMapProps,
	NodeMouseHandler,
	OnNodeDrag,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import { EdgeMarkers } from './EdgeMarkers';
import { ActionsPanel, PanelButtonProps } from './ActionsPanel';
import { HeaderPanel } from './HeaderPanel';
import { AnalyticsContextWithMetadata, useReportEvent } from '@services/analytics';

export type FlowProps<TNode extends Node, TEdge extends Edge> = ReactFlowProps<TNode, TEdge> & {
	title?: string;
	closable?: boolean;
	onClose?: () => void;
	performNodesLayout?: (nodes: TNode[], edges: TEdge[]) => TNode[];
	minimap?: MiniMapProps<TNode>;
	actions?: {
		zoomIn: boolean;
		zoomOut: boolean;
		fitView: boolean;
		layout: boolean;
		custom?: PanelButtonProps[];
	};
};

export function Flow<TNode extends Node = Node, TEdge extends Edge = Edge>(props: FlowProps<TNode, TEdge>) {
	return (
		<ReactFlowProvider>
			<FlowWithAnalytics {...props} />
		</ReactFlowProvider>
	);
}

function FlowWithAnalytics<TNode extends Node, TEdge extends Edge>(props: FlowProps<TNode, TEdge>) {
	const rf = useReactFlow<TNode, TEdge>();

	return (
		<AnalyticsContextWithMetadata metadata={{ numberOfObjects: rf.getNodes().length }}>
			<FlowWithProvider {...props} />
		</AnalyticsContextWithMetadata>
	);
}

function FlowWithProvider<TNode extends Node, TEdge extends Edge>({
	title,
	closable,
	onClose,
	nodes,
	edges,
	style = {},
	performNodesLayout,
	onNodesChange,
	minimap,
	actions,
	children,
	...props
}: FlowProps<TNode, TEdge>) {
	const { reportEvent } = useReportEvent();
	const rf = useReactFlow<TNode, TEdge>();
	const nodesInitialized = useNodesInitialized({ includeHiddenNodes: false });
	const [layoutedNodes, setLayoutedNodes, onLayoutedNodesChange] = useNodesState<TNode>(nodes ?? []);
	const [layoutDone, setLayoutDone] = useState(false);

	const onLayout = useCallback(() => {
		if (performNodesLayout) setLayoutedNodes(performNodesLayout(rf.getNodes(), rf.getEdges()));
	}, [performNodesLayout, rf, setLayoutedNodes]);

	const panelActions: PanelButtonProps[] = useMemo(() => {
		const activeActions = actions ?? { fitView: true, zoomIn: true, zoomOut: true, layout: !!performNodesLayout };
		const panelActions: PanelButtonProps[] = [];
		if (activeActions.zoomOut) {
			panelActions.push({
				onClick: () => {
					void rf.zoomOut();
					reportEvent({ event: 'flow-zoom-out' });
				},
				icon: <Minus16 />,
				tooltip: 'Zoom out',
			});
		}
		if (activeActions.zoomIn) {
			panelActions.push({
				onClick: () => {
					void rf.zoomIn();
					reportEvent({ event: 'flow-zoom-in' });
				},
				icon: <Plus16 />,
				tooltip: 'Zoom in',
			});
		}
		if (activeActions.fitView) {
			panelActions.push({
				onClick: () => {
					void rf.fitView();
					reportEvent({ event: 'flow-fit-view' });
				},
				icon: <Focus16 />,
				tooltip: 'Fit view',
			});
		}
		if (activeActions.layout) {
			panelActions.push({
				onClick: () => {
					onLayout();
					reportEvent({ event: 'flow-layout-reset' });
				},
				icon: <RefreshSmall16 />,
				tooltip: 'Reset layout',
			});
		}
		if (activeActions.custom) {
			panelActions.push(...activeActions.custom);
		}

		return panelActions;
	}, [actions, onLayout, performNodesLayout, reportEvent, rf]);

	const onNodesChangeInternal: OnNodesChange<TNode> = useCallback(
		(changes) => {
			onLayoutedNodesChange(changes);
			onNodesChange?.(changes);
		},
		[onLayoutedNodesChange, onNodesChange]
	);

	const onNodeClick: NodeMouseHandler<TNode> = useCallback(
		(event, node) => {
			reportEvent({
				event: 'flow-node-clicked',
				metaData: { ...node.data, objectType: node.type, objectId: node.id },
			});
		},
		[reportEvent]
	);

	const onNodeDrag: OnNodeDrag<TNode> = useCallback(
		(event, node) => {
			reportEvent({
				event: 'flow-node-dragged',
				metaData: { ...node.data, objectType: node.type, objectId: node.id },
			});
		},
		[reportEvent]
	);

	useEffect(() => {
		setLayoutDone(false);
	}, [nodes, edges]);

	useEffect(() => {
		if (nodesInitialized && !layoutDone) {
			onLayout();
			setLayoutDone(true);
		}
	}, [nodesInitialized, rf, setLayoutedNodes, layoutDone, onLayout]);

	useEffect(() => {
		if (layoutDone) {
			void rf.fitView().catch((e) => console.error(e));
		}
	}, [rf, layoutDone]);

	return (
		<ReactFlow
			draggable={true}
			maxZoom={1}
			fitView={true}
			{...props}
			style={{
				borderRadius: '12px',
				backgroundColor: colors.gray[100],
				...style,
				opacity: !layoutDone ? 0 : 1,
			}}
			nodes={layoutedNodes}
			edges={edges}
			onNodesChange={onNodesChangeInternal}
			onNodeClick={onNodeClick}
			onNodeDragStop={onNodeDrag}
			proOptions={{ hideAttribution: true }}
		>
			<EdgeMarkers />
			<HeaderPanel title={title} closable={closable} onClose={onClose} />
			{panelActions.length > 0 && <ActionsPanel actions={panelActions} />}
			{minimap && <MiniMap pannable={true} zoomable={true} inversePan={true} position={'top-left'} {...minimap} />}
			{children}
		</ReactFlow>
	);
}
