import Flex from '@components/Flex';
import React from 'react';
import { NotificationBarHeight } from '@components/NotificationBar';
import { PlaygroundBannerLogo1, PlaygroundBannerLogo2 } from '@icons/index';
import Typography from '@components/Typography';
import Button from '@components/Button';
import { useReportEvent } from '@services/analytics';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { BOOK_DEMO_URL } from 'src/common/utils/consts';
import { AddDataSourceButton } from '@pages/ConnectorsPage/components/AddDataSourceButton/AddDataSourceButton';

export const PlaygroundBanner = () => {
	const { reportEvent } = useReportEvent();
	const isPlaygroundEnabled = useFeatureFlag('pulse.sightfull2.playground.enable');
	const locationPathName = location.pathname;

	if (!isPlaygroundEnabled) {
		return;
	}

	return (
		<>
			<Flex
				maxH={`${NotificationBarHeight}px`}
				p={'10px 32px 10px 24px'}
				backgroundColor="gray.1050"
				alignItems="center"
				justifyContent="space-between"
			>
				<Flex gap={'8px'} alignItems={'center'}>
					<Flex gap={'4px'}>
						<PlaygroundBannerLogo1 />
						<PlaygroundBannerLogo2 />
					</Flex>
					<Typography variant={'DesktopH8Regular'} color={'white'}>
						You are exploring Sightfull’s interactive demo.
					</Typography>
				</Flex>
				<Flex gap={'12px'}>
					<Button
						variant={'ghost'}
						colorScheme={'white'}
						size={'small'}
						onClick={() => {
							reportEvent({
								event: 'playground-banner-schedule-call',
								metaData: {
									location: locationPathName,
								},
							});
							window.open(BOOK_DEMO_URL, '_blank');
						}}
					>
						Book a demo
					</Button>
					<AddDataSourceButton
						colorScheme={'white'}
						label={'Connect your data'}
						reportOnClick={() => {
							reportEvent({
								event: 'playground-banner-connect-data',
								metaData: {
									location: locationPathName,
								},
							});
						}}
					/>
				</Flex>
			</Flex>
		</>
	);
};
