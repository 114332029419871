import colors from '../../../../style/colors';
import Box from '../../Box';
import Flex from '../../Flex';
import Typography from '../../Typography';

export type SemanticFieldProps = {
	name: string;
	value: string;
};

export function SemanticField({ name, value }: SemanticFieldProps) {
	return (
		<Box flex={1}>
			<Flex direction={'row'} gap={'32px'} justifyContent={'space-between'} flex={1}>
				<Typography variant={'Paragraph12R'} color={colors.gray[700]} wordBreak={'keep-all'} whiteSpace={'nowrap'}>
					{name}
				</Typography>
				<Typography variant={'Paragraph12R'} color={colors.gray[1000]} wordBreak={'break-all'}>
					{value}
				</Typography>
			</Flex>
		</Box>
	);
}
