import { Fragment, useMemo } from 'react';
import Flex from '@components/Flex';
import useUser from 'src/common/hooks/stores/useUser';
import { useListAllFoldersSubscription } from 'src/generated/graphql';
import { DashboardFolder } from './DashboardFolder';

export const DashboardFolderList = () => {
	const [{ id: my_id }] = useUser();

	const { data: foldersList } = useListAllFoldersSubscription({
		variables: { my_id: my_id },
	});

	const foldersCollection = useMemo(
		() =>
			foldersList?.folders.map((folder) => {
				const parcedFolder = {
					folderName: folder.name,
					emoji: folder.emoji,
					id: folder.id,
					dashboards: folder?.dashboards || [],
				};
				return (
					<Fragment key={folder.id}>
						<DashboardFolder folder={parcedFolder} />
					</Fragment>
				);
			}),
		[foldersList?.folders]
	);

	return <Flex flexDirection={'column'}>{foldersCollection}</Flex>;
};
