import Typography from '../../Typography';
import colors from '../../../../style/colors';
import { ReactNode } from 'react';
import { SemanticTitle } from './SemanticTitle';
import Flex from '../../Flex';

export type SemanticParagraphProps = {
	title?: string;
	fallbackContent?: ReactNode;
	children?: ReactNode;
};

export function SemanticParagraph({ children, title, fallbackContent }: SemanticParagraphProps) {
	if (!children && !fallbackContent) return;

	return (
		<Flex direction={'column'} gap={'4px'}>
			{title && <SemanticTitle>{title}</SemanticTitle>}
			<Typography
				variant={'Paragraph12R'}
				color={children ? colors.gray[700] : colors.gray[500]}
				wordBreak={'break-word'}
			>
				{children ?? fallbackContent}
			</Typography>
		</Flex>
	);
}
