import { useCallback, useState } from 'react';
import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
import { useModal } from '@hooks/ui/useModal';
import { useUserRequestSetupConnectorLazyQuery } from 'src/generated/graphql';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { getConnectorsName } from '@pages/ConnectorsPage/utils/utils';
import { useReportEvent } from '@services/analytics';

export const useConnectorModals = () => {
	const { reportEvent } = useReportEvent();
	const hasWritePermission = usePermissionCheck().isHavingPermission(Permissions.writeConnector);
	const dataConnectorsPlaygroundEnabled = useFeatureFlag('pulse.sightfull2.playground.enable');
	const [selectedConnector, setSelectedConnector] = useState<DataConnectorInfo | null>(null);
	const {
		isOpen: isDataConnectorsModalOpen,
		onOpen: onDataConnectorsModalOpen,
		onClose: onDataConnectorsModalClose,
	} = useModal();

	const {
		isOpen: isDataConnectorsDynamicModalOpen,
		onOpen: onDataConnectorsDynamicModalOpen,
		onClose: onDataConnectorsDynamicModalClose,
	} = useModal();
	const {
		isOpen: isDataConnectorsPermissionModalOpen,
		onOpen: onDataConnectorsPermissionModalOpen,
		onClose: onDataConnectorsPermissionModalClose,
	} = useModal();
	const { isOpen: isHelpModalOpened, onOpen: onHelpModalOpen, onClose: onHelpModalClose } = useModal();

	const [requestSetupConnector, { data: requestSetupConnectorResponse, loading: requestSetupConnectorLoading }] =
		useUserRequestSetupConnectorLazyQuery({ fetchPolicy: 'no-cache' });

	const dataConnectorsSubmit = () => {
		if (hasWritePermission || dataConnectorsPlaygroundEnabled) {
			onDataConnectorsDynamicModalOpen();
		} else {
			onDataConnectorsPermissionModalOpen();
		}
	};

	const openHelpModalWithReport = useCallback(() => {
		if (isHelpModalOpened) {
			reportEvent({
				event: 'connectors-playground-authorization-failed-modal-displayed',
				metaData: { name: getConnectorsName() },
			});
		}
		onHelpModalOpen();
	}, [isHelpModalOpened, onHelpModalOpen, reportEvent]);

	return {
		selectedConnector,
		setSelectedConnector,
		isDataConnectorsModalOpen,
		onDataConnectorsModalOpen,
		onDataConnectorsModalClose,
		requestSetupConnector,
		requestSetupConnectorResponse,
		requestSetupConnectorLoading,
		dataConnectorsSubmit,
		isDataConnectorsDynamicModalOpen,
		onDataConnectorsDynamicModalClose,
		isDataConnectorsPermissionModalOpen,
		onDataConnectorsPermissionModalClose,
		openHelpModalWithReport,
		isHelpModalOpened,
		onHelpModalClose,
	};
};
