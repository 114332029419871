import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import { AccessController } from '@components/AccessController';
import Box from '@components/Box';
import Button from '@components/Button';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import classes from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesNavigationDrawer.module.scss';
import ListItem from '@components/ListItem';
import Popover from '@components/Popover';
import { ArrowUpRight, DeleteTrash16, EditFull16, More16 } from '@icons/index';
import { Collection } from '@layout/Menu/NavigationDrawer/types';
import { useReportEvent } from '@services/analytics';
import useNavigation, { NavigationOptions } from '@services/useNavigation';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { Permissions } from 'src/types/environment';

type PopoverMenuItemsProps = {
	onRename: VoidFunction;
	onDelete: VoidFunction;
	onNavigate: VoidFunction;
};

export const SpaceMoreButton = ({
	collection,
	setIsPanelActingLikeOnHover,
	onCreateEditModalOpen,
	onDeleteModalOpen,
	setEditedItem,
	setDeletedItem,
	navigateToCollection,
}: {
	collection: Collection;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
	onCreateEditModalOpen: VoidFunction;
	onDeleteModalOpen: VoidFunction;
	setEditedItem: (collection: Collection) => void;
	setDeletedItem: (collection: Collection) => void;
	navigateToCollection: (
		collectionType: string,
		collectionId: string,
		collectionName: string,
		navigate: (navOptions: NavigationOptions) => void,
		isNewTab?: boolean
	) => void;
}) => {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const { isOpen, onClose, onOpen } = useDisclosure();

	const onMenuOpen = () => {
		onOpen();
		setIsPanelActingLikeOnHover(true);
	};

	const onMenuClose = () => {
		onClose();
		setIsPanelActingLikeOnHover(false);
	};

	const handleDelete = (collection: Collection) => {
		onDeleteModalOpen();
		setDeletedItem(collection);
	};

	const handleRename = (collection: Collection) => {
		setEditedItem(collection);
		onCreateEditModalOpen();
	};

	const PopoverMenuItems = ({ onNavigate, onRename, onDelete }: PopoverMenuItemsProps) => (
		<>
			<PopoverContent>
				<Flex alignSelf="center" direction={'column'} py="8px" width="175px" borderRadius="4px">
					<ListItem
						color="gray.1000"
						hoverColor={'gray.200'}
						label="Open in new tab"
						size="sm"
						prefixIcon={<ArrowUpRight />}
						onClick={onNavigate}
					/>
					<AccessController permission={Permissions.updateCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'gray.200'}
							size="sm"
							label="Edit"
							prefixIcon={<EditFull16 />}
							onClick={onRename}
						/>
					</AccessController>
					<Box px={'12px'}>
						<Divider direction="horizontal" my="8px" />
					</Box>
					<AccessController permission={Permissions.deleteCollection}>
						<ListItem
							color="gray.1000"
							hoverColor={'blue.100'}
							size="sm"
							label="Delete"
							prefixIcon={<DeleteTrash16 />}
							onClick={onDelete}
						/>
					</AccessController>
				</Flex>
			</PopoverContent>
		</>
	);

	return (
		<Box className={classes.moreButton}>
			<Popover
				placement="bottom-start"
				variant="solid"
				isOpen={isOpen}
				onClose={onMenuClose}
				triggerElement={
					<Flex position="relative">
						<Button
							size="xxs"
							variant="outline"
							colorScheme="gray"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								reportEvent({ event: 'collection-dots-menu-clicked', metaData: { name: collection.name } });
								if (isOpen) onMenuClose();
								else onMenuOpen();
							}}
							isActive={isOpen}
							isIconOnly={true}
							color={'gray.900'}
							_hover={{ bgColor: isOpen ? 'transparent' : 'gray.200' }}
							blendMode={'multiply'}
						>
							<More16 />
						</Button>
					</Flex>
				}
				popoverContentProps={{
					boxShadow: shadows['panelShadow'],
					borderRadius: '8px',
					border: `1px solid ${colors.gray[300]}`,
				}}
			>
				<PopoverMenuItems
					onNavigate={() => {
						reportEvent({ event: 'open-collection-in-new-tab', metaData: { name: collection.name } });
						navigateToCollection(collection.collection_type, collection.id, collection.name, navigate, true);
					}}
					onRename={() => {
						reportEvent({
							event: 'sidebar-edit-item',
							metaData: { itemType: collection.collection_type, name: collection.name },
						});
						handleRename(collection);
					}}
					onDelete={() => {
						reportEvent({
							event: 'sidebar-delete-item',
							metaData: { itemType: collection.collection_type, name: collection.name },
						});
						handleDelete(collection);
					}}
				/>
			</Popover>
		</Box>
	);
};
