import { AbilityBuilder, AnyAbility, createMongoAbility, subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { Entitlement, ResourceType } from 'src/common/components/Can/types';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import { useGetUserRawEntitlementsRulesQuery } from 'src/generated/graphql';

export const AbilityContext = createContext<AnyAbility>(createMongoAbility());
export const EntitlementsContext = createContext<VoidFunction>(() => void 0);

export function PulseAbilityProviderGuard({ children }: { children: ReactNode }) {
	const isEntitlementsEnabled = useIsEntitlementsFeatureEnabled({ resourceType: 'any' });

	if (!isEntitlementsEnabled) return <>{children}</>;
	return <PulseAbilityProvider>{children}</PulseAbilityProvider>;
}

function PulseAbilityProvider({ children }: { children: ReactNode }) {
	const { data, refetch } = useGetUserRawEntitlementsRulesQuery({ fetchPolicy: 'network-only' });

	const ability = useMemo(() => {
		if (!data) return createMongoAbility();
		const builder = new AbilityBuilder(createMongoAbility);
		for (const rule of data.getUserRawEntitlementsRules.userRawEntitlementsRules) {
			builder.can(rule.action, rule.subject, rule.conditions);
		}
		return builder.build();
	}, [data]);

	const refresh = useCallback(async () => {
		await refetch();
	}, [refetch]);

	return (
		<EntitlementsContext.Provider value={refresh}>
			<AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
		</EntitlementsContext.Provider>
	);
}

export function useIsEntitlementsFeatureEnabled({ resourceType }: { resourceType: ResourceType | 'any' }) {
	const isMetricEntitlementsEnabled = useFeatureFlag('pulse.sightfull2.entitlements.metrics.enable');
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	if (!isSightfull2) return false;
	if (resourceType === 'any') return isMetricEntitlementsEnabled;
	if (resourceType === 'metric') return isMetricEntitlementsEnabled;

	return false;
}

export function useIsRBACPlanEnabled() {
	const { plan } = useTenantConfig();
	return !!plan?.resource_rbac;
}

export function useEntitlementsCheck(entitlement: Entitlement): boolean {
	const ability = useAbility(AbilityContext);
	const isEntitlementsEnabled = useIsEntitlementsFeatureEnabled({ resourceType: entitlement.resourceType });
	return (
		!isEntitlementsEnabled ||
		ability.can(entitlement.action, subject(entitlement.resourceType, { resourceId: entitlement.resourceId }))
	);
}

export function useEntitlementsRefresh() {
	return useContext(EntitlementsContext);
}
