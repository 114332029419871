import { Flex } from '@chakra-ui/react';
import { Provider } from 'jotai';
import { useEffect, useState } from 'react';
import SomethingWentWrongError from 'src/common/components/ErrorWentWrong/SomethingWentWrongError';
import MetricViewLayout from 'src/common/components/MetricView';
import InteractiveMetricChat from 'src/common/components/MetricView/InteractiveMetricChat';
import { useSignalGet } from 'src/common/hooks/fetching/useSignalGet';
import { extractMetricNameAndSearchParams } from 'src/common/utils/signalUtils';
import Page from 'src/layout/Page';
import { TableRefAtom } from 'src/pages/MetricPage/atoms/TableRef';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { CollectionType } from 'src/types/signal';

import { getInitialDerivedStateAtom } from '../MetricPage/atoms/DerivedState';
import { IsMetricPageURLBasedAtom, MetricPageSearchParamsAtom } from '../MetricPage/atoms/MetricPageSearchParams';

import isEqual from 'lodash/isEqual';
import LeftExpandCollapsePanel from 'src/common/components/LeftExpandCollapsePanel';
import { useGetDashboardSubscription } from 'src/common/hooks/fetching/useDashboards';
import { useGetWorkspacesSubscription } from 'src/common/hooks/fetching/useWorkspaces';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useUser from 'src/common/hooks/stores/useUser';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';
import { DrillDownPanel } from '../MetricPage/components/DrillDownPanel';
import FiltersAndBreakdownsModal from '../MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import { useCoreNodeScheme } from '../MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useNodeScheme } from '../MetricPage/components/FiltersAndBreakdown/NodeScheme/useNodeScheme';
import useFiltersAndBreakdown from '../MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { LegendPanel } from '../MetricPage/components/LegendsPanel';
import RulesEngineRunner from '../MetricPage/components/RulesEngineRunner';
import { useMetricDerivedState } from '../MetricPage/hooks/useMetricDerivedState';
import { shouldLegendPanelExpand } from '../MetricPage/utils';
import { useNormalizedSearchParams } from '../MetricPage/utils/useNormalizedSearchParams';
import ResponsiveHeader from '../Spaces/common';
import { LoadingSignalPage } from './SkeletonComponents/LoadingSignalPage';
import { SignalPageConfigAtom } from './atoms/SignalPageConfig';
import Header from './components/Header';
import { useCollectionId } from './hooks/useCollectionId';
import { useSignal } from './hooks/useSignal';
import { useSignalPageSearchParams } from './hooks/useSignalPageSearchParams';

export function SignalPage({ collectionType }: { collectionType: CollectionType }) {
	const { dashboardId, workspaceId, signalId } = useSignalPageSearchParams();
	const collectionId = collectionType === 'workspace' ? workspaceId : dashboardId;

	const { data, loading, error } = useSignalGet({ signal_id: signalId });

	if (error) return <SomethingWentWrongError title="Oh no. Couldn't find this signal." />;
	if (loading) return <LoadingSignalPage />;

	if (!data || !data.signals_by_pk) return <SomethingWentWrongError title="Oh no. Couldn't find this signal." />;

	const signal = data.signals_by_pk;

	const { metricName, searchParams } = extractMetricNameAndSearchParams(decodeURIComponent(signal.attachment));

	return (
		<Provider
			initialValues={[
				[MetricPageSearchParamsAtom, { metricName, searchParams }],
				getInitialDerivedStateAtom(),
				[IsMetricPageURLBasedAtom, false],
				[SignalPageConfigAtom, { signal, collectionId: collectionId }],
				[TableRefAtom, null],
			]}
		>
			<Page page="Signal Page">
				<RulesEngineRunner>
					<SignalPageGuard initialSearchParams={searchParams} collectionType={collectionType} />
				</RulesEngineRunner>
			</Page>
		</Provider>
	);
}

function SignalPageGuard({
	collectionType,
	initialSearchParams,
}: {
	collectionType: CollectionType;
	initialSearchParams: URLSearchParams;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { reportEvent } = useReportEvent();
	const collectionId = useCollectionId();
	const [dashboardsQueryData] = useGetDashboardSubscription({
		id: collectionId,
	});
	const [workspaceQueryData] = useGetWorkspacesSubscription({
		id: collectionId || '',
	});
	const collection =
		collectionType == 'dashboard' ? dashboardsQueryData?.dashboards[0] : workspaceQueryData?.workspaces[0];

	const isEditMetricEnabled = isSightfull2;
	const investigateModePanelWidth = 400;

	const collectionPath = collectionType === 'workspace' ? 'workspace' : 'dashboard';
	const { navigate } = useNavigation();
	const {
		isLoading: isMetricStateLoading,
		metricNameWithFlavor,
		periodRange,
		objectsTypes,
		errorMessage,
	} = useMetricDerivedState();
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const [isLegendPanelExpanded, setIsLegendPanelExpanded] = useState(shouldLegendPanelExpand());
	const { searchParams } = useMetricPageSearchParams();
	const initialSearchParamsNormalized = useNormalizedSearchParams({ searchParams: initialSearchParams });
	const [isParamsChanged, setIsParamsChanged] = useState(false);
	const signal = useSignal();
	const [{ id: userId }] = useUser();
	const [isGenAIPopoverOpen, setIsGenAIPopoverOpen] = useState(false);

	useEffect(() => {
		setIsParamsChanged(!isEqual(searchParams, initialSearchParamsNormalized));
	}, [searchParams, initialSearchParamsNormalized]);

	const nodeScheme = useNodeScheme({
		metricName: metricNameWithFlavor,
		periods: periodRange.asAbsoluteRange.toMetricPeriods().map((e) => e.id),
		objectsTypes,
	});

	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes,
		readyToFetch: !isMetricStateLoading,
	});

	const toggleGenAIPopover = () => {
		setIsGenAIPopoverOpen(!isGenAIPopoverOpen);
	};

	const navigateToMetricPage = () => {
		if (!signal?.attachment) return;
		reportEvent({
			event: 'signal-explore-metric-clicked',
			metaData: {
				feature: 'Signal header',
				signalId: signal?.id,
				collectionType,
				attachment: signal.attachment,
				isSameUserSignal: signal.author_id == userId,
				isSightfullSignal: signal.author.email?.includes('@sightfull'),
			},
		});
		const { metricName, searchParams } = extractMetricNameAndSearchParams(decodeURIComponent(signal.attachment));
		const encodedMetricName = encodeURIComponent(metricName);
		navigate({ path: `/${MetricPagePath}/${encodedMetricName}`, isNewTab: true, additionalSearchParams: searchParams });
	};

	return (
		<>
			<Flex overflowY={'auto'} direction={'column'} height={'100%'} borderRadius={'16px 16px 0 0'}>
				<ResponsiveHeader
					renderItem={(isTiny: boolean) => (
						<Header
							isActionsDisabled={!!errorMessage}
							navigateToMetricPage={navigateToMetricPage}
							isParamsChanged={isParamsChanged}
							isTiny={isTiny}
							collectionType={collectionType}
							collectionId={collectionId}
							collectionTitle={collection?.name || ''}
							onCloseClicked={() => {
								navigate({ path: `${collectionPath}/${collectionId}` });
							}}
						/>
					)}
				/>

				<Flex grow={1} justify={'space-between'} height={'0'}>
					<LeftExpandCollapsePanel
						width={isEditMetricEnabled ? investigateModePanelWidth : 280}
						renderItem={(isShowingFullSize: boolean) => (
							<DrillDownPanel
								isShowingFullSize={isShowingFullSize}
								onModalOpen={modalActions.onOpen}
								toggleGenAIPopover={toggleGenAIPopover}
							/>
						)}
					/>
					<Flex grow={1} flexDirection="column">
						<MetricViewLayout navigateToMetricPage={navigateToMetricPage} onModalOpen={modalActions.onOpen} />
					</Flex>
					{!errorMessage && (
						<LegendPanel
							isLoading={isMetricStateLoading}
							isExpanded={isLegendPanelExpanded}
							setIsExpanded={(isExpanded) => {
								reportEvent({ event: 'legend-panel-toggled', metaData: { expanded: isExpanded } });
								setIsLegendPanelExpanded(isExpanded);
							}}
						/>
					)}
				</Flex>
				<InteractiveMetricChat isGenAIPopoverOpen={isGenAIPopoverOpen} setIsGenAIPopoverOpen={setIsGenAIPopoverOpen} />
			</Flex>
			<FiltersAndBreakdownsModal
				type={modalState.type}
				isOpen={modalState.isOpen}
				onClose={modalActions.onClose}
				onAddItems={modalActions.onAddItems}
				nodeScheme={isSightfull2 ? coreNodeScheme : nodeScheme}
				initialFilter={modalState.initialFilter}
			/>
		</>
	);
}
