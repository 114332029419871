import Box from '@components/Box';
import { NodeProps, Handle, Position, Edge } from '@xyflow/react';
import Dagre from '@dagrejs/dagre';
import { SemanticObjectNodeBaseType, SemanticObjectNodeType, SemanticNodeType } from '../types';
import { SemanticCard } from './SemanticCard';
import { SemanticPopup } from './SemanticPopup';
import { FlowPopover } from '@components/Flow';
import colors from '../../../../style/colors';

export function performNodesLayout(
	nodes: SemanticObjectNodeBaseType<SemanticNodeType>[],
	edges: Edge[]
): SemanticObjectNodeBaseType<SemanticNodeType>[] {
	const g = new Dagre.graphlib.Graph({ directed: true, compound: true, multigraph: true }).setDefaultEdgeLabel(
		() => ({})
	);
	g.setGraph({ rankdir: 'LR', ranksep: 0 });

	// this determines the width of the edge based on the width of the source node
	// if the node is wide, then we set a wider edge distance as well
	const nodesSepratorWidthMap = new Map<string, number>();
	nodes.forEach((node) => {
		const width = node.measured?.width ?? 0;
		nodesSepratorWidthMap.set(node.id, width > 200 ? 140 : 60);
	});

	edges.forEach((edge) => g.setEdge(edge.source, edge.target, { width: nodesSepratorWidthMap.get(edge.source)! }));
	nodes.forEach((node) => {
		g.setNode(node.id, {
			...node,
			width: node.measured?.width ?? 0,
			height: node.measured?.height ?? 0,
		});
	});

	Dagre.layout(g);

	return nodes.flatMap((node) => {
		const { x, y } = g.node(node.id);
		return { ...node, position: { x, y }, draggable: true, selectable: true };
	});
}

export function SemanticNode({
	children,
	data,
	selected,
	popupContent,
	withHandles = true,
}: NodeProps<SemanticObjectNodeType> & {
	children: React.ReactNode;
	withHandles?: boolean;
	popupContent?: React.ReactNode;
}) {
	const { highlighted } = data;
	return (
		<Box>
			<FlowPopover popoverContent={popupContent ? <SemanticPopup>{popupContent}</SemanticPopup> : null}>
				<SemanticCard
					highlighted={highlighted}
					hoverBorderColor={!selected ? colors.gray[500] : undefined}
					borderColor={selected ? colors.gray[800] : colors.gray[300]}
				>
					{children}
				</SemanticCard>
			</FlowPopover>
			{withHandles ? (
				<>
					<Handle
						type="target"
						position={Position.Top}
						id={'top-center'}
						isConnectable={false}
						style={{ visibility: 'hidden' }}
					/>
					<Handle
						type="target"
						position={Position.Left}
						id={'left-top'}
						isConnectable={false}
						style={{ visibility: 'hidden', top: 15 }}
					/>
					<Handle
						type="target"
						position={Position.Left}
						id={'left-center'}
						isConnectable={false}
						style={{ visibility: 'hidden' }}
					/>
					<Handle
						type="source"
						position={Position.Right}
						id={'right-center'}
						isConnectable={false}
						style={{ visibility: 'hidden' }}
					/>
					<Handle
						type="source"
						position={Position.Bottom}
						id={'bottom-center'}
						isConnectable={false}
						style={{ visibility: 'hidden' }}
					/>
				</>
			) : undefined}
		</Box>
	);
}
