import { IMarkdownString } from 'monaco-types';
import { buildColumnLayout, buildDiv, buildDivChildren, buildLink } from './baseBuilders';

export type CompletionTooltipProps = {
	title: string;
	subtitle: string;
	typeBadgeIconName?: string;
	typeBadgeText?: string;
	image?: { name: string; color: string };
	description?: string;
	openMetricLink?: string;
	additionalInformationRows?: string[];
	path?: string;
	sourceFooter?: string;
};

export function getCssCompletionTooltip(
	{
		title,
		image,
		subtitle,
		path,
		typeBadgeIconName,
		typeBadgeText,
		openMetricLink,
		description,
		additionalInformationRows = [],
		sourceFooter = '',
	}: CompletionTooltipProps,
	insertText: string
): IMarkdownString | undefined {
	const tooltipLines = [];

	const img = image ? buildDiv(`img-${image.name} ${image.color}`) : '';
	const titleDiv = buildDiv('title', title);
	const subtitleDiv = buildDiv('subtitle', subtitle);

	const titleColumn = buildColumnLayout('', titleDiv, subtitleDiv);

	const insertToken = buildDiv('insert-token', insertText.endsWith('.') ? insertText.slice(0, -1) : insertText);
	const typeBadgeTextDiv = typeBadgeText && buildDiv('type-badge-text', typeBadgeText);
	const typeBadgeImgDiv = typeBadgeIconName && buildDiv(typeBadgeIconName);

	tooltipLines.push(buildDiv('no-flow-layout', `${img}${titleColumn}`));
	if (path && path?.length > 0) tooltipLines.push(path);
	if (typeBadgeTextDiv) tooltipLines.push(buildDiv('type-badge-layout', `${typeBadgeImgDiv}${typeBadgeTextDiv}`));
	tooltipLines.push(insertToken);

	const descriptionDiv = description ? buildDiv('description', `${description}`) : '';

	const openMetricLinkDiv = openMetricLink ? buildLink(openMetricLink, 'Explore Metric') : '';
	const openMetricDiv = openMetricLink ? buildDiv('open-metric-link', `${openMetricLinkDiv}`) : '';

	if (openMetricLink && description) {
		tooltipLines.push(buildColumnLayout('', descriptionDiv, openMetricDiv));
	} else if (description) {
		tooltipLines.push(descriptionDiv);
	} else if (openMetricLink) {
		tooltipLines.push(openMetricDiv);
	}

	if (additionalInformationRows.length > 0) tooltipLines.push(buildDiv('divider'));
	tooltipLines.push(additionalInformationRows.join(buildDiv('divider')));

	return {
		value: [buildDivChildren('content', ...tooltipLines), buildDiv('spacer'), sourceFooter].join(''),
		isTrusted: true,
		supportHtml: true,
	};
}
