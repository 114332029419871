import { Box, ModalHeader } from '@chakra-ui/react';
import Typography from '@components/Typography';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { CloseLarge16 } from '@components/Icons';
import { FolderSelect } from '@components/FolderSelect';
import Modal from '@components/Modal';
import colors from 'src/style/colors';
import { useEffect, useState } from 'react';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { ListAllFoldersSubscription } from 'src/generated/graphql';
import { DEFAULT_EMOJIPICKER_VALUE } from '../../EmojiPicker/EmojiPicker';

type ModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
	folder?: FolderData;
};

export type FolderData = {
	emoji: string;
	folderName: string;
	id?: string;
	dashboards?: ListAllFoldersSubscription['folders'][number]['dashboards'];
};

export const initialFolderData = {
	emoji: DEFAULT_EMOJIPICKER_VALUE,
	folderName: '',
};

export const UpsertFolderModal = ({ isOpen, onClose, folder }: ModalProps) => {
	const closeModal = () => {
		setFolderData(initialFolderData);
		onClose();
	};

	const [folderData, setFolderData] = useState<FolderData>(initialFolderData);

	const { createDashboardFolder, isCreateFolderLoading, editDashboardFolder, isEditFodlerLoading } =
		useDashboardFolders();

	useEffect(() => {
		if (folder) setFolderData(folder);
	}, [folder]);

	const isLoading = isCreateFolderLoading || isEditFodlerLoading;
	const isSubmitDisabled = !folderData.emoji || !folderData.folderName;
	const isEditFlow = !!folderData.id;

	const onSubmit = async () => {
		if (isEditFlow) {
			await editDashboardFolder(folderData);
		} else {
			await createDashboardFolder(folderData.folderName, folderData.emoji, true);
		}
		closeModal();
	};

	return (
		<Modal maxWidth="460px" isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false} isCentered>
			<ModalHeader boxShadow={'borderBottom'} p="16px">
				<Flex textAlign={'center'} justifyContent="space-between" alignItems="center">
					<Button isIconOnly variant="outline" onClick={closeModal} size="inline" colorScheme="black">
						<CloseLarge16 color={colors.gray[900]} />
					</Button>
					<Box margin={'0 auto'}>
						<Typography color={'gray.1000'} marginLeft={'-32px'} variant="DesktopH7Medium">
							{isEditFlow ? 'Edit' : 'Create a'} folder
						</Typography>
					</Box>
				</Flex>
			</ModalHeader>
			<Flex width={'100%'} gap={'24px'} flexDirection={'column'} boxShadow={'borderBottom'} padding={'24px'}>
				<FolderSelect
					folderId={folderData.id}
					itemType={folderData.id ? 'folder' : 'dashboard'}
					setFolderData={setFolderData}
				/>
			</Flex>
			<Flex py="16px" px="24px" justifyContent={'space-between'}>
				<Button color={'gray.1000'} size={'medium'} colorScheme="gray" variant="outline" onClick={closeModal}>
					Cancel
				</Button>
				<Button
					isLoading={isLoading}
					isDisabled={isSubmitDisabled}
					size={'medium'}
					variant="solid"
					colorScheme={isSubmitDisabled ? 'gray' : 'blue'}
					onClick={onSubmit}
				>
					{isEditFlow ? 'Edit' : 'Create'}
				</Button>
			</Flex>
		</Modal>
	);
};
