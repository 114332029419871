import Flex from '@components/Flex';

export type SemanticFooterProps = {
	children: React.ReactNode;
};

export function SemanticFooter({ children }: SemanticFooterProps) {
	if (!children) return;

	return (
		<Flex direction={'row'} alignItems={'center'} justifyContent={'right'} flex={1}>
			{children}
		</Flex>
	);
}
