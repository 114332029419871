import { Flex } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import { Mention } from 'src/types/mention';
import Avatar from '../../Avatar/Avatar';
import Typography from '../../Typography';
import { ResourceRolesUser } from '../useResourceRoles';

export function AutoCompleteList({
	mentions,
	usersWithRoles,
	onItemSelected,
}: {
	mentions: Mention[];
	usersWithRoles: ResourceRolesUser[];
	onItemSelected: (mention: Mention) => void;
}) {
	const [selectedItemIndex, setSelectedItemIndex] = useState(0);
	const scrollableDivRef = useRef<HTMLDivElement>(null);

	const isUserAlreadyAdded = useCallback(
		(mention: Mention) => {
			return usersWithRoles.some((user) => user.userId === mention.id);
		},
		[usersWithRoles]
	);

	const isElementFullyVisible = (elem: HTMLElement) => {
		if (!scrollableDivRef.current) return false;

		const { top, bottom } = elem.getBoundingClientRect();
		const { top: containerTop, bottom: containerBottom } = scrollableDivRef.current.getBoundingClientRect();

		return top >= containerTop && bottom <= containerBottom;
	};
	const scrollToElement = useCallback((index: number, isScrollingUp: boolean) => {
		const activeElement = document.getElementById(`option-${index}`);
		if (activeElement && !isElementFullyVisible(activeElement)) {
			activeElement.scrollIntoView({ behavior: 'smooth', block: isScrollingUp ? 'start' : 'end' });
		}
	}, []);
	const handleKeyDown = useCallback(
		(eventName: string) => {
			if (eventName === 'ArrowUp') {
				const newHoverIndex = Math.max(selectedItemIndex - 1, 0);
				setSelectedItemIndex(newHoverIndex);
				scrollToElement(newHoverIndex, true);
			} else if (eventName === 'ArrowDown') {
				const newHoverIndex = Math.min(selectedItemIndex + 1, mentions.length - 1);
				setSelectedItemIndex(newHoverIndex);
				scrollToElement(newHoverIndex, false);
			} else if (eventName === 'Enter') {
				const doesMentionExistAndNotAdded =
					mentions[selectedItemIndex] && !isUserAlreadyAdded(mentions[selectedItemIndex]);
				if (doesMentionExistAndNotAdded) onItemSelected(mentions[selectedItemIndex]);
			}
		},
		[isUserAlreadyAdded, mentions, onItemSelected, scrollToElement, selectedItemIndex]
	);

	useKeyPress(['ArrowUp'], () => handleKeyDown('ArrowUp'));
	useKeyPress(['ArrowDown'], () => handleKeyDown('ArrowDown'));
	useKeyPress(['Enter'], () => handleKeyDown('Enter'));

	if (mentions.length === 0) return null;

	return (
		<Flex
			direction={'column'}
			borderRadius={'8px'}
			border={'1px solid'}
			borderColor={'gray.300'}
			overflow={'clip'}
			paddingY={'4px'}
			ref={scrollableDivRef}
			maxHeight={'230px'}
			overflowY={'auto'}
			width={'340px'}
		>
			{mentions.map((mention, index) => {
				return (
					<div id={`option-${index}`} key={mention.id}>
						<AutoCompleteListItem
							mention={mention}
							isSelected={selectedItemIndex == index}
							isAlreadyAdded={isUserAlreadyAdded(mention)}
							onClick={() => {
								if (!isUserAlreadyAdded(mention)) onItemSelected(mention);
							}}
							onHover={() => setSelectedItemIndex(index)}
						/>
					</div>
				);
			})}
		</Flex>
	);
}

function AutoCompleteListItem({
	mention,
	isSelected,
	isAlreadyAdded,
	onClick,
	onHover,
}: {
	mention: Mention;
	isSelected: boolean;
	isAlreadyAdded: boolean;
	onClick: () => void;
	onHover?: () => void;
}) {
	return (
		<Flex
			direction={'row'}
			padding={'8px 12px'}
			background={isSelected ? 'blue.100' : 'transparent'}
			justifyContent={'space-between'}
			alignItems={'center'}
			onClick={onClick}
			onMouseEnter={onHover}
			onMouseDown={(e) => e.preventDefault()}
		>
			<Flex gap={'12px'}>
				<Avatar imageUrl={mention.avatarURL || ''} shape="round" width={'32px'} height={'32px'} />

				<Flex direction={'column'} gap={'4px'} maxWidth={isAlreadyAdded ? '170px' : undefined}>
					<Typography
						variant="DesktopH8Regular"
						color={isAlreadyAdded ? 'gray.700' : 'gray.1000'}
						textOverflow={'ellipsis'}
						noOfLines={1}
					>
						{mention.name}
					</Typography>
					<Typography variant="Paragraph12R" color={'gray.700'} textOverflow={'ellipsis'} noOfLines={1}>
						{mention.email}
					</Typography>
				</Flex>
			</Flex>
			{isAlreadyAdded && (
				<Typography variant="DesktopH10Regular" color={'gray.700'}>
					Already added
				</Typography>
			)}
		</Flex>
	);
}
