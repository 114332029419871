import Box from '@components/Box';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import Input from '../../../Input';
import { InputProps } from '../../../Input/types';
import { EditableValueContainer } from './EditableValueContainer';
import { StringValueDisplay } from './StringValueDisplay';

export type InputStringEditorProps = {
	value: string;
	onApply: (value: string) => void;
	onEdit?: VoidFunction;
	validateChange?: (newValue: string) => boolean;
	onClose?: VoidFunction;
	tooltip?: string;
	prefixComponent?: ReactNode;
	defaultIsEditing?: boolean;
	hasLeftPadding?: boolean;
	isIntercomDetectable?: boolean;
} & InputProps;

export function InputStringEditor({
	value,
	onEdit,
	validateChange,
	onClose,
	onApply,
	tooltip,
	prefixComponent,
	defaultIsEditing = false,
	hasLeftPadding = true,
	isIntercomDetectable = true,
	...inputProps
}: InputStringEditorProps) {
	const [activeValue, setActiveValue] = useState<string | null>(defaultIsEditing ? value : null);
	const isEditing = useMemo(() => activeValue !== null, [activeValue]);

	const onStartEditing = useCallback(() => {
		setActiveValue(value);
		onEdit?.();
	}, [onEdit, setActiveValue, value]);

	const onInputChange = useCallback(
		(newValue: string) => {
			if (activeValue === null) return;
			if (validateChange && !validateChange?.(newValue)) return;
			setActiveValue(newValue);
		},
		[activeValue, validateChange]
	);

	const onDoneEditing = useCallback(() => {
		setActiveValue(null);
		onClose?.();
	}, [onClose]);

	const onApplyValue = useCallback(() => {
		if (activeValue && activeValue !== value) {
			onApply(activeValue);
		}
		onDoneEditing();
	}, [activeValue, value, onApply, onDoneEditing]);

	return (
		<EditableValueContainer prefixComponent={prefixComponent} hasLeftPadding={hasLeftPadding}>
			{!isEditing && (
				<StringValueDisplay
					tooltip={tooltip}
					value={value}
					hint={'Add value'}
					onStartEditing={onStartEditing}
					isEditing={!value}
					isEnabled={true}
					isIntercomDetectable={isIntercomDetectable}
				/>
			)}
			{isEditing && (
				<Box height={'32px'}>
					<Input
						autoFocus={true}
						size={'xxs'}
						width={'100%'}
						value={activeValue ?? value}
						{...inputProps}
						variant={'tight'}
						onChange={onInputChange}
						onEnter={onApplyValue}
						onBlur={onApplyValue}
					/>
				</Box>
			)}
		</EditableValueContainer>
	);
}
