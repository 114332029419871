import { Modal as ChakraModal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useIsRBACPlanEnabled } from 'src/services/entitlements';
import shadows from 'src/style/shadows';
import { SpecificResource } from '../Can/types';
import Flex from '../Flex';
import Spinner from '../Spinner';
import { RBACModal } from './RBACModal/RBACModal';
import { ResourceRoles, useResourceRoles } from './useResourceRoles';

function RBACModalWithLoader({
	onClose,
	resource,
	roles,
	isLoading,
}: {
	onClose: () => void;
	resource: SpecificResource;
	roles?: ResourceRoles;
	isLoading: boolean;
}) {
	const isRBACPlanEnabled = useIsRBACPlanEnabled();

	if (isLoading) {
		return (
			<Flex
				width={'450px'}
				padding={'64px'}
				justifyContent={'center'}
				alignItems={'center'}
				backgroundColor={'white'}
				borderRadius={'8px'}
				boxShadow={shadows['a-22']}
			>
				<Spinner />
			</Flex>
		);
	}

	return (
		<Flex direction={'column'} gap={'8px'}>
			<RBACModal onClose={onClose} resource={resource} roles={roles} isEditable={isRBACPlanEnabled} />
		</Flex>
	);
}

export function ResourceAccessModal({
	isOpen,
	onClose,
	resource,
}: {
	isOpen: boolean;
	onClose: () => void;
	resource: SpecificResource;
}) {
	const { roles, isLoading } = useResourceRoles({ resource });
	return (
		<ChakraModal isOpen={isOpen} onClose={onClose} motionPreset="none">
			<ModalOverlay />
			<ModalContent overflow="hidden" top={'0'} background={'transparent'} marginTop={'8%'} minWidth={'450px'}>
				<RBACModalWithLoader onClose={onClose} resource={resource} isLoading={isLoading} roles={roles} />
			</ModalContent>
		</ChakraModal>
	);
}
