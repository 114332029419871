import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import ListItem from '@components/ListItem';
import Popover from '@components/Popover';

import { AddLarge, DashboardNew18, FolderNew18 } from '@icons/index';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { TestIDs } from 'src/common/types/test-ids';
import { UpsertFolderModal } from '../LeftSidePanelV2/DashboardFolder';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import { isSpacesPage, isWorkspacePage } from 'src/pages/WorkspacePage/utils';

export const CreateNewButton = ({
	onCreateNewDashboard,
	isWithDropdown = false,
}: {
	onCreateNewDashboard: VoidFunction;
	isWithDropdown?: boolean;
}) => {
	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });

	const {
		isOpen: isCreateFolderModalOpen,
		onOpen: onCreateFolderModalOpen,
		onClose: onCreateFolderModalClose,
	} = useModal();

	const { isOpen, onClose, onOpen } = useDisclosure();

	const onSelectorOpen = () => {
		reportEvent({ event: 'sidebar-create-new-item-clicked' });
		onOpen();
	};

	const onSelectorClose = () => {
		onClose();
	};

	const getAddButtonTestId = () => {
		if (isSpacesPage()) {
			const collectionType = isWorkspacePage() ? 'workspace' : 'dashboard';
			return TestIDs.SIDEBAR_FOOTER_ADD_NEW_BUTTON(collectionType);
		}
	};

	const PlusButton = (
		<Tooltip size="md" variant="fluid" label={'Create new'} placement={'right'} marginLeft={'6px'}>
			<Button
				size={'small'}
				variant={'outline'}
				colorScheme={'gray'}
				onClick={isWithDropdown ? onSelectorOpen : onCreateNewDashboard}
				isIconOnly
				_hover={{ bgColor: colors.buttonPanelItemColor }}
				mixBlendMode={'multiply'}
				data-testid={getAddButtonTestId()}
			>
				<AddLarge color={colors.gray['900']} />
			</Button>
		</Tooltip>
	);

	return (
		<>
			{isWithDropdown ? (
				<Popover
					triggerElement={PlusButton}
					isOpen={isOpen}
					placement={'bottom-start'}
					onClose={onSelectorClose}
					popoverContentProps={{
						boxShadow: shadows['panelShadow'],
						borderRadius: '8px',
						border: `1px solid ${colors.gray[300]}`,
					}}
				>
					<PopoverContent>
						<Flex alignSelf="center" direction={'column'} py="8px" width="224px">
							<ListItem
								color="gray.1000"
								hoverColor={'gray.200'}
								label="Dashboard"
								size="sm"
								onClick={() => {
									reportEvent({ event: 'sidebar-create-new-item-select', metaData: { itemType: 'dashboard' } });
									onCreateNewDashboard();
								}}
								prefixIcon={<DashboardNew18 />}
							/>
							<ListItem
								color="gray.1000"
								hoverColor={'gray.200'}
								label="Folder"
								size="sm"
								onClick={() => {
									reportEvent({ event: 'sidebar-create-new-item-select', metaData: { itemType: 'folder' } });
									onCreateFolderModalOpen();
								}}
								prefixIcon={<FolderNew18 />}
							/>
						</Flex>
					</PopoverContent>
				</Popover>
			) : (
				PlusButton
			)}
			<UpsertFolderModal isOpen={isCreateFolderModalOpen} onClose={onCreateFolderModalClose} />
		</>
	);
};
