import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import { isMacOperatingSystem } from 'src/common/utils/utils';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import Flex from '../Flex';
import { Link16 } from '../Icons';
import ListItem from '../ListItem';
import Typography from '../Typography';

export function CopyLinkFooterModal({ onCopyLink }: { onCopyLink: VoidFunction }) {
	useKeyPress(['l'], onCopyLink, { isWithMetaKey: true });
	useKeyPress(['l'], onCopyLink, { isWithCtrlKey: true });

	return (
		<Flex
			width={'450px'}
			direction={'row'}
			padding="16px"
			boxShadow={shadows['a-22']}
			borderRadius={'8px'}
			background={'white'}
		>
			<ListItem
				onClick={onCopyLink}
				color="gray.1000"
				hoverColor="blue.100"
				prefixIcon={<Link16 />}
				label="Copy link"
				borderRadius="8px"
				size={'md'}
				hasRoundedCorners
				suffixComponent={
					<Typography variant="DesktopH8Regular" color={colors.gray[600]} paddingRight={'7px'}>
						{isMacOperatingSystem ? '⌘' : 'Ctrl'}+L
					</Typography>
				}
			/>
		</Flex>
	);
}
