import Typography from '@components/Typography';

export const SuccessToast = ({ categoryName, message }: { categoryName: string; message: string }) => {
	return (
		<Typography variant="Paragraph14SB" color="gray.1000">
			{categoryName}&nbsp;
			<Typography variant="Paragraph14R" color="gray.1000">
				{message}
			</Typography>
		</Typography>
	);
};
