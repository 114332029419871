import Box from '@components/Box';
import { ReactNode, useEffect, useRef } from 'react';
import useEmbedMode from 'src/common/hooks/navigation/useEmbedSetting';
import { AnalyticsPageContext, PageType } from 'src/services/analytics';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import shadows from 'src/style/shadows';

const MainPageRefAtom = atom<React.RefObject<HTMLDivElement> | undefined>(undefined);

export function useMainPageContainerRef() {
	return useAtomValue(MainPageRefAtom);
}

export function PageV2({ children, page }: { children: ReactNode; page: PageType }) {
	const [embed] = useEmbedMode();
	const isWidget = embed === 'widget';
	const pageRef = useRef<HTMLDivElement>(null);
	const setMainPageRef = useSetAtom(MainPageRefAtom);

	useEffect(() => {
		setMainPageRef(pageRef);
	}, [setMainPageRef]);

	return (
		<AnalyticsPageContext page={page}>
			<Box
				position={'relative'}
				ref={pageRef}
				id={'pageContainer'}
				backgroundColor={'white'}
				width={isWidget ? '100%' : 'auto'}
				flex={1}
				boxShadow={shadows['mainCanvas']}
				margin={'0 8px 8px 8px'}
				overflowY={isWidget ? 'auto' : 'clip'}
				zIndex={isWidget ? 2 : 3}
				borderRadius={isWidget ? '0' : '8px'}
			>
				{children}
			</Box>
		</AnalyticsPageContext>
	);
}
