import { useCallback } from 'react';
import { useGetSemanticsLazyQuery } from 'src/generated/graphql';
import { EntityWithMetrics, LoadedDimension } from 'src/lib/completions/semanticTypes';
import { useSemanticsStore } from 'src/stores/semantics';

export function useSemanticDefinitions() {
	const { setSemantics, semantics } = useSemanticsStore();
	const [fetchSemantics] = useGetSemanticsLazyQuery();

	const reloadSemantics = useCallback(
		() => fetchSemantics({ fetchPolicy: 'no-cache' }).then((result) => setSemantics(result.data?.getSemantics || [])),
		[fetchSemantics, setSemantics]
	);

	return { semanticDefinitions: semantics, reloadSemantics };
}

export function useSemanticDefinitionsForEntity(entity?: string): EntityWithMetrics | undefined {
	const { semanticDefinitions } = useSemanticDefinitions();

	return semanticDefinitions?.entities.find((e) => e.name === entity);
}

export function useSemanticDefinitionsForEntityDimension(
	entity?: string,
	dimension?: string
): LoadedDimension | undefined {
	const loadedEntity = useSemanticDefinitionsForEntity(entity);

	return loadedEntity?.dimensions.find((e) => e.name === dimension);
}

export function useSemanticsGetMetricDisplayName(): (metricName: string) => string {
	const { semanticDefinitions } = useSemanticDefinitions();

	const getMetricDisplayName = useCallback(
		(metricName: string) => {
			const metrics = semanticDefinitions?.metrics || [];
			const formulaMetrics = semanticDefinitions?.formula_metrics || [];
			const metric = [...metrics, ...formulaMetrics].find((m) => m.name === metricName);
			return metric?.meta?.display_name || metricName;
		},
		[semanticDefinitions]
	);

	return getMetricDisplayName;
}
