import { useCallback } from 'react';
import useNavigation from '../../../../services/useNavigation';
import Button from '../../Button';
import { ArrowUpRightMedium16 } from '../../Icons';
import { ButtonSizeType } from '../../Button/types';
import { useReportEvent } from '../../../../services/analytics';

export function ExploreSemanticObjectButton({
	path,
	text,
	size = 'xs',
	isNewTab = true,
	metadata,
}: {
	path: string;
	text?: string;
	size?: ButtonSizeType;
	isNewTab?: boolean;
	metadata: { objectType: string; objectName: string; direction?: string };
}) {
	const { reportEvent } = useReportEvent();

	const { navigate } = useNavigation();

	const onClick = useCallback(() => {
		reportEvent({ event: 'explore-object-clicked', metaData: { path, ...metadata } });
		navigate({
			path,
			isNewTab,
		});
	}, [reportEvent, path, metadata, navigate, isNewTab]);

	return (
		<Button
			size={size}
			variant={'ghost'}
			colorScheme={'gray'}
			onClick={onClick}
			padding={'0 12px'}
			leftIcon={<ArrowUpRightMedium16 />}
		>
			{text}
		</Button>
	);
}
