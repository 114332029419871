import { ValueFormatterParams } from 'ag-grid-community';
import { Abc16, Abc19, Calendar16, HashMark16, HashMark18, Switch16, Switch20 } from 'src/common/components/Icons';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { formatValue } from 'src/common/utils/valueFormatting';
import {
	ColDef,
	CreateEntityMutation,
	RelationshipsAndDimensionsQuery,
	UpdateEntityMutation,
} from 'src/generated/graphql';
import { RawSQLDataSource, TableDataSource } from 'src/lib/completions/semanticTypes/normalization.schema';
import { isRecord } from 'src/models/MetricDefinition/normalizeMetricState';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { removeDollarSigns } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import TenantConfigType from 'src/types/tenantConfig';
import { OntologyDimensionInfo, OntologyRelationshipInfo } from './normalizeYaml';
import { OntologyPropertyType } from './types';
import { FetchResult } from '@apollo/client';

export const serializeFilterKey = (key: string) => capitalizedFirstLetter(removeDollarSigns(key).replace('.', ' '));

export const serializeColumnDef = (
	columns: ColDef[],
	tenantConfig: TenantConfigType,
	additionalColumns?: string[],
	onRemoveColumn?: (colId: string) => void
) => {
	return columns.map((el, i) => {
		return {
			headerName: el?.colDisplayName,
			field: el?.colName,
			pinned: i === 0,
			valueFormatter: (params: ValueFormatterParams) => formatValue(el.colName, params.value, tenantConfig),
			filterKey: el?.colName,
			headerComponentParams: { removable: additionalColumns?.includes(el?.colName), onRemoveColumn },
		};
	});
};

export const serializeRowDef = (rows: Record<string, any>[]) => {
	return rows.map((el) => ({ _row_id: el.id, ...el?.cols }));
};

const iconMap = {
	boolean: { small: <Switch16 />, large: <Switch20 /> },
	string: { small: <Abc16 />, large: <Abc19 /> },
	number: { small: <HashMark16 />, large: <HashMark18 /> },
	timestamp: { small: <Calendar16 />, large: <Calendar16 /> },
	default: { small: <Abc16 />, large: <Abc19 /> },
} as const;

type IconSize = 'small' | 'large';
type IconTypes = keyof typeof iconMap;

export const getIconType = (type: string): IconTypes => {
	const normalizedType = type.toLowerCase();
	if (['bool', 'boolean'].includes(normalizedType)) return 'boolean';
	if (['text', 'string', 'character varying'].includes(normalizedType)) return 'string';
	if (['number', 'bigint', 'double precision', 'id'].includes(normalizedType)) return 'number';
	if (normalizedType.includes('timestamp')) return 'timestamp';
	return 'default';
};

export const getIconByType = (type: string, size: IconSize = 'large'): JSX.Element => {
	const iconType = getIconType(type);
	return iconMap[iconType][size];
};

export const parseValue = (input?: Record<string, string | { sql: string }[]>) =>
	input && Object.entries(input).map(([key, value]) => ({ key, value }));

export const isTableDataSource = (dataSource: TableDataSource | RawSQLDataSource): dataSource is TableDataSource =>
	'schema' in dataSource && dataSource.schema && 'name' in dataSource.schema && 'table' in dataSource;

export const isRawSQLDataSource = (dataSource: TableDataSource | RawSQLDataSource): dataSource is RawSQLDataSource =>
	'raw_sql' in dataSource;

export const isOntologyDimensionInfo = (value: unknown): value is OntologyDimensionInfo => {
	return (
		typeof value === 'object' && value !== null && 'type' in value && 'sources' in value && Array.isArray(value.sources)
	);
};

export const isOntologyRelationshipInfo = (value: unknown): value is OntologyRelationshipInfo => {
	return typeof value === 'object' && value !== null && 'type' in value && 'referenced_entity' in value;
};

export const splitJoinKeys = (value: string) => value.split(/\s*=\s*/);

export const splitJoinKeysByAnd = (value: string) => value.split(/\s+and\s+/);

const keyPattern = '\\$[a-zA-Z0-9_\\.]+';
const joinConditionPattern = `${keyPattern}\\s*=\\s*${keyPattern}`;
export const isJoinKeysFormat = (value?: string) =>
	value
		? value.split(/\s+and\s+/).every((cond) => cond.split(/\s*=\s*/).every((part) => part.startsWith('$'))) &&
		  new RegExp(`^\\s*(${joinConditionPattern})(\\s+and\\s+${joinConditionPattern})*\\s*$`).test(value)
		: true;

export const extractJoinConditions = (value?: string) => {
	const regex = new RegExp(`${joinConditionPattern}`, 'g');
	return value?.match(regex) ?? [];
};

export const hasEmptyDimensions = (propertyDefinitionValue: OntologyDimensionInfo | OntologyRelationshipInfo) => {
	if (isOntologyDimensionInfo(propertyDefinitionValue)) {
		return !!propertyDefinitionValue?.sources?.filter(
			(el) => el && isRecord(el) && (('sql' in el && !el.sql) || ('raw_sql' in el && !el.raw_sql))
		)?.length;
	}
};

export const hasEmptyRelationships = (propertyDefinitionValue: OntologyDimensionInfo | OntologyRelationshipInfo) => {
	if (!isOntologyRelationshipInfo(propertyDefinitionValue)) return false;
	return !(propertyDefinitionValue.on?.includes('=') && propertyDefinitionValue.on?.includes('.'));
};

export const entityHasEmptyValues = (
	propertyType?: OntologyPropertyType | null,
	propertyDefinitionValue?: OntologyDimensionInfo | OntologyRelationshipInfo | null
) => {
	if (!propertyDefinitionValue) return false;
	if (propertyType === 'dimensions' && !!propertyDefinitionValue) return hasEmptyDimensions(propertyDefinitionValue);
	if (propertyType === 'relationships' && !!propertyDefinitionValue)
		return hasEmptyRelationships(propertyDefinitionValue);
};

export const findKeyValue = (value: string, includesDot: boolean) =>
	splitJoinKeys(value)
		?.find((el) => (includesDot ? el.includes('.') : !el.includes('.')))
		?.trim() || '';

export const getAffectedMetricsList = (
	el: OntologyDimensionInfo | OntologyRelationshipInfo,
	entitiesFromQuery:
		| RelationshipsAndDimensionsQuery['relationshipsAndDimensions']['entities'][0]['dimensions']
		| RelationshipsAndDimensionsQuery['relationshipsAndDimensions']['entities'][0]['relationships']
) =>
	entitiesFromQuery
		?.find((d) => removeDollarSigns(d.identifier) === el.name)
		?.affectedMetricsList.map((el) => removeUnderscoresAndCapitalize(el)) || [];

export function getUpsertDefinitionHash(
	upsertResponse: FetchResult<UpdateEntityMutation> | FetchResult<CreateEntityMutation>
): string {
	if (
		upsertResponse.data &&
		'updateEntity' in upsertResponse.data &&
		upsertResponse.data.updateEntity != null &&
		typeof upsertResponse.data.updateEntity == 'object'
	) {
		return upsertResponse.data.updateEntity.semanticDefinitionVersion.definitionHash;
	}
	if (
		upsertResponse.data &&
		'createEntity' in upsertResponse.data &&
		upsertResponse.data.createEntity != null &&
		typeof upsertResponse.data.createEntity == 'object'
	) {
		return upsertResponse.data.createEntity.semanticDefinitionVersion.definitionHash;
	}
	throw new Error('Missing definition hash');
}
