import { Flex } from '@chakra-ui/react';
import Box from '@components/Box';
import { ConfirmationModal } from '@components/ConfirmationModal';
import { FolderData } from './UpsertFolderModal';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { Fragment, useMemo } from 'react';
import pluralize from 'pluralize';
import colors from 'src/style/colors';
import Typography from '../../Typography';

const MAX_DASHBOARDS_TO_SHOW = 7;

function DashboardsLineageInfoParagraph({ dashboardsToDelete }: { dashboardsToDelete: string[] }) {
	if (!dashboardsToDelete) {
		return <></>;
	}

	return (
		<Box borderRadius={'8px'} padding={'12px 16px'} backgroundColor={colors.gray[200]}>
			<Typography textAlign={'start'} variant={'Paragraph14R'} color={'gray.1000'} wordBreak={'break-word'}>
				This action will affect&nbsp;
				<Typography textAlign={'start'} variant={'Paragraph14SB'}>
					{`${dashboardsToDelete.length} ${pluralize('dashboard', dashboardsToDelete.length)} - `}
				</Typography>
				&nbsp;
				{dashboardsToDelete.slice(0, MAX_DASHBOARDS_TO_SHOW).map((dashboard, index) => (
					<Fragment key={dashboard + index}>
						{dashboard}
						{index < MAX_DASHBOARDS_TO_SHOW - 1 && index < dashboardsToDelete.length - 1
							? ', '
							: index === MAX_DASHBOARDS_TO_SHOW - 1 && dashboardsToDelete.length > MAX_DASHBOARDS_TO_SHOW
							? ` and +${dashboardsToDelete.length - MAX_DASHBOARDS_TO_SHOW} more`
							: '.'}
					</Fragment>
				))}
			</Typography>
		</Box>
	);
}

export function DeleteFolderModal({
	isOpen,
	onClose,
	folder,
}: {
	isOpen: boolean;
	onClose: () => void;
	folder: FolderData;
}) {
	const { deleteDashboardFolder, isDeleteFolderLoading } = useDashboardFolders();

	const onDeleteFolder = async () => {
		if (!folder.id) return;
		await deleteDashboardFolder(folder.id);
		onClose();
	};

	const onCancelDelete = () => {
		onClose();
	};

	const dashboardsToDelete = useMemo(() => folder.dashboards?.map((el) => el.name) || [], [folder.dashboards]);

	return (
		<ConfirmationModal
			primaryButtonLabel={'Delete'}
			isOpen={isOpen}
			modalText={
				<Flex direction={'column'} gap="10px">
					{dashboardsToDelete.length > 0 ? (
						<>
							<Typography variant={'DesktopH8Regular'} textAlign="center">
								<Typography variant={'DesktopH8Bold'}>{folder.folderName}</Typography>
								&nbsp;and all its dashboards will be permanently deleted.
							</Typography>
							<DashboardsLineageInfoParagraph dashboardsToDelete={dashboardsToDelete} />
						</>
					) : (
						<Typography variant={'DesktopH8Regular'} textAlign="center">
							<Typography variant={'DesktopH8Bold'}>{folder.folderName}</Typography>
							&nbsp;folder will be permanently deleted.
						</Typography>
					)}
				</Flex>
			}
			modalTitle={'Delete folder.'}
			onSubmit={onDeleteFolder}
			onClose={onCancelDelete}
			isLoading={isDeleteFolderLoading}
			submitColorScheme={'red'}
			minimumModalHeight="300px"
		/>
	);
}
