import { BaseEdge, EdgeProps } from '@xyflow/react';
import { EntityToMetricEdgeType } from '../types';
import colors from '../../../../style/colors';
import { getBezierPath } from './utils';

export function EntityToMetricEdge({
	sourceX,
	sourceY,
	targetX,
	targetY,
	...props
}: EdgeProps<EntityToMetricEdgeType>) {
	const [edgePath] = getBezierPath({
		sourceX,
		sourceY,
		targetX,
		targetY,
	});

	return (
		<>
			<BaseEdge
				{...props}
				path={edgePath}
				style={{
					stroke: colors.gray[300],
				}}
			/>
		</>
	);
}
