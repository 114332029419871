import { Box, Flex } from '@chakra-ui/react';
import { useRef } from 'react';
import { ErrorYellow14 } from 'src/common/components/Icons';
import { InitialsBadge } from 'src/common/components/InitialsBadge';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { NormalizedOntology } from '../../hooks/useOntologyPageState';
import classes from './OntologyEntityCards.module.scss';

type CardProps = {
	onClick: (entityName: string) => void;
	ontology: NormalizedOntology;
	isSelected: boolean;
	isFullyDefined: boolean;
};

export function OntologyEntityCard({ onClick, ontology, isSelected, isFullyDefined }: CardProps) {
	const { displayName, name: ontologyName } = ontology;
	const name = displayName || ontologyName;
	const subtitle = ontology.panelSubtitle || '';
	const nameRef = useRef(null);
	const subtitleRef = useRef(null);
	const isOverflowingName = useOnOverflow(nameRef, [name], undefined, true);
	const isOverflowingDescription = useOnOverflow(subtitleRef, [subtitle], undefined, true);

	return (
		<Flex
			cursor={'pointer'}
			alignItems={'center'}
			className={`${classes.card} ${isSelected ? classes.isSelected : ''}`}
			onClick={() => onClick(ontologyName)}
		>
			<Box className={classes.avatarWrapper}>
				<Box className={classes.avatar} color={ontology.iconColor}>
					<InitialsBadge border={ontology.iconBorder} size={'36px'} bgColor={ontology.iconBackgroundColor} />
					<ontology.icon />
				</Box>
				{!isFullyDefined && (
					<Box className={classes.badge} width={'16px'} height={'16px'}>
						<ErrorYellow14 />
					</Box>
				)}
			</Box>
			<Flex width="100%" direction={'row'} justifyContent={'space-between'}>
				<Flex flexDirection={'column'}>
					<Tooltip
						maxW={'225px'}
						wrapperStyle={{ width: 'fit-content', maxHeight: '26px' }}
						label={isOverflowingName && name}
						size="md"
						variant="primary"
						background="black"
						hasArrow
						placement="top"
					>
						<Typography
							whiteSpace={'nowrap'}
							marginBottom={'6px'}
							color={'gray.1000'}
							cursor={'pointer'}
							variant="DesktopH8Medium"
							textAlign="start"
						>
							<Box maxWidth={'155px'} overflow={'hidden'} textOverflow="ellipsis" ref={nameRef}>
								{name}
							</Box>
						</Typography>
					</Tooltip>

					<Tooltip
						maxW={'225px'}
						wrapperStyle={{ width: 'fit-content', maxHeight: '26px' }}
						label={isOverflowingDescription && subtitle}
						size="md"
						variant="primary"
						background="black"
						hasArrow
						placement="top"
					>
						<Typography
							color={'gray.900'}
							whiteSpace={'nowrap'}
							cursor={'pointer'}
							variant="DesktopH10Regular"
							textAlign="start"
						>
							<Box maxWidth={'155px'} overflow={'hidden'} textOverflow="ellipsis" ref={subtitleRef}>
								{subtitle}
							</Box>
						</Typography>
					</Tooltip>
				</Flex>
			</Flex>
		</Flex>
	);
}
