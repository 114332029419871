import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import Flex from 'src/common/components/Flex';
import { TagIcon24, Warning32 } from 'src/common/components/Icons';
import { StillInProgressModal } from 'src/common/components/StillInProgressModal';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import { useModal } from 'src/common/hooks/ui/useModal';
import { usePreventReload } from 'src/common/hooks/usePreventReload';
import { EntityCatalogQuery, useGetEntityRecordsLazyQuery } from 'src/generated/graphql';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from 'src/layout/EntityProfileDrawer/hooks';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { useReportEvent } from 'src/services/analytics';
import useNavigationBlock from 'src/services/useNavigationBlock';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import { writePartialOntologyState } from '../../atoms/OntologyState';
import { SkeletonEditorPanel } from '../../components/SkeletonComponents';
import { SkeletonHeader, SkeletonTable } from '../../components/SkeletonComponents/SkeletonTable/SkeletonTable';
import { useColumnsAndFilters } from '../../hooks/useColumnsAndFilters';
import { NormalizedOntology, useOntologyPageState } from '../../hooks/useOntologyPageState';
import { useIsFullAdvancedMode } from '../../hooks/useOntologySearchParams';
import useOntologyEditorState from '../../hooks/useOntologyState';
import { MAX_ITEMS_TO_FETCH, REQUEST_LOADER_SPEED } from '../../utils/consts';
import { TableData } from '../../utils/types';
import { serializeColumnDef, serializeRowDef } from '../../utils/utils';
import BuilderPanel from '../BuilderPanel/BuilderPanel';
import { EditorPanel } from '../EditorPanel/EditorPanel';
import { BuilderHeader } from '../Header';
import { SaveLoadingBar } from '../SaveLoadingBar';
import { StaleVersionModal } from '../StaleVersionModal/StaleVersionModal';
import { OntologyGridTable } from '../Table/OntologyTableV2';
import { UpsertEntityModal } from '../UpsertEntityModal';
import { CreateNewEntityContent } from './CreateNewEntutyContent';
import { PageContentStructure } from './PageContentStructure';

export function EntitiesOntologyPageContent({
	isLoading,
	entities,
	normalizedOntology,
	ontologyName,
	selectedOntology,
	isUpsertEntityModalOpened,
	onCloseUpsertEntityModal,
	onCreateNewOpen,
}: {
	isLoading: boolean;
	entities: EntityCatalogQuery['entityCatalog'];
	normalizedOntology: NormalizedOntology[];
	ontologyName: string;
	selectedOntology: NormalizedOntology | null;
	isUpsertEntityModalOpened: boolean;
	onCloseUpsertEntityModal: VoidFunction;
	onCreateNewOpen: VoidFunction;
}) {
	const hasWritePermission = usePermissionCheck().isHavingPermission(Permissions.writeEntities);
	const tenantConfig = useTenantConfig();

	const { reportEvent } = useReportEvent();

	const [progress, setProgress] = useState<number>(100);

	const [tableData, setTableData] = useState<TableData>({ rowData: [], columnDefs: [] });
	const [columnsAndFilters, setColumnsAndFilters] = useColumnsAndFilters(ontologyName);
	const { additionalColumns, filter } = columnsAndFilters;

	const [getTableDataCallApi, { loading: isLoadingTable }] = useGetEntityRecordsLazyQuery();

	const onRemoveColumn = useCallback(
		(colId: string) => {
			setColumnsAndFilters({ additionalColumns: additionalColumns.filter((col) => col !== colId), filter });
		},
		[additionalColumns, filter, setColumnsAndFilters]
	);

	const { isAdvancedMode } = useIsFullAdvancedMode();

	const { isOpen, onOpen: openSaveChangesModal, onClose } = useModal();

	const runEditorCalculations = useCallback(() => {
		onClose();
		setProgress(0);
	}, [onClose]);

	const getTableData = useCallback(() => {
		getTableDataCallApi({
			variables: {
				entity: ontologyName,
				filter,
				additionalColumns,
				itemsPerPage: MAX_ITEMS_TO_FETCH,
				pageOffset: 0,
			},
			fetchPolicy: 'no-cache',
		}).then((res) => {
			if (res.variables?.entity !== ontologyName) return;
			const { cols = [], rows = [] } = res?.data?.getEntityRecords || {};
			setTableData({
				columnDefs: serializeColumnDef(cols, tenantConfig, additionalColumns, onRemoveColumn),
				rowData: serializeRowDef(rows),
			});
		});
	}, [getTableDataCallApi, ontologyName, filter, additionalColumns, tenantConfig, onRemoveColumn]);

	const [
		{
			ontologyState,
			loadingFiles,
			normalizationInProgress,
			validationInProgress,
			updateEntityInProgress,
			deleteEntityInProgress,
		},
		{ onUpdateEntity, onDelete },
	] = useOntologyEditorState({
		entityName: selectedOntology?.name,
		onSaveAndRunNormalizationStart: runEditorCalculations,
		onNormalizationSuccess: getTableData,
		setColumns: (names: string[]) =>
			setColumnsAndFilters({
				...columnsAndFilters,
				additionalColumns: Array.from(new Set([...additionalColumns, ...names])),
			}),
	});

	const {
		ontologyPageState: { isOntologyPageEditable },
		setIsOntologyPageEditable,
	} = useOntologyPageState();
	const { isNavigationBlocked, setIsNavigationBlocked } = useNavigationBlock();
	const blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			isNavigationBlocked.isBlocked && currentLocation.pathname !== nextLocation.pathname
	);

	const [isStillInProgessModalVisible, setIsIsStillInProgessModalVisible] = useState<boolean>(false);

	const saveRunAndValidateInProgress = useMemo(
		() => normalizationInProgress || validationInProgress || updateEntityInProgress,
		[normalizationInProgress, updateEntityInProgress, validationInProgress]
	);

	const isEditable = useMemo(
		() => !saveRunAndValidateInProgress && hasWritePermission && !isLoadingTable && !deleteEntityInProgress,
		[deleteEntityInProgress, hasWritePermission, isLoadingTable, saveRunAndValidateInProgress]
	);

	useEffect(() => {
		setIsOntologyPageEditable(isEditable);
	}, [isEditable, setIsOntologyPageEditable]);

	const setPartialOntologyState = useSetAtom(writePartialOntologyState);

	usePreventReload({ dependency: (ontologyState?.hasUnsavedChanges ?? false) || saveRunAndValidateInProgress });

	useEffect(() => {
		const isEntityDefined = selectedOntology && (selectedOntology.isDefined || selectedOntology.isFullyDefined);

		if (!ontologyName || selectedOntology?.name !== ontologyName || !isEntityDefined) {
			return setTableData({
				columnDefs: serializeColumnDef([], tenantConfig),
				rowData: serializeRowDef([]),
			});
		}

		getTableData();
	}, [ontologyName, additionalColumns, filter, getTableData, selectedOntology, tenantConfig]);

	useEffect(() => {
		if (!saveRunAndValidateInProgress) return;

		const interval = setInterval(() => {
			setProgress(progress + 1);
		}, REQUEST_LOADER_SPEED);
		if (progress > 85) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [progress, saveRunAndValidateInProgress]);

	const { pushEntity } = useEntityProfileDrawer();
	const { reportOpenEntityDrawer } = useEntityProfileReportEvent();

	const onRowClicked = (recordId: string) => {
		reportOpenEntityDrawer({
			origin: 'ontology-table',
			id: recordId,
			entity: ontologyName,
		});

		pushEntity(recordId);
	};

	const isPageEmpty = !ontologyName && !entities.length;

	return (
		<>
			<PageContentStructure>
				{isLoading ? (
					<SkeletonHeader />
				) : (
					!isPageEmpty && (
						<BuilderHeader
							ontologyType={'entity'}
							onDelete={async (callback: VoidFunction) => {
								const firstEntityName = normalizedOntology?.[0]?.name || '';
								const isDeletingFirst = selectedOntology?.name === firstEntityName;
								const entityToNavigate = isDeletingFirst ? normalizedOntology?.[1]?.name : firstEntityName;
								await onDelete(callback, callback, entityToNavigate);
							}}
							deleteEntityInProgress={deleteEntityInProgress}
							ontology={selectedOntology}
							isEditable={isOntologyPageEditable}
							isSwitchingModeEnabled={!ontologyState?.hasYamlErrors}
						/>
					)
				)}
				{
					<Flex overflowY={'auto'} overflowX={'hidden'} flex={1} height={'100%'}>
						{updateEntityInProgress || normalizationInProgress || isLoadingTable || isLoading ? (
							<Flex width="100%" flex={1}>
								<SkeletonTable />
							</Flex>
						) : isPageEmpty ? (
							<CreateNewEntityContent onCreateNewOpen={onCreateNewOpen} />
						) : (
							<OntologyGridTable
								entityName={ontologyName}
								columnsAndFilters={columnsAndFilters}
								setColumnsAndFilters={setColumnsAndFilters}
								errorMessage={ontologyState?.errorMessage}
								isErrorActive={ontologyState?.editorRequestMessageState === 'ERROR'}
								data={tableData.rowData}
								columnDefs={tableData.columnDefs}
								onRowClicked={onRowClicked}
							/>
						)}
						{isLoading || loadingFiles || !ontologyState ? (
							<SkeletonEditorPanel />
						) : (
							!isPageEmpty &&
							(isAdvancedMode ? (
								<EditorPanel
									errorMessage={ontologyState.errorMessage}
									editorLoading={loadingFiles}
									entityName={ontologyName}
									requestMessage={ontologyState.editorRequestMessageState ?? 'NONE'}
									isEditable={isEditable}
									editorValue={ontologyState.editorYaml}
									hasUnsavedChanges={ontologyState.hasUnsavedChanges}
									isSaveAndRunButtonDisabled={
										!ontologyState.hasUnsavedChanges || ontologyState.hasYamlErrors || saveRunAndValidateInProgress
									}
									onButtonClick={() => {
										reportEvent({
											event: 'save-and-run-ontology-clicked',
											metaData: { feature: 'Ontology Execution', entity: ontologyName },
										});
										openSaveChangesModal();
									}}
								/>
							) : (
								<BuilderPanel
									isEditable={isEditable}
									entities={entities}
									entityDisplayName={selectedOntology?.displayName || removeUnderscoresAndCapitalize(ontologyName)}
									onSubmit={onUpdateEntity}
								/>
							))
						)}
					</Flex>
				}
				<SaveLoadingBar
					updateInProgress={updateEntityInProgress}
					normalizationInProgress={normalizationInProgress}
					validationInProgress={validationInProgress}
					progress={progress}
				/>
			</PageContentStructure>
			<ConfirmationModal
				modalIcon={<TagIcon24 color={colors.blue[600]} />}
				isOpen={isOpen}
				isLoading={saveRunAndValidateInProgress}
				// TODO: Can this onSubmit be async?
				onSubmit={async () => {
					reportEvent({
						event: 'save-and-run-ontology-confirmed',
						metaData: {
							objectType: 'entity',
							parentEntity: ontologyName,
							editMode: 'YAML',
						},
					});
					await onUpdateEntity();
				}}
				onClose={() => {
					reportEvent({
						event: 'save-and-run-ontology-canceled',
						metaData: {
							objectType: 'entity',
							parentEntity: ontologyName,
							editMode: 'YAML',
						},
					});
					onClose();
				}}
				modalTitle="Save changes."
				modalText="Making changes to this entity will affect other entities, metrics, and dashboards within the platform."
			/>
			<ConfirmationModal
				modalIcon={<Warning32 color={colors.gray[600]} />}
				isOpen={blocker.state === 'blocked'}
				onSubmit={() => {
					if (saveRunAndValidateInProgress) {
						blocker.reset?.();
						return;
					}
					reportEvent({
						event: 'ontology-edit-unsaved-leave-clicked',
						metaData: { feature: 'YAML Editor', entity: ontologyName, objectType: 'entity' },
					});
					if (isAdvancedMode) {
						setPartialOntologyState({ editorRequestMessageState: 'NONE', editorYaml: ontologyState?.savedYaml });
					} else {
						setPartialOntologyState({ editorRequestMessageState: 'NONE' });
						setIsNavigationBlocked({ isBlocked: false });
					}

					blocker.proceed?.();
				}}
				primaryButtonLabel={saveRunAndValidateInProgress ? 'Close' : 'Leave page'}
				isWithoutCancel={saveRunAndValidateInProgress}
				modalTitle={saveRunAndValidateInProgress ? 'Saving in progress' : 'Leave without saving.'}
				modalText={
					saveRunAndValidateInProgress
						? 'Your changes are still being applied, please wait until they are finished.'
						: 'You are about to leave without saving. Any unsaved changes will be deleted.'
				}
				onClose={() => {
					reportEvent({
						event: 'ontology-object-edit-cancel-clicked',
						metaData: { feature: 'YAML Editor', entity: ontologyName, objectType: 'entity' },
					});
					blocker.reset?.();
				}}
			/>
			<StillInProgressModal
				isOpen={isStillInProgessModalVisible}
				onClose={() => {
					reportEvent({
						event: 'ontology-edit-close-still-in-progress-modal-clicked',
						metaData: { feature: 'YAML Editor', entity: ontologyName },
					});
					setIsIsStillInProgessModalVisible(false);
				}}
			/>
			<UpsertEntityModal
				onModalClose={getTableData}
				ontologyEditorValue={ontologyState?.editorYaml}
				selectedOntology={selectedOntology}
				isOpen={isUpsertEntityModalOpened}
				onClose={onCloseUpsertEntityModal}
				isEditEntity={false}
			/>
			<StaleVersionModal isOpen={ontologyState?.hasStaleVersion ?? false} entityName={ontologyName} />
		</>
	);
}
