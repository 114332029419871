export const isRole = (role: string): role is RoleType => Roles.some((r) => r == role);
export const canEditRole = (role: RoleType, myRole: RoleType) => {
	if (myRole === 'owner') {
		return true;
	}
	if (myRole === 'editor') {
		return role !== 'owner';
	}
	return false;
};

export const Roles = ['viewer', 'editor', 'owner'] as const;
export type RoleType = typeof Roles[number];
export const rolesToString: { [role in RoleType]: string } = {
	viewer: 'can view',
	editor: 'can edit',
	owner: 'owner',
};
