import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { Plus16 } from '@components/Icons';
import { useModal } from '@hooks/ui/useModal';

import { useCallback, useEffect, useState } from 'react';
import { AskAIInput } from '../AskAIInput';
import { AskAIModalItem } from '../AskAIModalItem';
import { AskAIFooter } from './AskAIFooter';
import { AskAIInputEcho } from './AskAIInputEcho';
import { AskAISearchData } from './AskAISearchData';

import { useKeyPress } from '@hooks/interaction/useKeyPress';
import useEntitySearch from '@hooks/stores/useEntityState';
import { useSearchOptions } from '@layout/Menu/SearchBox/useSearchOptions';
import { SearchResultsPath } from '@pages/SearchResultsPage';
import useNavigation from '@services/useNavigation';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import { useReportAIEvent } from '../hooks/useReportAIEvent';
import { AskAICreateNewMetricPopover } from '../AskAICreateNewMetricPopover';
import { SearchMetricResultItem } from '@components/AskAI/SearchMetricResultItem';
import { CategoryV2, MetricMetadataV2 } from 'src/types/metric';
import { filterStringByTerm } from 'src/common/utils/utils';
import { useMetricCategoriesV2 } from '@pages/MetricCatalog/hooks/useMetricCategoriesV2';
import { MetricPagePath } from '@pages/MetricPage/pageRoutesPaths';

type AskAIMainPageProps = {
	onItemClicked: (askingAI: boolean) => void;
	onInputChange: (value: string) => void;
	inputValue: string;
};

type SelectableItem = {
	component?: JSX.Element;
	action?: (userPrompt: string) => void;
	header?: string;
	borderTop?: boolean;
	padding?: string;
	hasMinHeight?: boolean;
	isHeaderOnly?: boolean;
	isSubHeader?: boolean;
};

type SelectableItemProps = {
	inputValue: string;
	onItemClicked: (askingAI: boolean) => void;
};

const MAX_METRICS_RESULTS_NUMBER = 5;

function isItemSelectable(item: SelectableItem) {
	return item.action != undefined;
}

function useInputEchoItem({ inputValue, onItemClicked }: SelectableItemProps): SelectableItem {
	const { reportAskAIEvent } = useReportAIEvent();
	const { addChatMessage } = useAskAIChatMessages();

	const onPerformAskAI = useCallback(
		(userPrompt: string) => {
			reportAskAIEvent({
				event: 'ask-ai-query',
				metaData: { queryText: userPrompt },
			});
			addChatMessage({ userPrompt, type: 'metric-discovery' });
			onItemClicked(true);
		},
		[addChatMessage, onItemClicked, reportAskAIEvent]
	);

	return {
		action: onPerformAskAI,
		component: <AskAIInputEcho value={inputValue} key={'echo'} />,
	};
}

function useSearchDataItem({ inputValue, onItemClicked }: SelectableItemProps): SelectableItem {
	const { reportAskAIEvent } = useReportAIEvent();

	const { v2Options: searchOptions } = useSearchOptions(true);
	const { navigate } = useNavigation();
	const [{ selectedCategory }, { reset }] = useEntitySearch();

	const onSearch = useCallback(
		(userPrompt: string) => {
			const category = selectedCategory.value != '' ? selectedCategory : searchOptions[0];
			if (!category.value) return;

			reportAskAIEvent({
				event: 'global-search-enter',
				metaData: { category: category.value, searchTerm: userPrompt },
			});
			const searchPath = `${SearchResultsPath}/${encodeURIComponent(userPrompt)}/${category.value}`;
			navigate({ path: searchPath });
			reset();
			onItemClicked(false);
		},
		[navigate, onItemClicked, reportAskAIEvent, reset, searchOptions, selectedCategory]
	);

	return {
		action: onSearch,
		header: 'Search in your data',
		component: <AskAISearchData value={inputValue} key={'search'} searchOptions={searchOptions} />,
	};
}

function useCreateNewMetricItem({ inputValue, onItemClicked }: SelectableItemProps): SelectableItem {
	const { reportAskAIEvent } = useReportAIEvent();
	const { isOpen, onOpen, onClose } = useModal();

	const onMetricTypeClick = useCallback(() => {
		onItemClicked(false);
	}, [onItemClicked]);

	const onCreateNewMetric = useCallback(
		(userPrompt: string) => {
			reportAskAIEvent({
				event: 'create-new-metric',
				metaData: { queryText: userPrompt },
			});
			onOpen();
		},
		[onOpen, reportAskAIEvent]
	);

	return {
		action: onCreateNewMetric,
		component: (
			<AskAICreateNewMetricPopover
				prompt={inputValue}
				createInNewTab={false}
				onMetricTypeClick={onMetricTypeClick}
				isSelectMetricTypeOpen={isOpen}
				onSelectMetricTypeClose={onClose}
				key={'create-new-metric'}
			>
				<Flex alignItems={'center'}>
					<Plus16 />
					<Typography
						variant={'DesktopH8Regular'}
						textAlign="start"
						paddingLeft={'12px'}
						testId={`ask-ai-create-new-metric`}
					>
						Create new metric to answer “{inputValue}”
					</Typography>
				</Flex>
			</AskAICreateNewMetricPopover>
		),
	};
}

function createNavigateToMetricItem({
	action,
	metricDisplayName,
	metricCategory,
	isLast,
}: Pick<SelectableItem, 'action'> & {
	metricDisplayName: string;
	metricCategory: string;
	isLast: boolean;
}): SelectableItem {
	return {
		component: <SearchMetricResultItem metricDisplayName={metricDisplayName} metricCategory={metricCategory} />,
		borderTop: false,
		action,
		padding: isLast ? '0 8px 8px 8px' : '0 8px',
		hasMinHeight: false,
	};
}

function useSearchMetrics({
	inputValue,
	onItemClicked,
	metricCategories,
}: SelectableItemProps & { metricCategories: CategoryV2[] }): SelectableItem[] {
	const { navigate } = useNavigation();
	const searchMetrics = useCallback(
		({
			inputValue,
		}: Pick<SelectableItemProps, 'inputValue'>): {
			metricsMatchedByName: { category: CategoryV2; metricMetadata: MetricMetadataV2 }[];
			metricsMatchedByDescription: { category: CategoryV2; metricMetadata: MetricMetadataV2 }[];
		} => {
			const metricsMatchedByName: { category: CategoryV2; metricMetadata: MetricMetadataV2 }[] = [];
			const metricsMatchedByDescription: { category: CategoryV2; metricMetadata: MetricMetadataV2 }[] = [];
			metricCategories.forEach((metricCategory) => {
				metricCategory.metrics.forEach((metricMetadata) => {
					if (filterStringByTerm(inputValue, metricMetadata.name.replaceAll('_', ' '))) {
						metricsMatchedByName.push({ metricMetadata, category: metricCategory });
					} else {
						if (filterStringByTerm(inputValue, metricMetadata.description)) {
							metricsMatchedByDescription.push({ metricMetadata, category: metricCategory });
						}
					}
				});
				return { metricsMatchedByName, metricsMatchedByDescription };
			});
			return { metricsMatchedByName, metricsMatchedByDescription };
		},
		[metricCategories]
	);
	const { metricsMatchedByName, metricsMatchedByDescription } = searchMetrics({ inputValue });
	const allMetrics = metricsMatchedByName.slice(0, MAX_METRICS_RESULTS_NUMBER);
	const remainingItems = MAX_METRICS_RESULTS_NUMBER - allMetrics.length;
	if (remainingItems > 0) {
		allMetrics.push(...metricsMatchedByDescription.slice(0, remainingItems));
	}

	const metricsHeader: SelectableItem = {
		header: 'Metrics',
		isHeaderOnly: true,
		padding: '8px 8px 0px 8px',
	};
	const emptyMetricsSubtitle: SelectableItem = {
		header: 'No metrics match your search.',
		isHeaderOnly: true,
		borderTop: false,
		padding: '0px 16px 8px 8px',
		isSubHeader: true,
	};
	const metricsDisplay =
		allMetrics.length > 0
			? allMetrics.map((matchedMetricObject, index) =>
					createNavigateToMetricItem({
						action: () => {
							onItemClicked(false);
							navigate({ path: `/${MetricPagePath}/${matchedMetricObject.metricMetadata.name}` });
						},
						metricDisplayName:
							matchedMetricObject.metricMetadata.displayName ?? matchedMetricObject.metricMetadata.name,
						metricCategory: matchedMetricObject.category.name,
						isLast: index == allMetrics.length - 1,
					})
			  )
			: [emptyMetricsSubtitle];

	return [metricsHeader, ...metricsDisplay];
}

function useSelectableItems({
	inputValue,
	onItemClicked,
	metricCategories,
}: SelectableItemProps & { metricCategories: CategoryV2[] }): SelectableItem[] {
	const selectableItems = [
		useInputEchoItem({ inputValue, onItemClicked }),
		...useSearchMetrics({ inputValue, onItemClicked, metricCategories }),
		useSearchDataItem({ inputValue, onItemClicked }),
		useCreateNewMetricItem({ inputValue, onItemClicked }),
	];
	if (inputValue.length === 0) return [];
	return selectableItems;
}

export function AskAIMainPage({ onItemClicked, onInputChange, inputValue }: AskAIMainPageProps) {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [metricCategories] = useMetricCategoriesV2();

	const selectableItems = useSelectableItems({ inputValue, onItemClicked, metricCategories });

	useEffect(() => {
		if (inputValue === '') {
			setSelectedIndex(0);
		}
	}, [inputValue, setSelectedIndex]);

	const onSubmitInput = useCallback(
		(userPrompt: string) => {
			if (userPrompt.length > 0) {
				selectableItems[selectedIndex]?.action?.call(null, userPrompt);
			}
		},
		[selectableItems, selectedIndex]
	);

	const upDownEvent = useCallback(
		(up: boolean) => {
			const numItems = selectableItems.length;
			let nextSelectableIndex = selectedIndex;
			do {
				const nextIndex = up ? nextSelectableIndex - 1 : nextSelectableIndex + 1;
				const fixedIndex = nextIndex == -1 ? selectableItems.length - 1 : nextIndex;
				nextSelectableIndex = fixedIndex % numItems;
				if (nextSelectableIndex == selectedIndex) {
					break;
				}
			} while (!isItemSelectable(selectableItems[nextSelectableIndex]));

			setSelectedIndex(nextSelectableIndex);
		},
		[selectableItems, selectedIndex]
	);

	const onKeyUp = useCallback(() => upDownEvent(true), [upDownEvent]);
	const onKeyDown = useCallback(() => upDownEvent(false), [upDownEvent]);
	const onEnter = useCallback(() => {
		onSubmitInput(inputValue);
	}, [inputValue, onSubmitInput]);

	useKeyPress(['ArrowUp'], onKeyUp);
	useKeyPress(['ArrowDown'], onKeyDown);
	useKeyPress(['Enter'], onEnter);

	const onHoverSelectableItem = useCallback((index?: number) => {
		if (index !== undefined) setSelectedIndex(index);
	}, []);

	const onClickSelectableItem = useCallback(() => {
		onEnter();
	}, [onEnter]);

	return (
		<Flex direction={'column'} color={'gray.1000'} data-testid={'ask-ai-main-page'} data-selected-index={selectedIndex}>
			<AskAIModalItem selectable={false} padding={'7px 4px'}>
				<AskAIInput
					placeholder={'Search or ask anything with Sightfull AI'}
					onChange={onInputChange}
					isErasable={true}
					hasBorder={false}
					reportEvent={'ask-ai-main-query'}
					reportFeature={'Ask AI'}
				/>
			</AskAIModalItem>
			{inputValue
				? selectableItems.map((selectableItem, index) => (
						<AskAIModalItem
							borderTop={selectableItem.borderTop != undefined ? selectableItem.borderTop : true}
							selectable={isItemSelectable(selectableItem)}
							selected={selectedIndex === index}
							key={index}
							header={selectableItem.header}
							onHover={() => onHoverSelectableItem(index)}
							onClick={onClickSelectableItem}
							padding={selectableItem.padding}
							hasMinHeight={selectableItem.hasMinHeight}
							isHeaderOnly={selectableItem.isHeaderOnly}
							isSubHeader={selectableItem.isSubHeader}
						>
							{selectableItem.component}
						</AskAIModalItem>
				  ))
				: null}
			{inputValue ? (
				<AskAIModalItem borderTop={true} backgroundColor={'gray.200'} selectable={false}>
					<AskAIFooter />
				</AskAIModalItem>
			) : null}
		</Flex>
	);
}
