import { Panel } from '@xyflow/react';
import Typography from '../Typography';
import colors from '../../../style/colors';
import Button from '../Button';
import { CloseLarge16 } from '../Icons';

export type HeaderPanelProps = {
	title?: string;
	closable?: boolean;
	onClose?: () => void;
};

export function HeaderPanel({ title, closable, onClose }: HeaderPanelProps) {
	return (
		<>
			<Panel position={'top-left'} style={{ padding: '9px 5px' }}>
				{title && (
					<Typography variant={'DesktopH7Medium'} color={colors.gray[1000]}>
						{title}
					</Typography>
				)}
			</Panel>
			<Panel position={'top-right'} style={{ padding: '5px' }}>
				{closable && (
					<Button variant={'outline'} colorScheme={'black'} onClick={onClose} isIconOnly={true} size={'inline'}>
						<CloseLarge16 />
					</Button>
				)}
			</Panel>
		</>
	);
}
