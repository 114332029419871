import { Fragment } from 'react';
import Box from '@components/Box';
import Typography from '@components/Typography';
import { PathArrowToSolid16 } from '@components/Icons';

import styles from './FiltersAndBreakdowns.module.scss';

const Breadcrumbs = ({ path, traverseToSpecificPath }: any) => {
	const shouldDisplayBreadcrumbs = path.length > 1;
	const pathWithoutLastItem = path.slice(0, -1);

	if (!shouldDisplayBreadcrumbs) {
		return null;
	}

	return (
		<Box className={styles.breadcrumbsContainer}>
			{pathWithoutLastItem.map((item: any, i: number) => {
				const shouldHideSeperator = i + 2 === path.length;

				return (
					<Fragment key={item.label + i}>
						<Typography
							color="gray.600"
							variant="Paragraph12R"
							_hover={{ color: 'blue.700', cursor: 'pointer' }}
							onClick={() => traverseToSpecificPath(item)}
						>
							{item.label}
						</Typography>
						{shouldHideSeperator ? null : <PathArrowToSolid16 className={styles.pathArrow} />}
					</Fragment>
				);
			})}
		</Box>
	);
};

export default Breadcrumbs;
