import Flex from '../../Flex';

export function SemanticPopupContent({ children }: { children?: React.ReactNode }) {
	return <SemanticContent padding={'16px'}>{children}</SemanticContent>;
}

export function SemanticNodeContent({ children }: { children?: React.ReactNode }) {
	return <SemanticContent padding={'12px'}>{children}</SemanticContent>;
}

function SemanticContent({ padding, children }: { padding?: string; children?: React.ReactNode }) {
	return (
		<Flex direction={'column'} gap={padding} flex={1} padding={padding}>
			{children}
		</Flex>
	);
}
