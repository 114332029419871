import Button from '@components/Button';
import Flex from '@components/Flex';
import { SpacesNavigationDrawer } from '@components/LeftSidePanelV2/SpacesNavigationDrawer';
import Tooltip from '@components/Tooltip';
import { AppPanel } from '@icons/index';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { CreateNewButton } from './CreateNewButton';
import { isSpacesPage, isWorkspacePage } from 'src/pages/WorkspacePage/utils';

export function DashboardWorkspacePanel({
	handleCollapsePanelClick,
	isCollapsed = false,
	setIsPanelActingLikeOnHover,
}: {
	handleCollapsePanelClick: VoidFunction;
	isCollapsed?: boolean;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
}) {
	const { reportEvent } = useReportEvent();
	const {
		isOpen: isCreateDashboardModalOpen,
		onOpen: onCreateDashboardModalOpen,
		onClose: onCreateDashboardModalClose,
	} = useModal();

	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');

	return (
		<>
			<Flex justifyContent={'space-between'} mb={'8px'}>
				<CreateNewButton
					isWithDropdown={isDashboardFoldersEnable && !isWorkspacePage()}
					onCreateNewDashboard={() => {
						if (!isDashboardFoldersEnable) reportEvent({ event: 'left-panel-add-button-clicked' });
						onCreateDashboardModalOpen();
					}}
				/>
				<Tooltip
					size="md"
					variant="fluid"
					label={isCollapsed ? 'Expand' : 'Collapse'}
					placement={'right'}
					marginLeft={'6px'}
				>
					<Button
						size={'small'}
						variant={'outline'}
						colorScheme={'gray'}
						onClick={handleCollapsePanelClick}
						isIconOnly={true}
						bgColor={!isCollapsed ? 'gray.200' : 'transparent'}
						_hover={{ bgColor: colors.buttonPanelItemColor }}
						mixBlendMode={'multiply'}
					>
						<AppPanel color={isCollapsed ? colors.gray['900'] : colors.gray['1000']} />
					</Button>
				</Tooltip>
			</Flex>

			<Flex flexDirection={'column'} flex={1} overflowY={'auto'}>
				{isSpacesPage() && (
					<SpacesNavigationDrawer
						isCreateEditModalOpen={isCreateDashboardModalOpen}
						onCreateEditModalOpen={onCreateDashboardModalOpen}
						onCreateEditModalClose={onCreateDashboardModalClose}
						setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
						isParentPanelCollapsed={isCollapsed}
					/>
				)}
			</Flex>
		</>
	);
}
