import { useDeleteFolderMutation, useEditFolderMutation, useInsertFolderMutation } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import useToast from './ui/useToast';
import { FolderData } from '../components/LeftSidePanelV2/DashboardFolder/UpsertFolderModal';

// const [insertCollectionAndFeedMutation, { loading: isInsertCollectionAndFeedLoading }] =
// 	useInsertCollectionAndFeedMutation();
// const [insertFolder, { loading: isInsertFolderLoading }] = useInsertFolderMutation();
// const [{ id: my_id }] = useUser();
// const { data: foldersList, error } = useListAllFoldersSubscription({
// 	variables: { my_id: my_id },
// });
// const [deleteFolder, { loading, isDeleteFolderLoading }] = useDeleteFolderMutation();
// const [editFolder, { loading: isRenameFolderLoading }] = useEditFolderMutation();
// const [reorderDashboard, { loading: isReorderDashboardLoading }] = useReorderDashboardMutation();
// const [reorderFolder, { loading: isReorderFolderLoading }] = useReorderFolderMutation();

// useEffect(() => {
// 	// This mutation already exist, there is a new argument: folder_id, with which dashbaord is put into a folder.
// 	insertCollectionAndFeedMutation({
// 		variables: { name: 'collection name', collection_type: 'dashboard', folder_id: 'folder id' },
// 	});

// 	// Inserting a new folder with a given name
// 	insertFolder({ variables: { name: 'folder name', emoji: '😍' } });

// 	// Getting all folders list. Notice that they should be ordered by "order" field.
// 	// Also it is possible to access all the dashboards within the folder, and for each dashboard access all signals.
// 	foldersList?.folders?.forEach((folder) => {
// 		console.log(folder.id, folder.name, folder.order, folder.emoji);
// 		folder.dashboards?.forEach((dashboard) => {
// 			console.log(dashboard.id, dashboard.name, dashboard.order, dashboard.description, dashboard.layout);
// 			dashboard.feed.feed_signals?.forEach((signal) => {
// 				console.log(signal.signal_id, signal.feed_id, signal.order, signal);
// 			});
// 		});
// 	});

// 	// Deleting a folder. Use it's id from the list of folders.
// 	deleteFolder({ variables: { id: 'folder id from list of folders' } });

// 	// Name and emoji fields are a must here. If you want emoji to stay the same, pass the same emoji.
// 	editFolder({ variables: { id: 'folder id to rename', name: 'new folder name', emoji: '😍' } });

// 	// Changing order of the folder (with drag and drop). The new order should be calculated by:
// 	//  * if folder moved to be the first in the folder, should be (previous_first_folder_order / 2)
// 	//  * if folder is somewhere in the middle, should be ((folder_before_order + folder_after_order) / 2)
// 	//	* if folder moved to be the last in the folder, should be (current_time_timestamp * 1000)
// 	reorderFolder({ variables: { id: 'folder id', new_order: 1733995675269 } });

// 	// Reorder a dashboard. Can be dragged and dropped to another folder \ same folder. Use dashboard id and new folder id
// 	// (if same folder, pass the same folder id).
// 	// The order should be calculated as it is in reorderFolder, but in relation to the dashboards in the new folder.
// 	reorderDashboard({
// 		variables: { dashboard_id: 'dashboard id', new_folder_id: 'new folder id', new_order: 1733995675269 },
// 	});
// });

export const useDashboardFolders = () => {
	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });
	const toast = useToast();
	const [insertFolder, { loading: isCreateFolderLoading }] = useInsertFolderMutation();
	const [deleteFolder, { loading: isDeleteFolderLoading }] = useDeleteFolderMutation();
	const [editFolder, { loading: isEditFodlerLoading }] = useEditFolderMutation();

	const onError = (err: unknown) => {
		if (err instanceof Error) toast({ variant: 'error', message: err.message });
	};

	const createDashboardFolder = async (name: string, emoji: string, isWithToast?: boolean) => {
		reportEvent({ event: 'sidebar-create-new-item-create', metaData: { itemType: 'folder' } });
		try {
			const insertFolderResult = await insertFolder({ variables: { name, emoji } });
			if (!insertFolderResult.data) return;
			if (isWithToast) toast({ variant: 'ok', message: 'Successfully created folder' });
			return insertFolderResult.data;
		} catch (error) {
			onError(error);
		}
	};

	const deleteDashboardFolder = async (id: string) => {
		reportEvent({ event: 'sidebar-delete-item', metaData: { itemType: 'folder' } });
		try {
			const insertFolderResult = await deleteFolder({ variables: { id } });
			if (insertFolderResult.data) toast({ variant: 'ok', message: 'Successfully deleted folder' });
		} catch (error) {
			onError(error);
		}
	};

	const editDashboardFolder = async (folder: FolderData) => {
		const { id, folderName, emoji } = folder;
		reportEvent({ event: 'sidebar-edit-item', metaData: { itemType: 'folder', name: folder.folderName } });
		try {
			const insertFolderResult = await editFolder({ variables: { id, name: folderName, emoji } });
			if (insertFolderResult.data) toast({ variant: 'ok', message: 'Successfully updated folder' });
		} catch (error) {
			onError(error);
		}
	};

	return {
		createDashboardFolder,
		isCreateFolderLoading,
		deleteDashboardFolder,
		isDeleteFolderLoading,
		editDashboardFolder,
		isEditFodlerLoading,
	};
};
