import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonMetricCatalogBody.module.scss';

const {
	skeletonTitle,
	skeletonSubTitle,
	categorySkeleton,
	skeletonCards,
	skeletonSearch,
	skeletonCardsV2,
	skeletonButton,
} = classes;

function SkeletonCards({ isRunningSightfullV2 = false }: { isRunningSightfullV2?: boolean }) {
	return (
		<Flex flexWrap={'wrap'} gap={'16px'} flex={1}>
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
		</Flex>
	);
}

function SkeletonBanner() {
	return (
		<Flex
			gap={'12px'}
			padding={'104px 32px 44px'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			width={'100%'}
		>
			<Skeleton className={skeletonTitle} />
			<Skeleton className={skeletonSubTitle} />
			<Skeleton className={skeletonSearch} />
			<Flex justifyContent="space-between" gap={'12px'}>
				<Skeleton className={skeletonButton} />
				<Skeleton className={skeletonButton} />
				<Skeleton className={skeletonButton} />
				<Skeleton className={skeletonButton} />
			</Flex>
		</Flex>
	);
}

export function SkeletonMetricCatalogBody({
	isRunningSightfullV2 = false,
	paddingLeft,
}: {
	isRunningSightfullV2?: boolean;
	paddingLeft: string;
}) {
	return (
		<Flex flexDirection={'column'} paddingLeft={paddingLeft}>
			{isRunningSightfullV2 && <SkeletonBanner />}
			<Flex flexDirection={'column'}>
				<Skeleton className={categorySkeleton} />
				<SkeletonCards isRunningSightfullV2={isRunningSightfullV2} />
			</Flex>
			<Flex marginTop={'40px'} flexDirection={'column'}>
				<Skeleton className={categorySkeleton} />
				<SkeletonCards isRunningSightfullV2={isRunningSightfullV2} />
			</Flex>
		</Flex>
	);
}
