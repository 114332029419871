import { DatePicker } from 'src/common/components/DatePicker';
import Input from 'src/common/components/Input';
import { FieldType, ModalFieldValue } from '../types';
import { BooleanField } from 'src/layout/EntityProfileDrawer/components/BooleanField';
import { Calendar216 } from '@icons/index';

export const modalFieldByType: {
	[key in FieldType]: ({
		value,
		onChange,
		isDisabled,
	}: {
		value: ModalFieldValue;
		onChange: (newValue: ModalFieldValue | null) => void;
		isDisabled: boolean;
	}) => JSX.Element;
} = {
	float: ({
		value,
		onChange,
		isDisabled,
	}: {
		value: ModalFieldValue;
		onChange: (newValue: ModalFieldValue) => void;
		isDisabled?: boolean;
	}) => (
		<Input
			testId="field-float-input"
			value={Number(value)}
			onChange={onChange}
			isDisabled={isDisabled}
			type="number"
			width={'100%'}
		/>
	),
	int: ({
		value,
		onChange,
		isDisabled,
	}: {
		value: ModalFieldValue;
		onChange: (newValue: ModalFieldValue) => void;
		isDisabled?: boolean;
	}) => (
		<Input
			testId="field-int-input"
			value={Number(value)}
			onChange={onChange}
			isDisabled={isDisabled}
			type="number"
			width={'100%'}
		/>
	),
	string: ({
		value,
		onChange,
		isDisabled,
	}: {
		value: ModalFieldValue;
		onChange: (newValue: ModalFieldValue) => void;
		isDisabled?: boolean;
	}) => (
		<Input
			testId="field-string-input"
			value={String(value)}
			onChange={onChange}
			isDisabled={isDisabled}
			type="text"
			width={'100%'}
		/>
	),
	datetime: ({
		value,
		onChange,
		isDisabled,
	}: {
		value: ModalFieldValue;
		onChange: (newValue: ModalFieldValue | null) => void;
		isDisabled?: boolean;
	}) => (
		<DatePicker
			testId="field-datetime-input"
			onChange={onChange}
			value={new Date(String(value))}
			isDisabled={isDisabled}
			leftComponent={<Calendar216 color={'gray.800'} />}
		/>
	),
	bool: ({
		value,
		onChange,
		isDisabled,
	}: {
		value: ModalFieldValue;
		onChange: (newValue: ModalFieldValue) => void;
		isDisabled?: boolean;
	}) => <BooleanField testId="field-bool-input" value={Boolean(value)} onChange={onChange} isDisabled={isDisabled} />,
} as const;
