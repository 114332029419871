import { gql } from '@apollo/client';
import { SignalFields } from 'src/queries/signals';

export const WorkspaceSignalFragment = gql`
	${SignalFields}
	fragment WorkspaceSignalFragment on feed_signals {
		signal_id
		feed_id
		signal {
			...SignalFields
		}
		order
		resolved
		reason
		display_options
	}
`;
export const WorkspaceFragment = gql`
	${WorkspaceSignalFragment}
	fragment WorkspaceFragment on workspaces {
		id
		name
		description
		order
		layout
		feed {
			feed_signals {
				...WorkspaceSignalFragment
			}
		}
	}
`;

export const WorkspaceGetSingle = gql`
	${WorkspaceFragment}
	query WorkspaceGetSingle($id: uuid, $my_id: uuid) {
		workspaces(where: { id: { _eq: $id } }) {
			...WorkspaceFragment
		}
	}
`;

export const WorkspaceGetSingleByOrder = gql`
	${WorkspaceFragment}
	query WorkspaceGetSingleByOrder($offset: Int!, $my_id: uuid) {
		workspaces(
			limit: 1
			offset: $offset
			order_by: [{ order: asc }, { created_at: asc }]
			where: { collection_type: { _eq: "workspace" } }
		) {
			...WorkspaceFragment
		}
	}
`;
