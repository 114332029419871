import colors from 'src/style/colors';
import Avatar from '../Avatar/Avatar';
import Box from '../Box';
import Flex from '../Flex';
import ListItem from '../ListItem';
import Typography from '../Typography';
import { ResourceRolesUser } from './useResourceRoles';

export function GroupedUsersListItem({ users, onClick }: { users: ResourceRolesUser[]; onClick: () => void }) {
	return (
		<ListItem
			hasRoundedCorners
			hoverColor="blue.100"
			color="gray.1000"
			borderRadius="8px"
			size="md"
			label={
				<Flex color={colors.gray[1000]}>
					<Typography variant="DesktopH8Medium" color={'gray.1000'}>
						{users
							.slice(0, 2)
							.map((user) => user.userName)
							.join(', ')}
						&nbsp;
					</Typography>
					{users.length > 2 && (
						<>
							<Typography variant="DesktopH8Regular" color={'gray.1000'}>
								and
							</Typography>
							<Typography variant="DesktopH8Medium" color={'gray.1000'}>
								&nbsp;{users.length - 2} others
							</Typography>
						</>
					)}
				</Flex>
			}
			onClick={onClick}
			prefixComponent={
				<Flex width={'24px'} height={'24px'}>
					<Flex position={'relative'}>
						<Box position={'absolute'} top={'0px'} left={'0px'}>
							<Avatar
								imageUrl={users[0].avatarURL || ''}
								name={users[0].userName}
								shape="round"
								width={'15px'}
								height={'15px'}
							/>
						</Box>
						<Box position={'absolute'} top={'9px'} left={'9px'}>
							<Avatar
								imageUrl={users[1].avatarURL || ''}
								name={users[1].userName}
								shape="round"
								width={'15px'}
								height={'15px'}
							/>
						</Box>
					</Flex>
				</Flex>
			}
			hasChevronRight={true}
		/>
	);
}
