import Flex from '@components/Flex';
import Typography from '@components/Typography';
import colors from 'src/style/colors';
import { BarChart16 } from '@icons/index';

export function SearchMetricResultItem({
	metricDisplayName,
	metricCategory,
}: {
	metricDisplayName: string;
	metricCategory: string;
}) {
	return (
		<Flex alignItems={'center'} gap={'12px'}>
			<BarChart16 />
			<Flex>
				<Typography variant={'DesktopH8Regular'} color={colors['gray']['1000']}>
					{metricDisplayName}
				</Typography>
				<Typography variant={'DesktopH8Regular'} color={colors['gray']['500']}>
					&nbsp;- {metricCategory}
				</Typography>
			</Flex>
		</Flex>
	);
}
