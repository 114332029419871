import { ValueFormatterParams } from 'ag-grid-community';
import { CellRenderer } from 'src/common/components/Table/CellRenderer';
import { formatValue } from 'src/common/utils/valueFormatting';
import { formatDate, formatDateTime } from 'src/common/utils/valueFormatting/formatters';
import { ColDef } from 'src/generated/graphql';
import TenantConfigType from 'src/types/tenantConfig';
import {
	ActionsRenderer,
	ConnectorLogoRenderer,
	CreatedByRenderer,
	LastSyncRenderer,
	StatusBadgeRenderer,
} from '../components/ConnectorsTable/renderers';
import { NOT_ACTIVE_COLUMNS, SYNC_COPY } from './constants';
import { NameCol } from './types';
import { FIVETRAN_FLOW_NOT_COMPLETE_STORAGE_NAME } from 'src/common/components/TopNavigation/DataConnectorsModal/constants';
import { getSourcesInfo } from '@hooks/useSourceInfo';

export const getCellRenderer = (colName: string) => {
	switch (colName) {
		case '$name':
			return ConnectorLogoRenderer();
		case '$last_sync':
			return LastSyncRenderer();
		case '$created':
			return CreatedByRenderer();
		case '$status':
			return StatusBadgeRenderer();
		case '$actions':
			return ActionsRenderer();
		default:
			return CellRenderer(false);
	}
};

export const serializeConnectorsTableRowDef = (rows: Record<string, any>[]) => {
	if (!rows.length) return [];
	return rows.map((row, index) => ({
		_row_id: index,
		$name: {
			sourceName: row.name,
			status: row.connectorStatus,
			type: row.type,
		},
		$last_sync: {
			date: row.lastSuccessfulSync ? formatDateTime(row.lastSuccessfulSync) : 'No data',
			isSyncing: row.syncState === 'syncing',
		},
		$created: {
			date: row.createdAt && formatDate(row.createdAt),
			author: row.createdBy,
		},
		$status: row.connectorStatus,
		$actions: {
			connectorId: row.connectorId,
			connectorType: row.type,
			status: row.connectorStatus,
		},
	}));
};

export const serializeConnectorsTableColumnDef = (colDefs: ColDef[], tenantConfig: TenantConfigType) =>
	colDefs.map((col) => ({
		headerName: col?.colDisplayName,
		field: col?.colName,
		pinned: false,
		valueFormatter: (params: ValueFormatterParams) => formatValue(col.colName, params.value, tenantConfig),
		filterKey: col?.colName,
		resizable: false,
		cellRenderer: getCellRenderer(col?.colName),
		headerComponentParams: { removable: false, isWithColMenu: false, columnsWithoutActions: NOT_ACTIVE_COLUMNS },
		comparator: (valueA: string | NameCol, valueB: string | NameCol) => {
			if (col?.colName === '$name') return compareByName(valueA, valueB);
			if (col?.colName === '$last_sync') return compareByLastSync(valueA, valueB);
			if (typeof valueA === 'string' && typeof valueB === 'string') return valueA.localeCompare(valueB);
			if (valueA === valueB) return 0;
			return valueA > valueB ? 1 : -1;
		},
	}));

const isNameCol = (value: unknown): value is { sourceName: string } =>
	typeof value === 'object' && value !== null && 'sourceName' in value;

const isLastSyncCol = (value: unknown): value is { date: string; isSyncing: boolean } =>
	typeof value === 'object' && value !== null && 'date' in value && 'isSyncing' in value;

const compareByName = (valueA: unknown, valueB: unknown): number => {
	if (isNameCol(valueA) && isNameCol(valueB)) {
		return valueA.sourceName.localeCompare(valueB.sourceName);
	}
	return 0;
};
const compareByLastSync = (valueA: unknown, valueB: unknown): number => {
	if (isLastSyncCol(valueA) && isLastSyncCol(valueB)) {
		const sortingValueA = valueA.isSyncing ? SYNC_COPY : valueA.date;
		const sortingValueB = valueB.isSyncing ? SYNC_COPY : valueB.date;
		return sortingValueA.localeCompare(sortingValueB);
	}
	return 0;
};

export const getConnectorsStorageItems = (): { timestamp: string; serviceId?: string } | undefined => {
	const data = localStorage.getItem(FIVETRAN_FLOW_NOT_COMPLETE_STORAGE_NAME);
	if (!data) return undefined;
	return JSON.parse(data);
};

export const getConnectorsName = () => {
	const storageItems = getConnectorsStorageItems();
	return getSourcesInfo({ source: storageItems?.serviceId })?.connectorInfo?.name;
};
