import Box from '@components/Box';
import Flex from '@components/Flex';
import { MetricCardV2 } from './MetricCardV2';
import { CardContentProps, RecentCardProps } from '../types';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import classes from './MetricCardV2.module.scss';
import colors from 'src/style/colors';
import PendingSetupMetricPreviewWhite from 'src/assets/images/pending-setup-metric-preview-white.svg';

const cardStyles = {
	chartHeight: '144px',
	wrapperPadding: '0',
	cardShadow: 'cardShadow',
	cardBgColor: 'white',
	cardHeight: 'auto',
	maxCardHeight: 'auto',
	cardPaddingTop: '24px',
	pendingSetupImageSrc: PendingSetupMetricPreviewWhite,
};

export function RecentMetricCard({ metric, withError, dataIntercomSequence }: RecentCardProps) {
	const { name, displayName, entity } = metric;
	const { metricName } = classes;

	const CardContent = ({
		handleClick,
		CardPreview,
		isOverflowingName,
		nameRef,
		entityDisplayName,
		SourceLogo,
		labelName,
	}: CardContentProps) => {
		return (
			<Flex flexDirection={'column'} onClick={handleClick}>
				{CardPreview?.()}
				<Flex marginTop={'12px'} alignItems={'center'} justifyContent={'space-between'}>
					<Box>
						<Tooltip size="md" variant="fluid" label={isOverflowingName && (displayName ?? name)}>
							<Typography
								minLines={1}
								color={'gray.1000'}
								noOfLines={1}
								variant="DesktopH8Medium"
								marginBottom={'-2px'}
							>
								<Box ref={nameRef} className={metricName}>
									{displayName ?? name}
								</Box>
							</Typography>
						</Tooltip>
						<Typography color={entity ? colors.gray[700] : 'transparent'} cursor={'inherit'} variant="Paragraph12R">
							{entity ? entityDisplayName : 'Placeholder'}
						</Typography>
					</Box>
					{SourceLogo && (
						<Tooltip label={labelName} size="sm">
							{SourceLogo}
						</Tooltip>
					)}
				</Flex>
			</Flex>
		);
	};

	return (
		<MetricCardV2
			categoryName="recent-metrics"
			withError={withError}
			metric={metric}
			dataIntercomSequence={dataIntercomSequence}
			cardStyles={cardStyles}
			renderItem={({
				handleClick,
				CardPreview,
				isOverflowingName,
				nameRef,
				entityDisplayName,
				SourceLogo,
				labelName,
			}: CardContentProps) => (
				<CardContent
					handleClick={handleClick}
					CardPreview={CardPreview}
					isOverflowingName={isOverflowingName}
					nameRef={nameRef}
					entityDisplayName={entityDisplayName}
					SourceLogo={SourceLogo}
					labelName={labelName}
				/>
			)}
		/>
	);
}
