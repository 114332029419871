import { BaseEdge, EdgeProps } from '@xyflow/react';
import { EntityToEntityEdgeType } from '../types';
import colors from '../../../../style/colors';
import { getBezierPath } from './utils';
import { EntityRelationshipsEdgeButton } from './Relationships/EntityRelationshipsEdgeButton';

export function EntityToEntityEdge({
	sourceX,
	sourceY,
	targetX,
	targetY,
	data,
	...props
}: EdgeProps<EntityToEntityEdgeType>) {
	const { relationships } = data ?? {};
	const hasRelationships = relationships && relationships.length > 0;

	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		targetX,
		targetY,
	});

	return (
		<>
			<BaseEdge
				{...props}
				path={edgePath}
				style={{
					stroke: hasRelationships ? colors.cyan[800] : colors.gray[300],
				}}
			/>
			<EntityRelationshipsEdgeButton labelX={labelX} labelY={labelY} relationships={relationships} />
		</>
	);
}
