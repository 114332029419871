import { EdgeLabelRenderer } from '@xyflow/react';
import { ManyToOneThin20 } from '@components/Icons';
import Flex from '@components/Flex';
import { FlowPopover } from '@components/Flow';
import { useReportEvent } from '@services/analytics';
import colors from '../../../../../style/colors';
import { SemanticRelationshipType } from '../../types';
import { SemanticPopup } from '../../components/SemanticPopup';
import { RelationshipPopupContent } from './RelationshipPopupContent';
import { MultiPageRelationshipsContent } from './MultiPageRelationshipsContent';
import { useCallback } from 'react';

export type EntityRelationshipsEdgeButtonProps = {
	labelX: number;
	labelY: number;
	relationships?: SemanticRelationshipType[];
};

export function EntityRelationshipsEdgeButton({ labelX, labelY, relationships }: EntityRelationshipsEdgeButtonProps) {
	const isMultiPage = (relationships?.length ?? 0) > 1;

	const { reportEvent } = useReportEvent();
	const onPopoverTrigger = useCallback(() => {
		reportEvent({
			event: 'lineage-relationship-button-clicked',
			metaData: { relationshipType: 'entity', relationship: relationships?.map((rel) => rel.name).join('.') },
		});
	}, [relationships, reportEvent]);

	if (!relationships || relationships?.length === 0) return;

	return (
		<EdgeLabelRenderer>
			<FlowPopover
				triggerLocation={{ x: labelX, y: labelY }}
				onTrigger={onPopoverTrigger}
				popoverContent={
					<SemanticPopup>
						{isMultiPage ? (
							<MultiPageRelationshipsContent relationships={relationships} />
						) : (
							<RelationshipPopupContent
								relationships={relationships}
								isMultiTraversal={false}
								subtitle={'Relationship'}
							/>
						)}
					</SemanticPopup>
				}
			>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					height={'28px'}
					width={'28px'}
					borderColor={colors.cyan[800]}
					borderRadius={'24px'}
					cursor={'pointer'}
					border={'1px solid'}
					color={colors.cyan[800]}
					backgroundColor={'white'}
				>
					<ManyToOneThin20 />
				</Flex>
			</FlowPopover>
		</EdgeLabelRenderer>
	);
}
