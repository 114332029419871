import { BaseEdge, EdgeProps, OnSelectionChangeFunc, useOnSelectionChange } from '@xyflow/react';
import { MetricToMetricEdgeType } from '../types';
import colors from '../../../../style/colors';
import { MetricRelationshipsEdgeButton } from './Relationships/MetricRelationshipsEdgeButton';
import { useCallback, useState } from 'react';
import { getBezierPath } from './utils';

export function MetricToMetricEdge({
	source,
	target,
	sourceX,
	sourceY,
	targetX,
	targetY,
	data,
	...props
}: EdgeProps<MetricToMetricEdgeType>) {
	const { relationships } = data ?? {};
	const hasRelationships = relationships && relationships.length > 0;
	const [isConnectedMetricSelected, setIsConnectedMetricSelected] = useState(false);

	const onSelectionChanged: OnSelectionChangeFunc = useCallback(
		({ nodes }) => {
			const connectedMetric = nodes.find((node) => node.id === source || node.id === target);
			setIsConnectedMetricSelected(!!connectedMetric);
		},
		[source, target]
	);

	useOnSelectionChange({ onChange: onSelectionChanged });

	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		targetX,
		targetY,
	});

	return (
		<>
			<BaseEdge
				{...props}
				path={edgePath}
				style={{
					stroke: hasRelationships
						? colors.cyan[isConnectedMetricSelected ? 1000 : 800]
						: colors.violet[isConnectedMetricSelected ? 800 : 300],
				}}
			/>
			<MetricRelationshipsEdgeButton labelX={labelX} labelY={labelY} relationships={relationships} />
		</>
	);
}
