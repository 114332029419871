import { useCallback, useMemo } from 'react';
import { useMetricCatalogV2LazyQuery } from 'src/generated/graphql';
import { normalizeCategoriesV2 } from 'src/normalize';
import { CategoryV2 } from 'src/types/metric';

export const useMetricCategoriesV2 = (clearMetricCardsCache?: VoidFunction): [CategoryV2[], boolean] => {
	const [fetchCategories, { data: rawMetricsV2, error: didMetricV2LoadFailed, loading: isLoadingCategories }] =
		useMetricCatalogV2LazyQuery();

	const getMetricCategories = useCallback(() => {
		const categories = rawMetricsV2?.metricCatalogV2.categories;

		if (categories) {
			return normalizeCategoriesV2(categories, { shouldDropUncategorized: true });
		}
		if (!didMetricV2LoadFailed && !isLoadingCategories) {
			fetchCategories();
		}

		return [];
	}, [fetchCategories, didMetricV2LoadFailed, isLoadingCategories, rawMetricsV2]);

	const metricCategories = useMemo(() => getMetricCategories(), [getMetricCategories]);

	clearMetricCardsCache?.();

	return [metricCategories, isLoadingCategories];
};
