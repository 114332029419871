import { Box } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Button from 'src/common/components/Button';
import Flex from 'src/common/components/Flex';
import { CollapsedSidebar16, ExpandedSidebar16 } from 'src/common/components/Icons';
import { shouldLeftExpandCollapsePanelExpand } from 'src/common/utils/utils';
import { useReportEvent } from 'src/services/analytics';
import { ResizePanel } from '../ResizePanel';
import classes from './LeftExpandCollapsePanel.module.scss';
import { TinySwitch } from '../TinySwitch/TinySwitch';

type Props = {
	minWidth?: number;
	width: number;
	isLoading?: boolean;
	isCollapsable?: boolean;
	renderItem: (isShowingFullSize: boolean, expandPanel: () => void) => ReactNode;
	isResizeBlocked?: boolean;
	handleExpand?: (newValue: boolean) => void;
	shouldShowHiddenMetrics?: boolean;
	setShouldShowHiddenMetrics?: (shouldShowHiddenMetrics: boolean) => void;
};

export function LeftExpandCollapsePanel({
	isCollapsable,
	renderItem,
	minWidth,
	width,
	isLoading,
	isResizeBlocked,
	handleExpand,
	shouldShowHiddenMetrics = false,
	setShouldShowHiddenMetrics,
}: Props) {
	const { reportEvent } = useReportEvent();

	const [isMouseEnter, setIsMouseEnter] = useState(false);
	const [isShowingFooterShadow, setIsShowingFooterShadow] = useState(false);

	const breakpointsRef = useRef<HTMLDivElement>(null);

	const [isExpanded, setIsLeftExpandCollapsePanelExpanded] = useState(shouldLeftExpandCollapsePanelExpand());

	const setIsExpanded = useCallback(
		(newExpandState: boolean) => {
			if (isExpanded != newExpandState) {
				reportEvent({ event: 'drill-down-panel-toggled', metaData: { expanded: newExpandState } });
			}
			setIsLeftExpandCollapsePanelExpanded(newExpandState);
			handleExpand?.(newExpandState);
		},
		[isExpanded, reportEvent, handleExpand]
	);

	const isShowingFullSize = isMouseEnter || isExpanded || !!isCollapsable;

	const collapsedPanelWidth = 64;

	const handleResize = useCallback(() => {
		if (isCollapsable) return;
		setIsExpanded(shouldLeftExpandCollapsePanelExpand());
		if (breakpointsRef.current) {
			setIsShowingFooterShadow(breakpointsRef.current?.scrollHeight > breakpointsRef.current?.clientHeight);
		}
	}, [setIsExpanded, isCollapsable]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize]);

	const handleOnExpand = (isExpanded: boolean) => {
		if (isExpanded) {
			setIsMouseEnter(false);
		}

		setIsExpanded(isExpanded);
	};

	const handleOnMouseEnter = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			const element = e.target as HTMLElement;
			const isHoveringSvg = element.tagName === 'svg' || element.tagName === 'path';

			if (!isExpanded && !isHoveringSvg) {
				setIsMouseEnter(true);
				handleExpand?.(true);
			}
		},
		[handleExpand, isExpanded]
	);

	const handleOnMouseLeave = useCallback(() => {
		setIsMouseEnter(false);
		handleExpand?.(false);
	}, [handleExpand]);

	return (
		<ResizePanel
			isResizeBlocked={!isExpanded || isResizeBlocked}
			minWidth={isShowingFullSize ? minWidth || width : collapsedPanelWidth}
			width={isExpanded ? width : collapsedPanelWidth}
		>
			<Box zIndex={2} ref={breakpointsRef} position="relative" height="100%">
				<Box
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={handleOnMouseLeave}
					className={classNames({
						[classes.collapsed]: !isShowingFullSize,
						[classes.isHovered]: isMouseEnter,
					})}
					transition="all 300ms"
					position="absolute"
					as="aside"
					top={0}
					width={'100%'}
				>
					{renderItem(isShowingFullSize, () => {
						setIsExpanded(true);
						setIsMouseEnter(false);
					})}

					{setShouldShowHiddenMetrics && isShowingFullSize && (
						<HiddenMetricsSwitcher
							shouldShowHiddenMetrics={shouldShowHiddenMetrics}
							setShouldShowHiddenMetrics={setShouldShowHiddenMetrics}
						/>
					)}

					{!isCollapsable && !isLoading && (
						<ExpandCollapseFooter
							isShowingFooterShadow={isShowingFooterShadow}
							isExpanded={isExpanded}
							setIsExpanded={handleOnExpand}
						/>
					)}
				</Box>
			</Box>
		</ResizePanel>
	);
}

const HiddenMetricsSwitcher = ({
	shouldShowHiddenMetrics = false,
	setShouldShowHiddenMetrics,
}: {
	shouldShowHiddenMetrics?: boolean;
	setShouldShowHiddenMetrics: (shouldShowHiddenMetrics: boolean) => void;
}) => {
	const { reportEvent } = useReportEvent();
	return (
		<Flex
			className={classes.bottomContent}
			height="44px"
			padding={'12px 16px'}
			alignItems="center"
			gap="8px"
			cursor={'pointer'}
			position="absolute"
			left={0}
			bottom={'56px'}
			right={0}
		>
			<Flex alignItems={'center'}>
				<TinySwitch
					isEnabled={shouldShowHiddenMetrics}
					onClick={(isChecked) => {
						reportEvent({
							event: 'metric-catalog-toggle-hidden-metrics',
							metaData: {
								isShowingHiddenMetrics: isChecked,
							},
						});
						setShouldShowHiddenMetrics(isChecked);
					}}
					text="Include hidden metrics"
				/>
			</Flex>
		</Flex>
	);
};

const ExpandCollapseFooter = ({
	setIsExpanded,
	isExpanded,
}: {
	setIsExpanded: (isExpanded: boolean) => void;
	isExpanded: boolean;
	isShowingFooterShadow: boolean;
}) => {
	return (
		<Flex
			className={classes.bottomContent}
			justifyContent="flex-end"
			flexDir="row"
			height="56px"
			paddingY="8px"
			paddingX="16px"
			alignItems="center"
			gap="8px"
			cursor={'pointer'}
			marginLeft="1px"
			onClick={() => {
				setIsExpanded(!isExpanded);
			}}
			position="absolute"
			left={0}
			bottom={0}
			right={0}
		>
			<Button isIconOnly variant="outline" size="inline" colorScheme="gray">
				{isExpanded ? <CollapsedSidebar16 /> : <ExpandedSidebar16 />}
			</Button>
		</Flex>
	);
};
