import Box from '@components/Box';
import Flex from '@components/Flex';
import Skeleton from '@components/Skeleton';
import Typography from '@components/Typography';
import { CheckboxCheck10, ChevronLeftSmall, ChevronRightSmall } from '@icons/index';
import chunk from 'lodash/chunk';
import { useEffect, useMemo, useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useTemplateInfoQuery } from 'src/generated/graphql';
import 'src/pages/MetricCatalog/TemplateOnboardingModal/AliceCarousel.scss';
import classes from 'src/pages/MetricCatalog/TemplateOnboardingModal/TemplateOnboardingModal.module.scss';
import { ProcessStatus } from 'src/pages/MetricCatalog/TemplateOnboardingModal/types';
import { MetricMetadataV2 } from 'src/types/metric';
import { CatalogMetricCard } from '../../MetricCards/CatalogMetricCard';

type StartStepProps = {
	referralPageName?: string;
	templateName: string;
	templatesTag?: string;
	setProcessStatus: (status: ProcessStatus) => void;
	processStatus: ProcessStatus;
	onClose: VoidFunction;
	onModalClose: VoidFunction;
};

export const StartStep = ({
	referralPageName,
	templateName,
	templatesTag,
	processStatus,
	setProcessStatus,
	onClose,
	onModalClose,
}: StartStepProps) => {
	const {
		error,
		data,
		loading: isFileInfoLoading,
	} = useTemplateInfoQuery({ variables: { referralPageName, templateName, templatesTag } });
	const templateDisplayName = data?.templateInfo?.title || '';
	const metricSource = useMemo(() => data?.templateInfo?.source, [data]);
	const metrics = useMemo(() => data?.templateInfo?.metrics || [], [data]);

	useEffect(() => {
		if (!error) return;

		if (error?.graphQLErrors?.[0]?.extensions?.code === 404) {
			setProcessStatus('templateNotFound');
			return onClose();
		}

		onModalClose();
	}, [error, onClose, onModalClose, setProcessStatus]);

	const carouselRef = useRef<AliceCarousel | null>(null);
	const nextSlide = () => {
		carouselRef.current?.slideNext();
	};
	const prevSlide = () => {
		carouselRef.current?.slidePrev();
	};

	const metricCardSlides = useMemo(() => {
		const metricCards = metrics.map(({ id, title, oneLiner, imageUrl }) => {
			const metricMetadata: MetricMetadataV2 = {
				id: id,
				name: id,
				displayName: title,
				description: oneLiner,
				source: metricSource || '',
				entity: '',
				isFullyDefined: true,
				isNew: false,
				hide: false,
			};
			return (
				<Box maxWidth={'234px'} key={id}>
					<CatalogMetricCard
						metric={metricMetadata}
						imageUrl={imageUrl}
						cardSize={'md'}
						thumbnail={
							<Flex justifyContent={'center'} alignItems={'center'} className={classes.checkMark}>
								<CheckboxCheck10 />
							</Flex>
						}
						noEffects
					/>
				</Box>
			);
		});

		return chunk(metricCards, 3);
	}, [metricSource, metrics]);

	return (
		<>
			<Flex justifyContent={'space-between'} mb={'16px'} gap={'12px'}>
				{isFileInfoLoading || processStatus === 'templateNotFound' ? (
					<Flex flexDirection={'column'} width={'100%'}>
						<Skeleton className={classes.templateNameSkeleton} />
						<Skeleton className={classes.metricsStringSkeleton} />
					</Flex>
				) : (
					<Flex flexDirection={'column'}>
						<Typography variant={'DesktopH7Medium'} color="gray.1000" marginBottom={'4px'}>
							{templateDisplayName}
						</Typography>
						<Typography variant={'Paragraph12R'} color="gray.700">
							{metrics.map(({ title }) => title).join(', ')}
						</Typography>
					</Flex>
				)}
				{metrics.length > 3 && (
					<Flex cursor={'pointer'} gap={'4px'}>
						<span className={classes.carouselButton} onClick={prevSlide}>
							<ChevronLeftSmall />
						</span>
						<span className={classes.carouselButton} onClick={nextSlide}>
							<ChevronRightSmall />
						</span>
					</Flex>
				)}
			</Flex>
			{isFileInfoLoading || processStatus === 'templateNotFound' ? (
				<Skeleton className={classes.carouselSkeleton} />
			) : (
				<AliceCarousel
					ref={carouselRef}
					infinite
					disableButtonsControls
					disableDotsControls
					mouseTracking
					items={metricCardSlides.map((metrics, index) => (
						<Box key={index}>
							<Flex gap={'16px'}>{metrics}</Flex>
						</Box>
					))}
				/>
			)}
		</>
	);
};
