import { Box, Flex } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { useCallback } from 'react';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import useUser from 'src/common/hooks/stores/useUser';
import { useModal } from 'src/common/hooks/ui/useModal';
import { BOOK_DEMO_URL } from 'src/common/utils/consts';
import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import Avatar from '../../Avatar/Avatar';
import { SpecificResource } from '../../Can/types';
import ListItem from '../../ListItem';
import Typography from '../../Typography';
import { AllUserDetailsModal } from '../AllUserDetailsModal';
import { rolesToString, RoleType } from '../constants';
import { CopyLinkFooterModal } from '../CopyLinkFooterModal';
import { GeneralAccessSelect } from '../GeneralAccessSelect/GeneralAccessSelect';
import { GroupedUsersListItem } from '../GroupedUsersListItem';
import { InviteTextInput } from '../InviteTextInput/InviteTextInput';
import { RoleSelect } from '../RoleSelect/RoleSelect';
import {
	ResourceRoles,
	ResourceRolesUser,
	useAssignGeneralAccessRoleOnResource,
	useAssignRoleToUserOnResource,
} from '../useResourceRoles';
import { UserListItem } from '../UserListItem';
import { Header } from './Header';
import { UpgradeRBACBanner } from './UpgradeRBACBanner';

export function RBACModal({
	onClose,
	resource,
	roles,
	isEditable = true,
}: {
	onClose: () => void;
	resource: SpecificResource;
	roles?: ResourceRoles;
	isEditable?: boolean;
}) {
	const { onCopyText } = useOnCopy();

	const onCopy = () => {
		const currentURL = window.location.href;
		void onCopyText({ contentToCopy: currentURL });
	};

	const { companyName: tenantName, logoUrl: tenantLogo } = useTenantConfig();
	const [{ id: myId }] = useUser();
	const myUser: ResourceRolesUser | undefined = roles?.users?.find((user) => user.userId === myId);
	const usersWithoutMe = roles?.users?.filter((user) => user.email != null && user.userId != myUser?.userId);

	const { onOpen: onOpenDetailsModal, isOpen: isDetailsModalOpen, onClose: onCloseDetailsModal } = useModal();
	const isPlayground = useFeatureFlag('pulse.sightfull2.playground.enable');
	const { navigate } = useNavigation();

	const [assignRoleToUserOnResource] = useAssignRoleToUserOnResource({ resource });
	const changeUserRole = useCallback(
		(userId: string, role: RoleType | null) => {
			assignRoleToUserOnResource(userId, role);
		},
		[assignRoleToUserOnResource]
	);
	const [changeGeneralAccessRole] = useAssignGeneralAccessRoleOnResource({ resource });

	return (
		<Flex direction={'column'}>
			{isDetailsModalOpen && (
				<AllUserDetailsModal
					onClose={() => {
						onCloseDetailsModal();
						onClose();
					}}
					onBack={onCloseDetailsModal}
					roles={usersWithoutMe ?? []}
					myRole={myUser?.role ?? 'viewer'}
					changeUserRole={isEditable ? changeUserRole : undefined}
				/>
			)}
			{!isDetailsModalOpen && (
				<Flex direction={'column'} gap={'8px'}>
					<Flex
						width={'450px'}
						direction={'column'}
						boxShadow={shadows['a-22']}
						borderRadius={'8px'}
						background={'white'}
					>
						<Header onClose={onClose} resourceType={resource.resourceType} />

						{!isEditable && (
							<UpgradeRBACBanner
								resourceType={resource.resourceType}
								onUpgradeClicked={() => {
									if (isPlayground) {
										window.open(BOOK_DEMO_URL, '_blank');
									} else {
										const params = new URLSearchParams({
											open_intercom: 'true',
											intercom_message:
												"Hey, I'd love to hear more about adding metric edit protection and share capabilities to my Sightfull platform. Thanks!",
											send_intercom_message: 'false',
										});
										navigate({
											path: window.location.pathname,
											additionalSearchParams: params,
											willIncludeAllSearchParams: true,
										});
									}
								}}
							/>
						)}
						{isEditable && myUser?.role && ['owner', 'editor'].includes(myUser.role) && (
							<InviteTextInput myRole={myUser?.role} resource={resource} users={roles?.users} />
						)}

						<Flex padding={'16px'} gap="24px" direction={'column'} paddingTop={'24px'}>
							<Flex gap={'12px'} direction={'column'}>
								<Typography variant="DesktopH10Regular" color={colors.gray[800]}>
									General access
								</Typography>
								<GeneralAccessSelect
									organizationName={capitalize(tenantName)}
									generalAccessRole={roles?.generalAccessRole ?? null}
									isEditable={isEditable && myUser?.role == 'owner'}
									onChangeGeneralAccessRole={(role) => {
										changeGeneralAccessRole(role);
									}}
								/>
							</Flex>
							<Flex gap={'12px'} direction={'column'}>
								<Typography variant="DesktopH10Regular" color={colors.gray[800]}>
									Who has access
								</Typography>
								<Flex direction={'column'}>
									{roles?.generalAccessRole && (
										<ListItem
											hasRoundedCorners
											size="md"
											state="disabled"
											label={
												<Typography variant="DesktopH8Regular" color={'gray.1000'}>
													Anyone at {capitalize(tenantName)}
												</Typography>
											}
											prefixComponent={
												<Avatar
													imageUrl={tenantLogo}
													name={tenantName}
													shape="round"
													width={'24px'}
													height={'24px'}
													isActive
												/>
											}
											suffixComponent={
												myUser?.role === 'owner' ? (
													<Box marginRight={'-9px'}>
														<RoleSelect
															onSelect={(role) => {
																changeGeneralAccessRole(role);
															}}
															selectedRole={roles.generalAccessRole}
															myRole={'editor'}
															isIncludingRemove={false}
														/>
													</Box>
												) : (
													<Typography variant="DesktopH8Regular" color={'gray.1000'}>
														{rolesToString[roles.generalAccessRole]}
													</Typography>
												)
											}
										/>
									)}
									{usersWithoutMe && usersWithoutMe.length > 1 && (
										<GroupedUsersListItem users={usersWithoutMe} onClick={onOpenDetailsModal} />
									)}
									{usersWithoutMe && usersWithoutMe.length === 1 && (
										<UserListItem
											key={usersWithoutMe[0].role}
											user={usersWithoutMe[0]}
											myRole={myUser?.role || 'viewer'}
											onRoleChange={isEditable ? (role) => changeUserRole(usersWithoutMe[0].userId, role) : undefined}
										/>
									)}
									{myUser && <UserListItem isMe={true} user={myUser} myRole={myUser.role} />}
								</Flex>
							</Flex>
						</Flex>
					</Flex>
					<CopyLinkFooterModal onCopyLink={onCopy} />
				</Flex>
			)}
		</Flex>
	);
}
