import _ from 'lodash';
import { useMemo } from 'react';
import { useGetRecentMetricsQuery } from 'src/generated/graphql';
import { CategoryV2, MetricMetadataV2 } from 'src/types/metric';

const RECENT_METRICS_TO_SHOW = 4;

export const useRecentMetrics = (metricCategories: CategoryV2[]): [MetricMetadataV2[], boolean] => {
	const { data: recentMetricsArray, loading: isLoadingRecentMetrics } = useGetRecentMetricsQuery({
		fetchPolicy: 'no-cache',
	});

	const allMetrics = useMemo(() => metricCategories.map((category) => category.metrics), [metricCategories]);
	const recentMetrics = useMemo(
		() =>
			recentMetricsArray?.getRecentMetrics?.recentMetrics
				?.slice(0, RECENT_METRICS_TO_SHOW)
				?.map((metricName) => _.flatten(allMetrics).find((metric) => metric.name === metricName))
				.filter((metric) => metric !== undefined) || [],
		[allMetrics, recentMetricsArray?.getRecentMetrics?.recentMetrics]
	);

	return [recentMetrics, isLoadingRecentMetrics];
};
