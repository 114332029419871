import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import colors from 'src/style/colors';
import Button from '../../Button';
import Flex from '../../Flex';
import Typography from '../../Typography';

export function UpgradeRBACBanner({
	resourceType,
	onUpgradeClicked,
}: {
	resourceType: string;
	onUpgradeClicked: VoidFunction;
}) {
	return (
		<Flex
			paddingY={'10px'}
			paddingX={'16px'}
			display={'flex'}
			alignItems={'center'}
			alignSelf={'stretch'}
			justifyContent={'space-between'}
			height={'44px'}
			backgroundColor={colors.gray[100]}
		>
			<Flex color={colors.gray[1000]}>
				<Typography variant="Paragraph12M">{pluralize(capitalize(resourceType))} are public.&nbsp;</Typography>
				<Typography variant="Paragraph12R">Ready to manage access?</Typography>
			</Flex>
			<Button
				size={'xxs'}
				variant="solid"
				colorScheme="blue"
				paddingX={'8px'}
				paddingY={'2px'}
				onClick={onUpgradeClicked}
			>
				Upgrade
			</Button>
		</Flex>
	);
}
