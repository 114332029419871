import { gql } from '@apollo/client';

export const UserRequestSetupConnector = gql`
	query UserRequestSetupConnector($connectorName: String!) {
		userRequestSetupConnector(connectorName: $connectorName) {
			status
		}
	}
`;

export const ConfigNewSource = gql`
	mutation ConfigNewSource(
		$dataIntegrator: String!
		$metadata: JSON
		$source: String!
		$destination: String = "postgres_rds_warehouse"
	) {
		configNewSource(
			dataIntegrator: $dataIntegrator
			source: $source
			destination: $destination
			metadata: $metadata
			hideSetupGuide: false
		) {
			status
			tenantId
			dataIntegrator
			integratorMetadata
		}
	}
`;

export const ReauthenticateSource = gql`
	mutation ReauthenticateSource($sourceId: String!, $hideSetupGuide: Boolean!) {
		reauthenticateSource(dataIntegrator: "fivetran", sourceId: $sourceId, hideSetupGuide: $hideSetupGuide) {
			status
			integratorMetadata
		}
	}
`;

export const ConfigNewMockSource = gql`
	mutation ConfigNewMockSource($dataIntegrator: String!, $metadata: JSON, $source: String!) {
		configNewMockSource(
			dataIntegrator: $dataIntegrator
			source: $source
			destination: "postgres_rds_warehouse"
			metadata: $metadata
		) {
			status
			tenantId
			dataIntegrator
			integratorMetadata
		}
	}
`;

export const ConfigNewMockDestination = gql`
	mutation ConfigNewMockDestination {
		configNewMockDestination(dataIntegrator: "fivetran", destination: "postgres_rds_warehouse") {
			status
			tenantId
			innerDestinationName
		}
	}
`;

export const AssignPlayground = gql`
	mutation AssignPlayground($playgroundType: PlaygroundType!, $fronteggUserId: String!) {
		assignPlayground(playgroundType: $playgroundType, fronteggUserId: $fronteggUserId) {
			status
		}
	}
`;
