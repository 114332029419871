import { gql } from '@apollo/client';

export const GetUserInformation = gql`
	query GetUserInformation($id_token: String = "") {
		login(id_token: $id_token) {
			user {
				id
				picture
				email
				first_name
				last_name
				timezone
				feed {
					id
				}
				sub
			}
			features {
				key
				user_id
				value
			}
			intercomHMAC
		}
		tenants {
			fiscal_year_start
			logo_url
			name
			trial_end
			graph_colors
			decimal_digits
			last_etl_synced
			lookback_months
		}
	}
`;

export const GetWorkingTenant = gql`
	query GetWorkingTenant($tid: Int!) {
		tenants_by_pk(tid: $tid) {
			tid
			name
			trial_end
			fiscal_year_start
			graph_colors
			decimal_digits
			logo_url
		}
	}
`;

export const UsersForMentions = gql`
	query UsersForMentions($email_regex: String!) {
		users(where: { _not: { email: { _iregex: $email_regex } } }) {
			id
			email
			first_name
			last_name
			picture
		}
	}
`;
