import { colors } from 'src/style/colors';
import shadows from 'src/style/shadows';
import Button from '../Button';
import Flex from '../Flex';
import { ChevronLeftSolid16, CloseLarge16 } from '../Icons';
import Typography from '../Typography';
import { RoleType } from './constants';
import { ResourceRolesUser } from './useResourceRoles';
import { UserListItem } from './UserListItem';

export function AllUserDetailsModal({
	onClose,
	onBack,
	roles,
	myRole,
	changeUserRole,
}: {
	onClose: () => void;
	onBack: () => void;
	roles: ResourceRolesUser[];
	myRole: RoleType;
	changeUserRole?: (userId: string, role: RoleType | null) => void;
}) {
	return (
		<Flex
			width={'450px'}
			maxHeight={'600px'}
			direction={'column'}
			boxShadow={shadows['a-22']}
			borderRadius={'8px'}
			background={'white'}
		>
			<Header onClose={onClose} onBack={onBack} collaboratorsCount={roles.length} />

			<Flex padding={'12px'} direction={'column'} overflowY={'auto'}>
				{roles.map((user) => {
					return (
						<UserListItem
							user={user}
							key={user.userId}
							myRole={myRole}
							onRoleChange={changeUserRole ? (role) => changeUserRole(user.userId, role) : undefined}
						/>
					);
				})}
			</Flex>
		</Flex>
	);
}

function Header({
	onClose,
	onBack,
	collaboratorsCount,
}: {
	onClose: () => void;
	onBack?: () => void;
	collaboratorsCount: number;
}) {
	return (
		<Flex
			padding={'12px'}
			display={'flex'}
			alignItems={'center'}
			alignSelf={'stretch'}
			justifyContent={'center'}
			height={'48px'}
			boxShadow={shadows.borderBottom}
		>
			<Flex flex={1}>
				<Button isIconOnly={true} onClick={onBack} variant="outline" size={'xxs'} colorScheme={'gray'}>
					<ChevronLeftSolid16 color={colors.gray['1000']} />
				</Button>
			</Flex>
			<Flex paddingX={'12px'}>
				<Typography variant={'DesktopH8Medium'} color={colors.gray['1000']} textAlign={'center'}>
					{collaboratorsCount} collaborators
				</Typography>
			</Flex>
			<Flex flex={1} justifyContent={'end'}>
				<Button isIconOnly={true} onClick={onClose} variant="outline" size={'xxs'} colorScheme={'gray'}>
					<CloseLarge16 color={colors.gray['1000']} />
				</Button>
			</Flex>
		</Flex>
	);
}
