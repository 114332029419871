export const EdgeMarker = {
	ArrowClosed: 'url(#edge_marker_arrowclosed)',
	Circle: 'url(#edge_marker_circle)',
	HalfCircle: 'url(#edge_marker_half_circle)',
};

export function EdgeMarkers() {
	return (
		<svg>
			<defs>
				<marker
					id="edge_marker_arrowclosed"
					viewBox="0 0 10 10"
					refX="5"
					refY="5"
					markerWidth="6"
					markerHeight="6"
					orient="auto-start-reverse"
					stroke="context-stroke"
					fill="context-stroke"
				>
					<path d="M 0 0 L 10 5 L 0 10 z" />
				</marker>
				<marker id="edge_marker_circle" markerWidth="8" markerHeight="8" refX="5" refY="5">
					<circle cx="5" cy="5" r="3" fill="context-stroke" />
				</marker>
				<marker
					id="edge_marker_half_circle"
					viewBox="0 0 10 10"
					refX="8"
					refY="5"
					markerWidth="10"
					markerHeight="10"
					orient="auto-start"
				>
					<path d="M 5 0 A 5 5 0 0 1 5 10" fill="context-stroke" />
				</marker>
			</defs>
		</svg>
	);
}
