import Box from '@components/Box';
import { Flow } from '@components/Flow';
import { performNodesLayout } from './components/SemanticNode';
import { SemanticObjectEdgeType, SemanticObjectNodeType } from './types';
import { EntityNode } from './Nodes/EntityNode';
import { MetricNode } from './Nodes/MetricNode';
import { MetricToMetricEdge } from './Edges/MetricToMetricEdge';
import { EntityToEntityEdge } from './Edges/EntityToEntityEdge';
import { EntityToMetricEdge } from './Edges/EntityToMetricEdge';
import { useReportEvent } from '../../../services/analytics';
import { useEffect } from 'react';

const SemanticNodeTypes = {
	metric: MetricNode,
	entity: EntityNode,
};

const SemanticEdgeTypes = {
	metricToMetric: MetricToMetricEdge,
	entityToEntity: EntityToEntityEdge,
	entityToMetric: EntityToMetricEdge,
};

export type SemanticFlowProps = {
	nodes: SemanticObjectNodeType[];
	edges: SemanticObjectEdgeType[];
	title?: string;
	closable?: boolean;
	onClose?: () => void;
};

export function SemanticFlow({ title, closable, onClose, nodes, edges }: SemanticFlowProps) {
	const { reportEvent } = useReportEvent();
	useEffect(() => {
		reportEvent({ event: 'lineage-opened' });
		return () => {
			reportEvent({ event: 'lineage-closed' });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box height={'100%'} width={'100%'} padding={'16px'}>
			<Flow
				closable={closable}
				onClose={onClose}
				title={title}
				nodeTypes={SemanticNodeTypes}
				edgeTypes={SemanticEdgeTypes}
				nodes={nodes}
				edges={edges}
				performNodesLayout={performNodesLayout}
			/>
		</Box>
	);
}
