import min from 'lodash/min';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import { defaultFeatureFlags } from 'src/config';
import { MetricCategory, MetricV2, Metrics, Tenants } from 'src/generated/graphql';
import { FeatureFlagNameType, FeatureFlagsType } from 'src/types/featureFlags';
import { Category, CategoryV2, MetricMetadata, MetricMetadataV2 } from 'src/types/metric';
import TenantConfigType, { defaultPlanConfig } from 'src/types/tenantConfig';
import { UserType } from 'src/types/user';
import { compareFlags, getValue, sortByNumericNames } from './utils';

const ALL_USERS_GROUP = '00000000-0000-0000-0000-000000000000';

export const flags = (arr: any, userId: any): FeatureFlagsType => {
	const flagsArray = [...arr];

	flagsArray.sort(compareFlags);

	const featureFlagObject = { ...defaultFeatureFlags } as FeatureFlagsType;

	for (let i = 0; i < flagsArray.length; i++) {
		const flag = flagsArray[i];
		const flagName: FeatureFlagNameType = flag.key;
		const hasDefaultFlag = Object.hasOwn(defaultFeatureFlags, flagName);

		if (!hasDefaultFlag) {
			continue;
		}

		const isFlagRelevantToUser = userId === flag.user_id || flag.user_id === ALL_USERS_GROUP;

		if (isFlagRelevantToUser) {
			featureFlagObject[flagName] = flag.value;
		}
	}

	return featureFlagObject;
};

export const user = (userObject: any, intercomHMAC: any): UserType => ({
	id: getValue(userObject?.id, null),
	email: getValue(userObject?.email, ''),
	firstName: getValue(userObject?.first_name, ''),
	lastName: getValue(userObject?.last_name, ''),
	picture: getValue(userObject?.picture, ''),
	sub: getValue(userObject?.sub, ''),
	timezone: getValue(userObject?.timezone, ''),
	feed: {
		id: getValue(userObject?.feed?.id, null),
	},
	intercomHMAC,
});

export const tenantSettings: (tenantSettings: Tenants[]) => TenantConfigType = (tenantSettings: Tenants[]) => {
	const tenant = tenantSettings[0];
	return {
		companyName: getValue(tenant?.name, ''),
		logoUrl: getValue(tenant?.logo_url, ''),
		fiscalYearStart: getValue(tenant?.fiscal_year_start, ''),
		trialEnd: getValue(tenant?.trial_end, ''),
		decimalDigits: getValue(tenant?.decimal_digits, null),
		graphColors: getValue(
			tenant?.graph_colors
				?.trim()
				?.split(',')
				.map((c) => `#${c}`),
			[]
		),
		lastEtlSync: tenant?.last_etl_synced ? new Date(tenant?.last_etl_synced) : undefined,
		lookbackMonths: getValue(tenant?.lookback_months, null),
		plan: getValue(tenant?.plan, defaultPlanConfig),
		isSightfullTenant: false,
	};
};

const uncategorized = 'uncategorized';

export function normalizeMetricMetadata(metric: Metrics): MetricMetadata {
	return {
		name: metric?.name ?? '',
		oneliner: metric?.oneliner ?? '',
		unit: metric?.unit ?? '',
		createdAt: metric?.created_at ?? '',
		updatedAt: metric?.updated_at ?? '',
		articleId: metric?.article_id ?? '',
		defaultChartType: metric?.default_chart_type ?? '',
		defaultRelativePeriod: metric?.default_relative_period ?? '',
		displayName: metric?.display_name ?? '',
		flavors: metric?.flavor ? [metric.flavor] : [],
		hide: metric?.hide ?? false,
		lastToolSyncedTs: metric?.last_tool_synced_ts ?? '',
		op: metric?.op ?? '',
		order: metric?.order?.valueOf(),
		relevantPeriodRanges: metric?.relevant_period_ranges ?? {},
		isCalculated: metric?.should_metric_run ?? false,
	};
}

export function normalizeMetricMetadataV2(metric: MetricV2): MetricMetadataV2 {
	return {
		id: metric?.metricId ?? '',
		name: metric?.metricName ?? '',
		entity: metric?.entity ?? '',
		description: metric?.meta.description ?? '',
		displayName: metric?.meta.displayName ?? '',
		oneliner: metric?.meta.oneLiner ?? '',
		defaultChartType: '',
		hide: metric?.meta?.hidden ?? false,
		source: metric?.meta.source,
		isFullyDefined: metric?.isFullyDefined ?? true,
		isNew: metric?.meta?.isNew ?? false,
	};
}

function groupMetrics(metrics: Metrics[]): Category[] {
	const groupBy = new Map<string, Category>();

	metrics.forEach((metric) => {
		const { category } = metric;
		const categoryName = category || uncategorized;
		const categoryObject = groupBy.get(categoryName);
		if (!categoryObject) {
			groupBy.set(categoryName, {
				name: categoryName,
				metrics: [],
			});
		}
		groupBy?.get(categoryName)?.metrics?.push(normalizeMetricMetadata(metric));
	});

	return [...groupBy.values()];
}

function mergeFlavors(metricCategories: Category[]) {
	const categories = [...metricCategories];

	categories.forEach((category) => {
		const metrics = sortBy(category.metrics, (metric) => metric.order, 'asc');
		const newMetrics = [] as MetricMetadata[];

		metrics.forEach((metric) => {
			const { flavors } = metric;
			const newMetricIndex = newMetrics.findIndex((newMetric) => newMetric.name === metric.name);
			if (newMetricIndex === -1) {
				newMetrics.push(metric);
			} else {
				newMetrics[newMetricIndex].flavors = [
					...new Set([...(newMetrics[newMetricIndex].flavors ?? []), ...flavors]).values(),
				];
			}
		});
		category.metrics = newMetrics;
	});

	return categories;
}

function sortMetrics(categories: Category[]) {
	const sortedCategories = sortBy(categories, minOrderInMetric);
	sortedCategories.forEach((category) => {
		category.metrics = sortBy(category.metrics, (metric) => metric.order);
	});
	return sortedCategories;
}

function sortCategoriesV2(category: CategoryV2[]) {
	const withSortedMetrics = category?.map((el) => ({
		name: el.name,
		id: el.id,
		metrics: sortByNumericNames(el.metrics),
		description: el.description,
		isFullyDefined: el.isFullyDefined,
	}));

	return sortByNumericNames(withSortedMetrics) as CategoryV2[];
}

function minOrderInMetric(category: Category) {
	return min(category.metrics.map((metric) => metric.order)) ?? 0;
}

export function metricCategoriesFromGraphQL(
	metrics: Metrics[],
	options: { shouldDropUncategorized?: boolean } = { shouldDropUncategorized: false }
): Category[] {
	const isCategorized = (m: Metrics) => m.category && m.category != uncategorized;
	const metricsListFiltered = options.shouldDropUncategorized ? metrics.filter(isCategorized) : metrics;
	const metricCategories = sortMetrics(mergeFlavors(groupMetrics(metricsListFiltered)));

	return metricCategories;
}

export function normalizeCategoriesV2(
	categories: MetricCategory[],
	options: { shouldDropUncategorized?: boolean } = { shouldDropUncategorized: false }
): CategoryV2[] {
	const filteredCategories = categories.filter(
		(category) => !options.shouldDropUncategorized || category.categoryId != null
	);

	const metricCategories: CategoryV2[] = filteredCategories.map((category) => ({
		id: category.categoryId,
		name: category.categoryName,
		description: category.description,
		metrics: category.metrics.map(normalizeMetricMetadataV2),
		isFullyDefined: category.isFullyDefined,
	}));

	const sortedMetricCategories = sortCategoriesV2(metricCategories);

	return sortedMetricCategories;
}

const IgnoreRegex = /([A-Z]| )/;
export function removeUnderscoresAndCapitalize(name: string) {
	const shouldPrettify = !IgnoreRegex.test(name);
	if (shouldPrettify) {
		return startCase(name.replace(/_/g, ' '));
	}
	return name;
}

export function addUnderscoresAndToLowerCase(name: string) {
	const shouldUglify = IgnoreRegex.test(name);
	if (shouldUglify) {
		return name
			.toLowerCase()
			.replace(/\s+/g, '_')
			.replace(/^_+|_+$/g, '');
	}
	return name;
}

export function semanticDisplayName({ meta, name }: { meta?: { display_name?: string }; name: string }) {
	return meta?.display_name ?? removeUnderscoresAndCapitalize(name);
}

export function removeNonAlphabeticCharacters(name: string) {
	return name.replace(/[^a-zA-Z0-9_ ]/g, '');
}

export function convertToValidCoreName(name: string) {
	return removeNonAlphabeticCharacters(addUnderscoresAndToLowerCase(name));
}

export function removeYAMLQuotes(string: string) {
	return string.replace(/"([^-"!@#%&*(),.?{}|<>\r\n]+[^\s])"/g, (fullString, stringWithoutQuotations) => {
		const isBoolean = stringWithoutQuotations === 'true' || stringWithoutQuotations === 'false';
		const isNumber = !isNaN(stringWithoutQuotations) || !isNaN(parseFloat(stringWithoutQuotations));
		const isIncludeSingleQuote = stringWithoutQuotations.includes("'");
		if (isBoolean || isNumber || isIncludeSingleQuote) {
			return `"${stringWithoutQuotations}"`;
		}
		return stringWithoutQuotations;
	});
}
