import { useRef, useState } from 'react';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import useDebouncedCallback from '@hooks/useDebouncedCallback';

export const useCarousel = () => {
	const [carouselElementKey, setCarouselElementKey] = useState(0);
	const [isPrevSlideDisabled, setIsPrevSlideDisabled] = useState(false);
	const [isNextSlideDisabled, setIsNextSlideDisabled] = useState(false);
	const [activeSlideNum, setActiveSlideNum] = useState(0);
	const [itemsInSlide, setItemsInSlide] = useState(0);
	const isOnlyOneSlide = isPrevSlideDisabled && isNextSlideDisabled;
	const carouselRef = useRef<AliceCarousel | null>(null);

	const goToNextSlide = (slides: unknown[]) => {
		const nextSlideNum = activeSlideNum + itemsInSlide;
		const lastSlideNum = slides.length - 1;
		carouselRef.current?.slideTo(nextSlideNum > lastSlideNum ? lastSlideNum : nextSlideNum);
	};

	const goToPrevSlide = () => {
		const prevSlideNum = activeSlideNum - itemsInSlide;
		carouselRef.current?.slideTo(prevSlideNum < 0 ? 0 : prevSlideNum);
	};

	const debouncedCarouselResize = useDebouncedCallback((elementId: string) => {
		const mainElementWidth = document.querySelector(`#${elementId}`)?.clientWidth || 0;
		setCarouselElementKey(mainElementWidth);
	}, 100);

	const setCarouselProps = (e: EventObject) => {
		const { isPrevSlideDisabled, isNextSlideDisabled, item, itemsInSlide } = e;
		setActiveSlideNum(item);
		setItemsInSlide(itemsInSlide);
		setIsPrevSlideDisabled(isPrevSlideDisabled);
		setIsNextSlideDisabled(isNextSlideDisabled);
	};

	return {
		carouselElementKey,
		isPrevSlideDisabled,
		isNextSlideDisabled,
		isOnlyOneSlide,
		carouselRef,
		goToNextSlide,
		goToPrevSlide,
		debouncedCarouselResize,
		setCarouselProps,
	};
};
