import { useVisitMetricMutation } from 'src/generated/graphql';
import { useEffect } from 'react';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';

export function useVisitMetric() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { metricNameWithFlavor } = useMetricDerivedState();
	const [visitMetric] = useVisitMetricMutation();
	useEffect(() => {
		if (!isSightfull2 || metricNameWithFlavor == '') return;

		void visitMetric({ variables: { metric: metricNameWithFlavor } });
	}, [isSightfull2, metricNameWithFlavor, visitMetric]);
}
