import Box from '@components/Box';
import Flex from '@components/Flex';
import Card from '@components/Card';
import Typography from '@components/Typography';
import Tooltip from 'src/common/components/Tooltip';
import { MetricCardV2 } from './MetricCardV2';
import classes from './MetricCardV2.module.scss';
import { useRef } from 'react';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import colors from 'src/style/colors';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { useEntitlementsCheck } from 'src/services/entitlements';
import { MetricCardMoreButton } from './MetricCardMoreButton';
import { CardContentProps, CatalogCardProps } from '../types';
import PendingSetupMetricPreview from 'src/assets/images/pending-setup-metric-preview.svg';
const maxDescriptionLines = 2;

export function CatalogMetricCard({
	metric,
	categoryName,
	withError = false,
	dataIntercomSequence,
	cardSize = 'int',
	imageUrl,
	thumbnail,
	noEffects = false,
	metricCategoryId,
}: CatalogCardProps) {
	const { metricCard, moreButton } = classes;

	const { id, name, description, displayName, isFullyDefined, entity, hide } = metric;

	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const hasEditEntitlement = useEntitlementsCheck({
		action: 'edit',
		resourceType: 'metric',
		resourceId: name,
	});
	const descriptionRef = useRef(null);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [description], undefined, false);

	const CardContent = ({
		handleClick,
		CardPreview,
		isOverflowingName,
		nameRef,
		entityDisplayName,
		SourceLogo,
		labelName,
	}: CardContentProps) => {
		return (
			<Card padding={0} radius={8} size={cardSize} onClick={handleClick} noHoverEffects={noEffects}>
				<Flex direction="column" alignItems={'flex-end'} position="relative" width={'100%'} className={metricCard}>
					<Box
						data-intercom-area={'catalog'}
						data-intercom-type={'metric'}
						data-intercom-target={displayName ?? name}
						data-intercom-sequence={dataIntercomSequence}
						width={'100%'}
					>
						{CardPreview?.()}
						<Flex padding={'16px'} gap={'8px'} flexDirection="column" justifyContent="space-between">
							<Box height={'40px'}>
								<Tooltip size="md" variant="fluid" label={isOverflowingName && (displayName ?? name)}>
									<Typography
										minLines={2}
										color={'gray.1000'}
										noOfLines={maxDescriptionLines}
										variant="DesktopH8Medium"
									>
										<Box ref={nameRef}>{displayName ?? name}</Box>
									</Typography>
								</Tooltip>
							</Box>
							<Flex gap={'20px'} flexDirection="column" justifyContent={'space-between'}>
								<Tooltip size="md" variant="fluid" label={isOverflowingDescription && description}>
									<Typography minLines={2} noOfLines={maxDescriptionLines} variant={'Paragraph12R'} color="gray.600">
										<Box ref={descriptionRef}>{description}</Box>
									</Typography>
								</Tooltip>
								<Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
									<Flex
										width={'fit-content'}
										alignItems={'center'}
										borderRadius={'2px'}
										padding={'2px 8px'}
										backgroundColor={entity ? colors.gray[200] : 'transparent'}
									>
										<Typography
											color={entity ? colors.gray[700] : 'transparent'}
											cursor={'inherit'}
											variant="Paragraph12M"
										>
											{entity ? entityDisplayName : 'Placeholder'}
										</Typography>
									</Flex>

									{SourceLogo && (
										<Tooltip label={labelName} size="sm">
											{SourceLogo}
										</Tooltip>
									)}
								</Flex>
							</Flex>
						</Flex>
					</Box>
					{/* After entitlements rolled out remove this duplication with Frontegg checks*/}
					{!noEffects && hasEditPermission && hasEditEntitlement && (
						<MetricCardMoreButton
							hidden={hide}
							metricDisplayName={displayName}
							metricName={name}
							className={moreButton}
							metricId={id}
							metricCategoryId={metricCategoryId}
							metricCategoryName={categoryName}
						/>
					)}
				</Flex>
			</Card>
		);
	};

	return (
		<MetricCardV2
			metric={metric}
			categoryName={categoryName}
			withError={withError}
			dataIntercomSequence={dataIntercomSequence}
			cardSize={cardSize}
			imageUrl={imageUrl}
			thumbnail={thumbnail}
			noEffects={noEffects}
			metricCategoryId={metricCategoryId}
			cardStyles={{
				chartHeight: '156px',
				wrapperPadding: isFullyDefined ? '20px' : '0',
				cardHeight: imageUrl || !isFullyDefined ? 'auto' : '124px',
				maxCardHeight: imageUrl ? '124px' : 'auto',
				cardPaddingTop: '12px',
				pendingSetupImageSrc: PendingSetupMetricPreview,
			}}
			renderItem={({
				handleClick,
				CardPreview,
				isOverflowingName,
				nameRef,
				entityDisplayName,
				SourceLogo,
				labelName,
			}: CardContentProps) => (
				<CardContent
					handleClick={handleClick}
					CardPreview={CardPreview}
					isOverflowingName={isOverflowingName}
					nameRef={nameRef}
					entityDisplayName={entityDisplayName}
					SourceLogo={SourceLogo}
					labelName={labelName}
				/>
			)}
		/>
	);
}
