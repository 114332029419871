const shadows = {
	blueActiveShadow: {
		100: '0px 0px 0px 3px #E8EDFF, 0px 1px 2px 0px #E8EDFF',
		200: '0px 0px 0px 3px #B4C4FF, 0px 1px 2px 0px #B4C4FF',
	},
	glow: {
		blue: '0px 0px 4px 0px #6E8EFFCC',
		black: '0px 0px 4px 0px #282D3380',
	},
	avatarMenuShadow:
		'0px 0px 0.88052px 0px rgba(0, 0, 0, 0.03), 0px 0px 2.29197px 0px rgba(0, 0, 0, 0.03), 0px 0px 5.52px 0px rgba(0, 0, 0, 0.05), 0px 0px 11px 0px rgba(0, 0, 0, 0.09)',
	z5: '0px 0px 2.17382px 0px rgba(0, 0, 0, 0.05), 0px 1.75px 6.01034px 0px rgba(0, 0, 0, 0.07), 0px 3.63px 14.4706px 0px rgba(0, 0, 0, 0.09), 0px 22px 48px 0px rgba(0, 0, 0, 0.14)',
	borderRight: 'inset -1px 0px 0px var(--chakra-colors-gray-300)',
	borderLeft: 'inset 1px 0px 0px var(--chakra-colors-gray-300)',
	borderTop: 'inset 0px 1px 0px var(--chakra-colors-gray-300)',
	borderBottom: 'inset 0px -1px 0px var(--chakra-colors-gray-300)',
	widgetHeaderBorderBottom: '0px -1px 0px 0px var(--chakra-colors-gray-300) inset',
	'g-02': '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
	'g-03': '0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)',
	'g-04': '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)',
	// disable the blue border that around Chakra components (related to accessibility)
	outline: '0 !important',
	'g-05': '0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)',
	'a-22':
		'0px 0px 11px rgba(0, 0, 0, 0.09), 0px 0px 5.52px rgba(0, 0, 0, 0.0543), 0px 0px 2.29197px rgba(0, 0, 0, 0.03), 0px 0px 0.880517px rgba(0, 0, 0, 0.0288)',
	'z-05':
		'0px 22px 48px rgba(0, 0, 0, 0.14), 0px 3.63px 14.4706px rgba(0, 0, 0, 0.0912224), 0px 1.75px 6.01034px rgba(0, 0, 0, 0.07), 0px 0px 2.17382px rgba(0, 0, 0, 0.0487776)',
	'z-03':
		'0px 3px 10px rgba(0, 0, 0, 0.13), 0px 1.28px 5.57858px rgba(0, 0, 0, 0.0774939), 0px 0.08px 2.55728px rgba(0, 0, 0, 0.0525061)',
	lg: '0px 8px 11px -4px rgba(45, 54, 67, 0.04), 0px 20px 24px -4px rgba(45, 54, 67, 0.04)',
	xl: '0px 24px 50px -12px rgba(45, 54, 67, 0.12)',
	focus: {
		medium: {
			gray: {
				100: '0px 0px 0px 3px var(--chakra-colors-gray-200), 0px 1px 2px 0px var(--chakra-colors-gray-200)',
				200: '0px 0px 0px 3px var(--chakra-colors-gray-300), 0px 1px 2px 0px var(--chakra-colors-gray-300)',
				300: '0px 0px 0px 3px var(--chakra-colors-gray-300), 0px 1px 2px 0px var(--chakra-colors-gray-300)',
			},
			blue: {
				100: '0px 0px 0px 3px #E8EDFF',
			},
		},
	},
	switcherButton:
		'0px 0px 0.88052px 0px rgba(0, 0, 0, 0.03), 0px 0px 2.29197px 0px rgba(0, 0, 0, 0.03), 0px 0px 5.52px 0px rgba(0, 0, 0, 0.05), 0px 0px 11px 0px rgba(0, 0, 0, 0.09)',
	sm: '0px 1px 2px 0px rgba(26, 29, 33, 0.05)',
	panelShadow: '0px 2px 4px -2px rgba(45, 54, 67, 0.06), 0px 4px 8px -2px rgba(45, 54, 67, 0.08)',
	cardShadow: '0px 20px 24px -4px rgba(26, 29, 33, 0.04), 0px 8px 11px -4px rgba(26, 29, 33, 0.04)',
	redButtonShadow: '0px 0px 0px 3px #FFDCDA, 0px 1px 2px 0px #FFDCDA',
	pageShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
	mainCanvas: '0px 0px 4px 0px rgba(49, 49, 49, 0.10)',
};

export default shadows;
