import Box from 'src/common/components/Box';
import Flex from 'src/common/components/Flex';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';

export function PageContentStructure({ children }: { children: React.ReactNode }) {
	return (
		<Flex borderRadius={'8px'} boxShadow={shadows.mainCanvas} backgroundColor={colors.white} width={'100%'}>
			<Box width={'100%'}>
				<>
					<Flex height={'100%'} flexDirection="column">
						{children}
					</Flex>
				</>
			</Box>
		</Flex>
	);
}
