import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import Drawer from 'antd/es/drawer';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'src/common/components/Spinner';
import useToast from 'src/common/hooks/ui/useToast';
import { FeedSignal } from 'src/types/spaces';
import { CommentSidebar } from './CommentSidebar/CommentSidebar';
import { WorkspaceSignalWidget } from './SignalWidget/WorkspaceSignalWidget';
import EmptyPage from '../DashboardPage/components/EmptyPage';

const INFINITE_SCROLL_BATCH_SIZE = 10;
const INFINITE_SCROLL_TARGET_ID = 'scrollableGrid';

export function WorkspaceLayout({ feedSignals, linkedId }: { feedSignals: FeedSignal[]; linkedId?: string }) {
	const signals = useMemo(
		() =>
			feedSignals.slice().sort((a, b) => {
				const timeA = a.order;
				const timeB = b.order;
				return new Date(timeB).getTime() - new Date(timeA).getTime();
			}),
		[feedSignals]
	);

	const [selectedSignalId, setSelectedSignalId] = useState<FeedSignal['signal_id'] | null>(null);
	const [loadedFeedSignalsCount, setLoadedFeedSignalsCount] = useState<number>(
		Math.min(feedSignals.length, INFINITE_SCROLL_BATCH_SIZE)
	);

	const loadMoreSignals = () => {
		setLoadedFeedSignalsCount((l) => Math.min(l + INFINITE_SCROLL_BATCH_SIZE, feedSignals.length));
	};
	const loadedSignals = useMemo(() => signals.slice(0, loadedFeedSignalsCount + 1), [loadedFeedSignalsCount, signals]);

	const selectedSignal = useMemo(
		() => signals.find((signal) => signal.signal_id == selectedSignalId),
		[selectedSignalId, signals]
	);

	const closeCommentSidebar = () => setSelectedSignalId(null);

	const onOpenSignalComment = (signal_id: FeedSignal['signal_id']) => {
		setSelectedSignalId(signal_id);
	};

	const showToast = useToast();

	useEffect(() => {
		if (!linkedId) return;
		const signalIndex = signals.findIndex((signal) => signal.signal_id == linkedId);
		const isSignalIdInWorkspace = signalIndex != -1;
		if (!isSignalIdInWorkspace) {
			showToast({ variant: 'error', message: 'Linked signal is not found' });
			return;
		}

		const isSignalIdLoaded = loadedFeedSignalsCount >= signalIndex;
		if (!isSignalIdLoaded) {
			setLoadedFeedSignalsCount(Math.min(signalIndex + 1, feedSignals.length));
			return;
		}

		const element = document.getElementById(linkedId);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
			setSelectedSignalId(linkedId);
		}
	}, [feedSignals.length, linkedId, loadedFeedSignalsCount, showToast, signals]);

	return !feedSignals.length ? (
		<EmptyPage signalType={'workspace'} />
	) : (
		<Flex width={'100%'} flexDir="row" mb="32px" height={'0'} minHeight="100%">
			<Grid
				gridTemplateColumns="repeat(12, [col-start] 1fr)"
				columnGap="16px"
				paddingX={'32px'}
				width="100%"
				overflowY={'auto'}
				id={INFINITE_SCROLL_TARGET_ID}
			>
				<GridItem
					colSpan={{
						base: 12,
						md: 8,
					}}
					colStart={{
						base: 1,
						md: 3,
					}}
				>
					<Flex flex={1} pt="32px" direction={'column'}>
						<InfiniteScroll
							dataLength={loadedFeedSignalsCount}
							next={loadMoreSignals}
							hasMore={loadedFeedSignalsCount < feedSignals.length}
							loader={<Spinner />}
							scrollableTarget={INFINITE_SCROLL_TARGET_ID}
						>
							{loadedSignals.map((signal) => (
								<Box paddingBottom={'24px'} key={`workspace-signal-widget-${signal.signal_id}`} id={signal.signal_id}>
									<WorkspaceSignalWidget signal={signal} onOpenSignalComment={onOpenSignalComment} />
								</Box>
							))}
						</InfiniteScroll>
					</Flex>
				</GridItem>
			</Grid>
			<Drawer
				open={!!selectedSignal}
				onClose={closeCommentSidebar}
				width={'420px'}
				title=""
				closable={false}
				bodyStyle={{ padding: 0 }}
				maskStyle={{ opacity: 0.5 }}
			>
				{selectedSignal && <CommentSidebar signal={selectedSignal} onClose={closeCommentSidebar} />}
			</Drawer>
		</Flex>
	);
}
