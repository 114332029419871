import { gql } from '@apollo/client';

export const CollectionFragment = gql`
	fragment CollectionFragment on workspaces {
		id
		name
		description
		order
		collection_type
		layout
		folder_id
	}
`;

export const GetCollections = gql`
	${CollectionFragment}
	subscription GetCollections($collection_type: String!) {
		workspaces(where: { collection_type: { _eq: $collection_type } }, order_by: [{ order: asc }, { created_at: asc }]) {
			...CollectionFragment
		}
	}
`;

export const UpdateCollection = gql`
	mutation UpdateCollection($id: uuid!, $name: String!, $description: String, $order: bigint, $folder_id: uuid) {
		update_workspaces_by_pk(
			pk_columns: { id: $id }
			_set: { name: $name, description: $description, order: $order, folder_id: $folder_id }
		) {
			id
			name
			description
			folder_id
			__typename
		}
	}
`;

export const InsertCollectionAndFeed = gql`
	mutation InsertCollectionAndFeed($name: String!, $collection_type: String!, $folder_id: uuid, $description: String) {
		insert_workspaces_one(
			object: {
				name: $name
				collection_type: $collection_type
				feed: { data: {} }
				folder_id: $folder_id
				description: $description
			}
		) {
			id
		}
	}
`;

export const DeleteCollection = gql`
	mutation DeleteCollection($id: uuid!, $name: String!) {
		update_workspaces(where: { id: { _eq: $id } }, _set: { is_deleted: true, name: $name }) {
			affected_rows
			returning {
				name
			}
		}
	}
`;
