import { gql } from '@apollo/client';

export const GetUsersWithRolesOnResource = gql`
	query GetUsersWithRolesOnResource($resourceType: String!, $resourceId: String!) {
		getUsersWithRolesOnResource(resourceType: $resourceType, resourceId: $resourceId) {
			generalAccessRole
			users {
				missingPermissionsForRole
				role
				userId
			}
		}
	}
`;

export const GetUserRawEntitlementsRules = gql`
	query getUserRawEntitlementsRules {
		getUserRawEntitlementsRules {
			userRawEntitlementsRules
		}
	}
`;

export const AssignRolesToUsersOnResource = gql`
	mutation AssignRolesToUsersOnResource(
		$resourceType: String!
		$resourceId: String!
		$userRoles: [UserRoleInput!]
		$generalAccessRole: GeneralAccessRoleInput
	) {
		assignRolesToUsersOnResource(
			resourceType: $resourceType
			resourceId: $resourceId
			userRoles: $userRoles
			generalAccessRole: $generalAccessRole
		) {
			status
		}
	}
`;
