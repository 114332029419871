import { Flex } from '@chakra-ui/react';

import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import Button from '../../Button';
import { CloseLarge16 } from '../../Icons';
import Typography from '../../Typography';

export function Header({ onClose, resourceType }: { onClose: () => void; resourceType: string }) {
	return (
		<Flex
			padding={'12px'}
			display={'flex'}
			alignItems={'center'}
			alignSelf={'stretch'}
			justifyContent={'center'}
			height={'48px'}
			boxShadow={shadows.borderBottom}
		>
			<Flex flex={1}>
				<Button isIconOnly={true} onClick={onClose} variant="outline" size={'xxs'} colorScheme={'gray'}>
					<CloseLarge16 color={colors.gray['1000']} />
				</Button>
			</Flex>
			<Flex paddingX={'12px'}>
				<Typography variant={'DesktopH8Medium'} color={colors.gray['1000']} textAlign={'center'}>
					Share {resourceType}
				</Typography>
			</Flex>
			<Flex flex={1} />
		</Flex>
	);
}
