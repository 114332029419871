import {
	FILTER_EMPTY_STRING_OPTION,
	FILTER_NULL_OPTION,
	requiredValuesCountPerOperator,
	supportedOperatorDisplayNames,
	typeMappingByFilterType,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/constants';
import { getLastSubstringAfterDot } from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { removeDollarSigns } from '@pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';
import { FilterV2, FilterV2Value } from '@pages/MetricPage/utils/state.types';
import { useMemo, useState } from 'react';
import { useCoreReaderDimensionValuesApi } from 'src/common/hooks/fetching/useCoreReaderFiltersApi';
import { SupportedDimensionTypes, SupportedOperators } from 'src/generated/graphql';
import { useFiltersV2 } from 'src/pages/MetricPage/components/InvestigatePanel/useFiltersV2';
import { useReportEvent } from 'src/services/analytics';
import { useGetEntityToFetch } from './useGetEntityToFetch';

export const useMetricFilter2 = ({ filter }: { filter: FilterV2 }) => {
	const [, { editFilter }] = useFiltersV2();
	const [filterState, setFilterState] = useState<FilterV2>(filter);
	const { reportEvent } = useReportEvent();
	const {
		objectsTypes: [baseEntity],
	} = useMetricDerivedState();

	const dimensionsEntity = useGetEntityToFetch({
		entity: baseEntity,
		filterKey: filter.key,
	});
	const dimensionName = removeDollarSigns(getLastSubstringAfterDot(filter.key) || filter.key || '');

	const getDimensionValuesState = useCoreReaderDimensionValuesApi({
		entity: dimensionsEntity || '',
		dimension: dimensionName,
	});
	const isLoadingDimensionValues = getDimensionValuesState.loading;
	const dimensionValues = getDimensionValuesState.data?.entityDimensionValues;

	const options = useMemo(() => {
		const isStringDimension = filter?.type === SupportedDimensionTypes.String;
		const additionalOptionsForStringDimensions = [FILTER_NULL_OPTION, FILTER_EMPTY_STRING_OPTION];

		return [
			...(isStringDimension ? additionalOptionsForStringDimensions : []),
			...(dimensionValues?.map((value) => ({
				title: String(value),
				value,
			})) || []),
		];
	}, [dimensionValues, filter?.type]);

	const updateFilter = (selectedValues: FilterV2Value[], operator: SupportedOperators): boolean => {
		const finalItems = trimValuesListByOperator(selectedValues, operator);

		const newFilter = {
			...filterState,
			values: finalItems.map(typeMappingByFilterType[filter.type]),
			operator,
		};

		setFilterState(newFilter);

		if (!isFilterComplete(newFilter)) {
			return false;
		}

		reportEvent({
			event: 'filters-apply-filter',
			metaData: {
				dimension: filter.key,
				values: finalItems,
				operator: supportedOperatorDisplayNames[operator][filter.type],
				'dimension type': filter.type,
				application: 'metric investigation',
				feature: 'filters',
			},
		});

		editFilter(newFilter, filter);

		return true;
	};

	return {
		isLoadingDimensionValues,
		options,
		updateFilter,
		dimensionsEntity,
		dimensionName,
		filterState,
	};
};

const trimValuesListByOperator = (values: FilterV2Value[], operator: SupportedOperators) => {
	if (operator === SupportedOperators.IsNull || operator === SupportedOperators.IsNotNull) {
		return [];
	}
	if (
		[
			SupportedOperators.GreaterThan,
			SupportedOperators.GreaterThanOrEqualTo,
			SupportedOperators.LessThan,
			SupportedOperators.LessThanOrEqualTo,
			SupportedOperators.Contains,
			SupportedOperators.NotContains,
			SupportedOperators.NotContainsSensitive,
		].includes(operator)
	) {
		return [...values].filter((v) => v != null).slice(0, 1);
	}
	if ([SupportedOperators.Between, SupportedOperators.NotBetween].includes(operator)) {
		return [...values].filter((v) => v != null).slice(0, 2);
	}
	return values;
};

export const isFilterComplete = (filter: FilterV2) => {
	return requiredValuesCountPerOperator[filter.operator] <= filter.values.length;
};
