import Typography from '../../Typography';
import colors from '../../../../style/colors';
import { ReactNode } from 'react';

export type SemanticTitleProps = {
	children?: ReactNode;
};

export function SemanticTitle({ children }: SemanticTitleProps) {
	if (!children) return;

	return (
		<Typography variant={'Paragraph12R'} color={colors.gray[700]}>
			{children}
		</Typography>
	);
}
