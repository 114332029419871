import Modal from '@components/Modal';
import { useKeyPress } from '@hooks/interaction/useKeyPress';
import { useCallback, useEffect, useState } from 'react';
import { AskAIMetricDiscoveryChatPage } from './Chat/MetricDiscovery/AskAIMetricDiscoveryChatPage';
import { useAskAIChatMessages } from './hooks/useAskAIChatMessages';
import { useReportAIEvent } from './hooks/useReportAIEvent';
import { AskAIMainPage } from './MainPage/AskAIMainPage';
import { useAIAgents } from './hooks/useAIAgents';

type AskAIModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
};

export function AskAIModal({ isOpen, onClose, onOpen }: AskAIModalProps) {
	const { reportAskAIEvent } = useReportAIEvent();
	const toggleModal = isOpen ? onClose : onOpen;
	const { resetChatMessages } = useAskAIChatMessages();
	const { abortAIAgent } = useAIAgents();
	const [askingAI, setAskingAI] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const abortQueryIfRunning = useCallback(() => {
		abortAIAgent();
	}, [abortAIAgent]);

	const onDialogHide = useCallback(() => {
		abortQueryIfRunning();
		onClose();
	}, [abortQueryIfRunning, onClose]);

	const onChatBack = useCallback(() => {
		reportAskAIEvent({ event: 'ask-ai-closed' });
		abortQueryIfRunning();
		resetChatMessages();
		setAskingAI(false);
	}, [abortQueryIfRunning, reportAskAIEvent, resetChatMessages]);

	const onDialogClose = useCallback(() => {
		onChatBack();
		onClose();
	}, [onChatBack, onClose]);

	const onSubmit = useCallback(
		(asking: boolean) => {
			setAskingAI(asking);
			if (!asking) onDialogClose();
		},
		[setAskingAI, onDialogClose]
	);

	useEffect(() => {
		return () => {
			resetChatMessages();
		};
	}, [resetChatMessages]);

	const keypressEvent = (metaData: any) => {
		return () => {
			reportAskAIEvent({ event: 'ask-ai-modal-keypress-toggle', metaData: { metaData } });
			toggleModal();
		};
	};

	const onItemClicked = useCallback(
		(askingAI: boolean) => {
			setInputValue('');
			onSubmit(askingAI);
		},
		[onSubmit]
	);

	const onInputChange = useCallback(
		(value: string) => {
			setInputValue(value);
		},
		[setInputValue]
	);

	useKeyPress(['k'], keypressEvent({ isWithMetaKey: true }), { isWithMetaKey: true });
	useKeyPress(['k'], keypressEvent({ isWithCtrlKey: true }), { isWithCtrlKey: true });

	return (
		<Modal
			data-testid={'ask-ai-modal'}
			borderRadius={'16px'}
			isOpen={isOpen}
			onClose={onDialogClose}
			closeOnOverlayClick={false}
			onOverlayClick={onDialogHide}
			maxWidth={askingAI ? '600px' : '760px'}
			maxHeight={'600px'}
			blockScrollOnMount={false}
			modalPosition={askingAI ? 'docked' : 'floating'}
			overlayColor={'black'}
			marginTop={askingAI || inputValue ? 'var(--chakra-space-16)' : '164px'}
		>
			{askingAI ? (
				<AskAIMetricDiscoveryChatPage onBack={onChatBack} onClose={onDialogClose} />
			) : (
				<AskAIMainPage inputValue={inputValue} onInputChange={onInputChange} onItemClicked={onItemClicked} />
			)}
		</Modal>
	);
}
