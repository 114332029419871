import Box from '@components/Box';
import Flex from '@components/Flex';
import React, { MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import {
	IsMetricPageURLBasedAtom,
	MetricPageSearchParamsAtom,
} from 'src/pages/MetricPage/atoms/MetricPageSearchParams';

import { Provider } from 'jotai';
import Chart from 'src/common/components/Chart';
import { getInitialDerivedStateAtom } from '../../MetricPage/atoms/DerivedState';
import { TableRefAtom } from '../../MetricPage/atoms/TableRef';
import RulesEngineRunner from '../../MetricPage/components/RulesEngineRunner';
import { useMetricDerivedState } from '../../MetricPage/hooks/useMetricDerivedState';
import { SkeletonCardPreview } from '../SkeletonComponents/SkeletonCardPreview/SkeletonCardPreview';
import { Center, Img } from '@chakra-ui/react';
import { CardSize } from '@components/Card/Card';
import { Label } from '@components/Labels';
import { BrokenChart70, EyeView16 } from 'src/common/components/Icons';
import { useSemanticDefinitionsForEntity } from 'src/common/hooks/stores/useSemanticDefinitions';
import { getSourcesInfo, getSourceInfoLogo } from 'src/common/hooks/useSourceInfo';
import { useCategoriesWithBrokenMetrics } from '../hooks/useCategoriesWithBrokenMetric';
import PLACEHOLDER_ICON from 'src/assets/images/database-gray-16.svg';
import { MetricMetadataV2 } from 'src/types/metric';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { MetricPagePath } from '../../MetricPage/pageRoutesPaths';
import { CardContentProps, CardStyleProps } from '../types';
import colors from 'src/style/colors';

type MetricCardProps = {
	metric: MetricMetadataV2;
	categoryName?: string;
	withError?: boolean;
	dataIntercomSequence?: number;
	imageUrl?: string;
	cardSize?: CardSize;
	thumbnail?: React.ReactNode;
	noEffects?: boolean;
	metricCategoryId?: string;
	cardStyles?: CardStyleProps;
	renderItem: (props: CardContentProps) => ReactNode;
};

export function MetricCardV2({
	metric,
	categoryName,
	withError = false,
	imageUrl,
	thumbnail,
	noEffects = false,
	cardStyles,
	renderItem,
}: MetricCardProps) {
	const { name, entity, hide = false, source, isFullyDefined = true, isNew = false } = metric;
	const {
		chartHeight,
		wrapperPadding,
		cardShadow,
		cardBgColor,
		cardHeight,
		maxCardHeight,
		cardPaddingTop,
		pendingSetupImageSrc,
	} = cardStyles || {};

	const nameRef = useRef(null);

	const isOverflowingName = useOnOverflow(nameRef, [name], undefined, false);

	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const entityDisplayName = useSemanticDefinitionsForEntity(entity)?.meta?.display_name ?? entity;

	const getMetricPathObject = useCallback((metric: MetricMetadataV2) => {
		const additionalSearchParams = new URLSearchParams();

		const { name: metricName, defaultChartType: chartType, isFullyDefined } = metric;

		if (chartType) additionalSearchParams.append('chartType', chartType);
		if (!isFullyDefined) additionalSearchParams.append('pageMode', 'edit');

		return {
			path: `/${MetricPagePath}/${metricName}`,
			additionalSearchParams,
		};
	}, []);

	const handleMetricClick = useCallback(
		(metric: MetricMetadataV2) => {
			navigate(getMetricPathObject(metric));
		},
		[getMetricPathObject, navigate]
	);

	const onMetricClick = () => {
		reportEvent({
			event: 'metric-navigate-to',
			metaData: { metric: metric.name, categoryName },
		});
		handleMetricClick(metric);
	};

	const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault();
		if (!noEffects) onMetricClick?.();
	};

	const searchParams = new URLSearchParams([]);
	const { updateBrokenCategories } = useCategoriesWithBrokenMetrics();

	const sourceInfo = useMemo(() => getSourcesInfo({ source: source }), [source]);
	const SourceLogo = useMemo(
		() =>
			getSourceInfoLogo({
				bigLogo: true,
				dataConnectorInfo: sourceInfo?.connectorInfo,
				placeholderIcon: PLACEHOLDER_ICON,
			}),
		[sourceInfo?.connectorInfo]
	);

	const MetricChartGuard = () => {
		const { isLoading: isMetricLoading, errorMessage = '' } = useMetricDerivedState();

		useEffect(() => {
			if (errorMessage && categoryName) updateBrokenCategories(categoryName, name);
		}, [errorMessage]);

		const isMetricBroken = withError || !!errorMessage;

		return isMetricLoading && !withError ? (
			<SkeletonCardPreview chartHeight={chartHeight} />
		) : (
			<Flex
				maxH={chartHeight}
				minH={chartHeight}
				position={'relative'}
				borderRadius="7px 7px 0px 0px"
				backgroundColor={errorMessage || hide ? 'gray.100' : 'blue.100'}
				padding={wrapperPadding}
			>
				<Flex
					boxShadow={cardShadow || (errorMessage && !imageUrl ? 'none' : 'cardShadow')}
					backgroundColor={cardBgColor || (errorMessage ? colors.gray[100] : 'white')}
					w={'100%'}
					h={cardHeight}
					maxH={maxCardHeight}
					borderRadius="8px"
					overflow={'hidden'}
					paddingBottom={errorMessage || !isFullyDefined ? '0' : '8px'}
					paddingTop={errorMessage || !isFullyDefined ? '0' : cardPaddingTop}
					opacity={hide ? '0.3' : '1'}
				>
					{!!thumbnail && (
						<Box position={'absolute'} top={'12px'} left={'12px'}>
							{thumbnail}
						</Box>
					)}
					{returnPreview(isMetricBroken)}
				</Flex>
				{!imageUrl && (
					<Flex top={'12px'} left={'12px'} position={'absolute'}>
						{renderLabel(isMetricBroken)}
					</Flex>
				)}
			</Flex>
		);
	};

	const returnPreview = (errorMessage: boolean) => {
		if (imageUrl) {
			return <Img width={'100%'} height={'100%'} src={imageUrl} alt="chart" />;
		}

		if (!isFullyDefined) {
			return <Img width={'100%'} src={pendingSetupImageSrc} objectFit="cover" />;
		}

		if (errorMessage)
			return (
				<Center width={'100%'} height="128px">
					<BrokenChart70 />
				</Center>
			);

		return (
			<Box width="100%" marginTop="-14px">
				<Chart isTooltipEnabled={false} height={'128px'} isEntityPage xAxisLabelsVisible={false} />
			</Box>
		);
	};

	const renderLabel = (isMetricBroken: boolean) => {
		const labelCommonProps = {
			size: 'small' as const,
			variant: 'filled' as const,
			style: 'strong' as const,
		};

		if (hide) {
			return (
				<Label {...labelCommonProps} colorScheme={'neutral'} startIcon={<EyeView16 />}>
					Hidden
				</Label>
			);
		}

		if (!isFullyDefined) {
			return (
				<Label {...labelCommonProps} colorScheme={'warning'}>
					Pending setup
				</Label>
			);
		}

		if (isMetricBroken) {
			return (
				<Label {...labelCommonProps} colorScheme={'error'}>
					Broken metric
				</Label>
			);
		}

		if (isNew) {
			return (
				<Label {...labelCommonProps} colorScheme={'primary'}>
					New metric
				</Label>
			);
		}
	};

	function CardPreview() {
		return (
			<Provider
				initialValues={[
					[MetricPageSearchParamsAtom, { metricName: name, searchParams }],
					getInitialDerivedStateAtom(),
					[IsMetricPageURLBasedAtom, false],
					[TableRefAtom, null],
				]}
			>
				<RulesEngineRunner>
					<MetricChartGuard />
				</RulesEngineRunner>
			</Provider>
		);
	}

	return renderItem({
		handleClick,
		CardPreview,
		isOverflowingName,
		nameRef,
		entityDisplayName,
		SourceLogo,
		labelName: sourceInfo?.connectorInfo?.name || '',
	});
}
