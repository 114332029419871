import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import Flex from '@components/Flex';
import { Panel } from '@xyflow/react';

export type ActionsPanelProps = {
	actions?: PanelButtonProps[];
};

export function ActionsPanel({ actions }: ActionsPanelProps) {
	return (
		<Panel position={'bottom-left'} style={{ padding: '5px' }}>
			<Flex gap={'6px'} direction={'row'}>
				{actions?.map((action, index) => (
					<PanelButton key={index} {...action} />
				))}
			</Flex>
		</Panel>
	);
}

export type PanelButtonProps = {
	onClick: () => void;
	tooltip: string;
	icon: React.ReactNode;
};

export function PanelButton({ onClick, tooltip, icon }: PanelButtonProps) {
	return (
		<Tooltip size={'sm'} label={tooltip}>
			<Button
				onClick={onClick}
				isIconOnly={true}
				size={'xs'}
				colorScheme={'black'}
				variant={'ghost'}
				backgroundColor={'white'}
			>
				{icon}
			</Button>
		</Tooltip>
	);
}
