import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Abc16, Boolean16, Calendar16, HashMark16 } from 'src/common/components/Icons';
import { getSourceInfoLogo, getSourcesInfo } from 'src/common/hooks/useSourceInfo';
import {
	EntityCatalogQuery,
	ParametersCatalogQuery,
	useEntityCatalogQuery,
	useParametersCatalogQuery,
} from 'src/generated/graphql';
import colors from 'src/style/colors';
import { useOntologyParams, useSetOntologyLocation } from './useOntologySearchParams';

export const allOntologyTypes = ['entity', 'parameter'] as const;

export type OntologyType = typeof allOntologyTypes[number];

export type NormalizedOntology = {
	name: string;
	ontologyType: OntologyType;
	displayName: string;
	panelSubtitle?: string;
	icon: React.FC;
	iconBorder?: string;
	iconColor: string;
	iconBackgroundColor: string;
	description: string;
	isDefined: boolean;
	isFullyDefined: boolean;
	affectedItemsList?: string[];
};

export type OntologyPageStateAtomType = {
	isLoading: boolean;
	entities: EntityCatalogQuery['entityCatalog'];
	parameters: ParametersCatalogQuery['parametersCatalog'];
	ontologyType: OntologyType;
	allOntology: NormalizedOntology[];
	ontologyByType: Record<OntologyType, NormalizedOntology[]>;
	selectedOntology: NormalizedOntology | null;
	isOntologyPageEditable: boolean;
};
export const OntologyPageStateAtom = atom<OntologyPageStateAtomType>({
	isLoading: true,
	entities: [],
	parameters: [],
	ontologyType: 'entity',
	allOntology: [],
	ontologyByType: { entity: [], parameter: [] },
	selectedOntology: null,
	isOntologyPageEditable: true,
});

function normalizeEntities(entities: EntityCatalogQuery['entityCatalog']): NormalizedOntology[] {
	return entities.map((entity) => {
		const sourceDisplayName = entity.schema?.sourceDisplayName;
		const sourcesInfo = getSourcesInfo({ source: sourceDisplayName });
		const entityIcon = () => getSourceInfoLogo({ bigLogo: false, dataConnectorInfo: sourcesInfo?.connectorInfo });
		return {
			name: entity.entityName,
			ontologyType: 'entity',
			displayName: entity.displayName,
			panelSubtitle: sourcesInfo?.connectorInfo?.name || 'Raw SQL',
			icon: entityIcon,
			iconBorder: `1px solid ${colors.gray[400]}`,
			iconColor: 'gray.600',
			description: entity.description,
			isDefined: entity.isDefined,
			isFullyDefined: entity.isFullyDefined,
			iconBackgroundColor: 'white',
			affectedItemsList: entity.affectedMetricsList,
		};
	});
}

function normalizeParameters(parameters: ParametersCatalogQuery['parametersCatalog']): NormalizedOntology[] {
	const parameterTypeToSubtitle: { [key: string]: string } = {
		string: 'Text',
		number: 'Number',
		bool: 'Boolean',
		date: 'Date',
	};
	const parameterTypeToIcon: { [key: string]: React.FC } = {
		string: Abc16,
		number: HashMark16,
		bool: Boolean16,
		date: Calendar16,
	};

	return parameters.map((parameter) => ({
		name: parameter.name,
		ontologyType: 'parameter',
		displayName: parameter.label,
		panelSubtitle: parameterTypeToSubtitle[parameter.type],
		icon: parameterTypeToIcon[parameter.type],
		iconColor: 'white',
		description: parameter.description ?? '',
		isDefined: true,
		isFullyDefined: true,
		iconBackgroundColor: 'gray.1000',
	}));
}

export function useOntologyPageState() {
	const ontologyPageState = useAtomValue(OntologyPageStateAtom);
	const setOntologyPageStateAtom = useSetAtom(OntologyPageStateAtom);

	const { data: entitiesData, loading: isLoadingEntities } = useEntityCatalogQuery();
	const entities: EntityCatalogQuery['entityCatalog'] = useMemo(
		() => entitiesData?.entityCatalog ?? [],
		[entitiesData?.entityCatalog]
	);
	const { data: parametersData, loading: isLoadingParameters } = useParametersCatalogQuery();
	const parameters: ParametersCatalogQuery['parametersCatalog'] = useMemo(
		() => parametersData?.parametersCatalog ?? [],
		[parametersData?.parametersCatalog]
	);
	const isLoading = useMemo(() => isLoadingEntities || isLoadingParameters, [isLoadingEntities, isLoadingParameters]);
	const { ontologyType, ontologyName } = useOntologyParams();

	const [prevOntologyType, setPrevOntologyType] = useState<OntologyType>(ontologyType);

	const updateOntologyLocation = useSetOntologyLocation();

	useEffect(() => {
		if (isLoading) return;

		const ontologyByType = { entity: normalizeEntities(entities), parameter: normalizeParameters(parameters) };
		const allOntology = ontologyByType[ontologyType];

		if (prevOntologyType != ontologyType) {
			updateOntologyLocation({ ontologyType, ontologyName: allOntology[0].name });
			setOntologyPageStateAtom((prev: OntologyPageStateAtomType) => ({
				...prev,
				isOntologyPageEditable: ontologyType == 'entity',
			}));
			setPrevOntologyType(ontologyType);
		}

		if (!ontologyName && !!allOntology.length)
			updateOntologyLocation({ ontologyType, ontologyName: allOntology[0]?.name || '' });

		if (ontologyName && !allOntology.length) updateOntologyLocation({ ontologyType, ontologyName: '' });

		const newSelectedOntology = allOntology.find((ontology) => ontology.name === ontologyName) ?? allOntology[0];
		const newSelectedOntologyIndex = allOntology.findIndex((ontology) => ontology.name === ontologyName);

		const prevSelectedOntologyIndex = allOntology.findIndex(
			(ontology) => ontology.name === ontologyPageState.selectedOntology?.name
		);

		const shouldSetSelectedOntology = prevSelectedOntologyIndex != newSelectedOntologyIndex;
		const selectedOntology = !allOntology.length
			? null
			: shouldSetSelectedOntology
			? newSelectedOntology
			: ontologyPageState.selectedOntology;

		setOntologyPageStateAtom((prev: OntologyPageStateAtomType) => ({
			...prev,
			isLoading,
			entities,
			parameters,
			ontologyType,
			allOntology,
			ontologyByType,
			selectedOntology,
		}));
	}, [
		entities,
		isLoading,
		ontologyName,
		ontologyPageState.selectedOntology,
		ontologyPageState.selectedOntology?.name,
		ontologyType,
		parameters,
		prevOntologyType,
		setOntologyPageStateAtom,
		updateOntologyLocation,
	]);

	const setIsOntologyPageEditable = useCallback(
		(isEditable: boolean) => {
			setOntologyPageStateAtom((prev: OntologyPageStateAtomType) => ({
				...prev,
				isOntologyPageEditable: isEditable,
			}));
		},
		[setOntologyPageStateAtom]
	);

	return { ontologyPageState, setIsOntologyPageEditable };
}
