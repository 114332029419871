import Box from '@components/Box';
import Flex from '@components/Flex';
import { Enter16 } from '@components/Icons';
import Spacer from '@components/Spacer';
import * as CSS from 'csstype';
import { ReactNode, useCallback } from 'react';
import Typography from '../Typography';
import colors from 'src/style/colors';

export function AskAIModalItem({
	selected = false,
	selectable = false,

	borderTop,
	borderBottom,
	header,
	onHover,
	onClick,
	backgroundColor,
	padding,
	children,
	hasMinHeight = true,
	isHeaderOnly = false,
	isSubHeader = false,
}: {
	selected?: boolean;
	selectable?: boolean;
	borderTop?: boolean;
	borderBottom?: boolean;
	header?: string;
	padding?: string;
	onHover?: VoidFunction;
	onClick?: () => void;
	backgroundColor?: CSS.Property.Color;
	children?: ReactNode;
	hasMinHeight?: boolean;
	isHeaderOnly?: boolean;
	isSubHeader?: boolean;
}) {
	const selectedProps = {
		borderRadius: '4px',
		backgroundColor: 'blue.100',
	};

	const onMouseOver = useCallback(() => {
		if (selectable) onHover?.();
	}, [onHover, selectable]);

	const onMouseClick = useCallback(() => {
		if (selectable) onClick?.();
	}, [onClick, selectable]);

	return (
		<Box
			as={selectable ? 'button' : undefined}
			alignContent={'start'}
			flex={1}
			padding={padding ?? '8px'}
			minHeight={hasMinHeight && selectable ? '56px' : undefined}
			borderTop={borderTop ? '1px solid' : undefined}
			borderBottom={borderBottom ? '1px solid' : undefined}
			borderColor={borderTop || borderBottom ? 'gray.300' : undefined}
			backgroundColor={backgroundColor}
			onMouseMove={onMouseOver}
			onClick={onMouseClick}
		>
			<Flex
				padding={selectable || isHeaderOnly ? '8px 16px 8px 16px' : undefined}
				{...(selected ? selectedProps : undefined)}
				alignItems={'center'}
			>
				{header ? (
					<Flex direction={'column'}>
						<Typography
							variant={'DesktopH10Regular'}
							textAlign="start"
							padding={isHeaderOnly ? undefined : '0 0 8px 0'}
							color={isSubHeader ? colors['gray']['500'] : undefined}
						>
							{header}
						</Typography>
						{children}
					</Flex>
				) : (
					children
				)}
				{selected && (
					<>
						<Spacer />
						<Box color={'gray.700'}>
							<Enter16 />
						</Box>
					</>
				)}
			</Flex>
		</Box>
	);
}
