export const NOT_ACTIVE_COLUMNS = ['_row_id', '$actions'];

export const connectorsTableMetadata = [
	{
		colDisplayName: 'Name',
		colName: '$name',
		isPrimaryKey: true,
		aggregationFunction: 'unknown',
	},
	{
		colDisplayName: 'Last sync',
		colName: '$last_sync',
		isPrimaryKey: false,
		aggregationFunction: 'unknown',
	},
	{
		colDisplayName: 'Created',
		colName: '$created',
		isPrimaryKey: false,
		aggregationFunction: 'unknown',
	},
	{
		colDisplayName: 'Status',
		colName: '$status',
		isPrimaryKey: false,
		aggregationFunction: 'unknown',
	},
	{
		colDisplayName: '',
		colName: '$actions',
		isPrimaryKey: false,
		aggregationFunction: 'unknown',
	},
];

export const SYNC_COPY = 'Syncing now...';
