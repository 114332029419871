import { IMarkdownString } from 'monaco-editor';
import { DimensionContext } from '../completionProvider';
import { addPrefixDollarSignIfNeeded } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { formatDimensionLabel } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { DESCRIPTION_BY_TYPE, DimensionTypes } from '../types';
import { buildDimensionSourceSection, contextTypeColor, getSourceFooter, isSourceDimension } from './contextTooltip';
import { buildDiv, buildDivChildren, buildSectionColumn } from './baseBuilders';
import { getCssCompletionTooltip } from './cssTooltip';

export function getContextCompletionFilterTooltip(
	context: DimensionContext,
	rawInsertText: string,
	formattedDimensionPath?: string
): IMarkdownString | undefined {
	const image = { color: contextTypeColor[context.type], name: context.type };
	const insertText = addPrefixDollarSignIfNeeded(rawInsertText);
	const rowTitle = context.display_name ?? context.name;
	const title = formatDimensionLabel(addPrefixDollarSignIfNeeded(rowTitle));
	const subtitleText = DESCRIPTION_BY_TYPE[context.type];
	const dimensionTypeIconName = isSourceDimension(context)
		? 'source-dimension-type-icon'
		: 'ontology-dimension-type-icon';
	const dimensionTypeBadgeText = isSourceDimension(context) ? 'Source dimension' : 'Ontology';
	const description = isSourceDimension(context)
		? `This source dimension is obtained from the source system.`
		: context.description;

	const additionalInformationRows = [];

	const path = formattedDimensionPath ? buildDimensionPath(formattedDimensionPath) : undefined;

	if (DimensionTypes.includes(context.type)) {
		const dimensionSourceSection = buildDimensionSourceSection(context);
		if (dimensionSourceSection) {
			additionalInformationRows.push(dimensionSourceSection);
		}
	}

	return getCssCompletionTooltip(
		{
			title,
			image,
			subtitle: subtitleText,
			typeBadgeIconName: dimensionTypeIconName,
			typeBadgeText: dimensionTypeBadgeText,
			path,
			description,
			additionalInformationRows,
			sourceFooter: getSourceFooter(context),
		},
		insertText
	);
}

function buildDimensionPath(formattedDimensionPath: string): string {
	const pathIcon = buildDiv('dimension-path-icon');
	const path = buildDiv('dimension-path-entity', formattedDimensionPath.replace(/\//g, pathIcon));
	return buildSectionColumn('', '', buildDivChildren('path-layout', path));
}
