import { useCallback } from 'react';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import { Map16 } from '../../../../common/components/Icons';
import { useReportEvent } from '../../../../services/analytics';

export type LineageButtonProps = {
	onClick?: () => void;
	isActive: boolean;
	metadata: { assetName: string; assetType: string };
};

export function LineageButton({ onClick, isActive, metadata }: LineageButtonProps) {
	const { reportEvent } = useReportEvent(metadata);

	const onLineageClicked = useCallback(() => {
		reportEvent({
			event: 'lineage-button-clicked',
			metaData: { action: isActive ? 'close' : 'open' },
		});
		onClick?.();
	}, [isActive, onClick, reportEvent]);

	return (
		<Tooltip label="Metric lineage" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
			<Button
				isDisabled={!onClick}
				isIconOnly={true}
				variant={isActive ? 'solid' : 'outline'}
				colorScheme={'black'}
				onClick={onLineageClicked}
				size={'inline'}
				data-intercom-area={'metric'}
				data-intercom-type={'button'}
				data-intercom-target={'Lineage'}
			>
				<Map16 />
			</Button>
		</Tooltip>
	);
}
