import Box from '@components/Box';
import Flex from '@components/Flex';
import Image from '@components/Image';
import { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import PLACEHOLDER_ICON from 'src/assets/icons/database-16.svg';
import { InitialsBadge } from 'src/common/components/InitialsBadge';
import Typography from 'src/common/components/Typography';
import { ConnectorStatus } from 'src/generated/graphql';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { prettifyAndCombine } from 'src/normalize/utils';
import colors from 'src/style/colors';
import { getSourcesInfo } from '@hooks/useSourceInfo';

export function ConnectorLogoRenderer() {
	function Cell(params: ICellRendererParams) {
		const cellValue = params?.value || {};

		const isDisabled =
			cellValue.status === ConnectorStatus.SetupIncomplete || cellValue.status === ConnectorStatus.Unauthorized;

		const badgeColorByStatus = useMemo(() => {
			switch (cellValue.status) {
				case ConnectorStatus.Broken:
					return 'red.700';
				case ConnectorStatus.Active:
					return 'green.500';
				case ConnectorStatus.Delayed:
					return 'yellow.500';
				default:
					return 'gray.500';
			}
		}, [cellValue.status]);

		const connectorInfo = useMemo(() => getSourcesInfo({ source: cellValue.type }), [cellValue.type]);
		const connectorLogo =
			connectorInfo?.connectorInfo?.icon != null ? connectorInfo?.connectorInfo?.icon : PLACEHOLDER_ICON;
		const connectorName = useMemo(
			() =>
				cellValue.sourceName.includes('.')
					? prettifyAndCombine(cellValue.sourceName)
					: removeUnderscoresAndCapitalize(cellValue.sourceName),
			[cellValue.sourceName]
		);

		return (
			<Flex alignItems={'center'}>
				<Box position={'relative'}>
					<InitialsBadge size={'40px'} border={`1px solid ${colors.gray[300]}`} bgColor={'white'} />
					<Flex
						backgroundColor={'white'}
						position={'absolute'}
						left={'50%'}
						top={'50%'}
						transform={'translate(-50%, -50%)'}
					>
						<Image
							src={connectorLogo}
							maxWidth={'18px'}
							height={'18px'}
							width={'100%'}
							blendMode={isDisabled ? 'luminosity' : undefined}
							opacity={isDisabled ? 0.5 : 1}
						/>
					</Flex>
					{!isDisabled && (
						<Flex
							position={'absolute'}
							width={'8px'}
							height={'8px'}
							borderRadius={'50%'}
							right={'2px'}
							top={'2px'}
							backgroundColor={badgeColorByStatus}
						/>
					)}
				</Box>
				<Flex marginLeft={'16px'} flexDirection={'column'}>
					<Typography
						marginBottom={'4px'}
						variant={'DesktopH8Medium'}
						color={isDisabled ? 'gray.500' : 'gray.1000'}
						noOfLines={1}
						wordBreak="break-all"
					>
						{connectorName}
					</Typography>
					<Typography
						variant={'DesktopH10Regular'}
						color={isDisabled ? 'gray.500' : 'gray.800'}
						noOfLines={1}
						wordBreak="break-all"
					>
						{removeUnderscoresAndCapitalize(cellValue.type)}
					</Typography>
				</Flex>
			</Flex>
		);
	}
	return Cell;
}
