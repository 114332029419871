import Box from '@components/Box';
import { SemanticFlow } from '@components/SemanticFlow';
import Loader from '@components/Loader';
import Flex from '@components/Flex';
import { AnalyticsContextWithMetadata } from '@services/analytics';
import { useMetricLineage } from '../../hooks/useMetricLineage';

export type MetricLineageProps = {
	metricName: string;
	onClose: () => void;
	pageState: string;
};

export function MetricLineage({ metricName, onClose, pageState }: MetricLineageProps) {
	const { nodesAndEdges } = useMetricLineage(metricName);
	if (!nodesAndEdges) return <LineageLoader />;

	const { nodes, edges } = nodesAndEdges;
	return (
		<Box width={'100%'} height={'100%'}>
			<AnalyticsContextWithMetadata
				metadata={{ feature: 'lineage', assetName: metricName, assetType: 'metric', pageState }}
			>
				<SemanticFlow closable={true} onClose={onClose} title={'Metric lineage'} nodes={nodes} edges={edges} />
			</AnalyticsContextWithMetadata>
		</Box>
	);
}

function LineageLoader() {
	return (
		<Flex justifyContent={'center'} alignItems={'center'} width={'100%'} height={'100%'}>
			<Loader isCenter={false} />
		</Flex>
	);
}
