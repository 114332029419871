import Flex from '@components/Flex';
import Typography from '@components/Typography';
import colors from '../../../../style/colors';

export type SemanticHeaderProps = {
	title: string;
	subtitle: string;
	icon: React.ReactNode;
	iconColor: string;
	iconBgColor: string;
	iconSize?: 'large' | 'small';
	rightPanel?: React.ReactNode;
};

export function SemanticHeader({
	title,
	subtitle,
	icon,
	iconColor,
	iconBgColor,
	rightPanel,
	iconSize = 'large',
}: SemanticHeaderProps) {
	const boxSize = iconSize === 'large' ? '40px' : '32px';
	return (
		<Flex direction={'row'} gap={'12px'} alignItems={'start'} justifyContent={'center'}>
			<Flex
				color={iconColor}
				backgroundColor={iconBgColor}
				borderRadius={'8px'}
				width={boxSize}
				height={boxSize}
				alignItems={'center'}
				justifyContent={'center'}
			>
				{icon}
			</Flex>
			<Flex direction={'column'} gap={'4px'} flex={1}>
				<Typography variant={'DesktopH8Medium'} color={colors.gray[1000]} textOverflow={'break-word'}>
					{title}
				</Typography>
				<Typography variant={'DesktopH10Regular'} color={colors.gray[700]}>
					{subtitle}
				</Typography>
			</Flex>
			{rightPanel}
		</Flex>
	);
}
